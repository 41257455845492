html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

hr {
  box-sizing: content-box; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important; }
  .label {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

figure {
  margin: 0; }

img {
  vertical-align: middle; }

.container, .container-post p, .container-post h2, .container-post h3, .container-post h5 {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 375px) {
    .container, .container-post p, .container-post h2, .container-post h3, .container-post h5 {
      padding-left: 25px;
      padding-right: 25px; } }
  @media (min-width: 768px) {
    .container, .container-post p, .container-post h2, .container-post h3, .container-post h5 {
      padding-left: 15px;
      padding-right: 15px; } }
  .container:before, .container-post p:before, .container-post h2:before, .container-post h3:before, .container-post h5:before, .container:after, .container-post p:after, .container-post h2:after, .container-post h3:after, .container-post h5:after {
    content: " ";
    display: table; }
  .container:after, .container-post p:after, .container-post h2:after, .container-post h3:after, .container-post h5:after {
    clear: both; }
  @media (min-width: 768px) {
    .container, .container-post p, .container-post h2, .container-post h3, .container-post h5 {
      width: 740px; } }
  @media (min-width: 992px) {
    .container, .container-post p, .container-post h2, .container-post h3, .container-post h5 {
      width: 970px; } }
  @media (min-width: 1200px) {
    .container, .container-post p, .container-post h2, .container-post h3, .container-post h5 {
      width: 1140px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 375px) {
    .container-fluid {
      padding-left: 25px;
      padding-right: 25px; } }
  @media (min-width: 768px) {
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px; } }
  .container-fluid:before, .container-fluid:after {
    content: " ";
    display: table; }
  .container-fluid:after {
    clear: both; }

.row {
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 375px) {
    .row {
      margin-left: -25px;
      margin-right: -25px; } }
  @media (min-width: 768px) {
    .row {
      margin-left: -15px;
      margin-right: -15px; } }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 375px) {
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
      padding-left: 25px;
      padding-right: 25px; } }
  @media (min-width: 768px) {
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
      padding-left: 15px;
      padding-right: 15px; } }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-1 {
  width: 8.33333%; }

.col-xs-2 {
  width: 16.66667%; }

.col-xs-3 {
  width: 25%; }

.col-xs-4 {
  width: 33.33333%; }

.col-xs-5 {
  width: 41.66667%; }

.col-xs-6 {
  width: 50%; }

.col-xs-7 {
  width: 58.33333%; }

.col-xs-8 {
  width: 66.66667%; }

.col-xs-9 {
  width: 75%; }

.col-xs-10 {
  width: 83.33333%; }

.col-xs-11 {
  width: 91.66667%; }

.col-xs-12 {
  width: 100%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-pull-1 {
  right: 8.33333%; }

.col-xs-pull-2 {
  right: 16.66667%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-4 {
  right: 33.33333%; }

.col-xs-pull-5 {
  right: 41.66667%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-7 {
  right: 58.33333%; }

.col-xs-pull-8 {
  right: 66.66667%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-10 {
  right: 83.33333%; }

.col-xs-pull-11 {
  right: 91.66667%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-push-1 {
  left: 8.33333%; }

.col-xs-push-2 {
  left: 16.66667%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-4 {
  left: 33.33333%; }

.col-xs-push-5 {
  left: 41.66667%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-7 {
  left: 58.33333%; }

.col-xs-push-8 {
  left: 66.66667%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-10 {
  left: 83.33333%; }

.col-xs-push-11 {
  left: 91.66667%; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.col-xs-offset-1 {
  margin-left: 8.33333%; }

.col-xs-offset-2 {
  margin-left: 16.66667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.33333%; }

.col-xs-offset-5 {
  margin-left: 41.66667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.33333%; }

.col-xs-offset-8 {
  margin-left: 66.66667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.33333%; }

.col-xs-offset-11 {
  margin-left: 91.66667%; }

.col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.33333%; }
  .col-sm-2 {
    width: 16.66667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.33333%; }
  .col-sm-5 {
    width: 41.66667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.33333%; }
  .col-sm-8 {
    width: 66.66667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.33333%; }
  .col-sm-11 {
    width: 91.66667%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 8.33333%; }
  .col-sm-pull-2 {
    right: 16.66667%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-4 {
    right: 33.33333%; }
  .col-sm-pull-5 {
    right: 41.66667%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-7 {
    right: 58.33333%; }
  .col-sm-pull-8 {
    right: 66.66667%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-10 {
    right: 83.33333%; }
  .col-sm-pull-11 {
    right: 91.66667%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 8.33333%; }
  .col-sm-push-2 {
    left: 16.66667%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-4 {
    left: 33.33333%; }
  .col-sm-push-5 {
    left: 41.66667%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-7 {
    left: 58.33333%; }
  .col-sm-push-8 {
    left: 66.66667%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-10 {
    left: 83.33333%; }
  .col-sm-push-11 {
    left: 91.66667%; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.33333%; }
  .col-md-2 {
    width: 16.66667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.33333%; }
  .col-md-5 {
    width: 41.66667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.33333%; }
  .col-md-8 {
    width: 66.66667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.33333%; }
  .col-md-11 {
    width: 91.66667%; }
  .col-md-12 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 8.33333%; }
  .col-md-pull-2 {
    right: 16.66667%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-4 {
    right: 33.33333%; }
  .col-md-pull-5 {
    right: 41.66667%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-7 {
    right: 58.33333%; }
  .col-md-pull-8 {
    right: 66.66667%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-10 {
    right: 83.33333%; }
  .col-md-pull-11 {
    right: 91.66667%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 8.33333%; }
  .col-md-push-2 {
    left: 16.66667%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-4 {
    left: 33.33333%; }
  .col-md-push-5 {
    left: 41.66667%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-7 {
    left: 58.33333%; }
  .col-md-push-8 {
    left: 66.66667%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-10 {
    left: 83.33333%; }
  .col-md-push-11 {
    left: 91.66667%; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 8.33333%; }
  .col-md-offset-2 {
    margin-left: 16.66667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.33333%; }
  .col-md-offset-5 {
    margin-left: 41.66667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.33333%; }
  .col-md-offset-8 {
    margin-left: 66.66667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.33333%; }
  .col-md-offset-11 {
    margin-left: 91.66667%; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.33333%; }
  .col-lg-2 {
    width: 16.66667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.33333%; }
  .col-lg-5 {
    width: 41.66667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.33333%; }
  .col-lg-8 {
    width: 66.66667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.33333%; }
  .col-lg-11 {
    width: 91.66667%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 8.33333%; }
  .col-lg-pull-2 {
    right: 16.66667%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-4 {
    right: 33.33333%; }
  .col-lg-pull-5 {
    right: 41.66667%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-7 {
    right: 58.33333%; }
  .col-lg-pull-8 {
    right: 66.66667%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-10 {
    right: 83.33333%; }
  .col-lg-pull-11 {
    right: 91.66667%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 8.33333%; }
  .col-lg-push-2 {
    left: 16.66667%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-4 {
    left: 33.33333%; }
  .col-lg-push-5 {
    left: 41.66667%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-7 {
    left: 58.33333%; }
  .col-lg-push-8 {
    left: 66.66667%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-10 {
    left: 83.33333%; }
  .col-lg-push-11 {
    left: 91.66667%; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

@-ms-viewport {
  width: device-width; }

.visible-xs {
  display: none !important; }

.visible-sm {
  display: none !important; }

.visible-md {
  display: none !important; }

.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table !important; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }
  @media print {
    .visible-print-block {
      display: block !important; } }

.visible-print-inline {
  display: none !important; }
  @media print {
    .visible-print-inline {
      display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

/* function to convert px to em */
/* base */
/* mobile */
/* desktop */
/* mobile */
/* desktop */
/* mobile */
/* desktop */
/* mobile */
/* desktop */
/* mobile */
/* desktop */
/* mobile */
/* desktop */
/* mobile */
/* desktop */
/* mobile */
/* desktop */
/* mobile */
/* desktop */
/* for types */
/* button */
/* link-block */
/* link with arrow */
/* outline button */
/* logo */
/* icon */
/* menu */
/* colors */
/* menu animation mobile */
/* sub menu desktop */
/* language switcher */
/* fullscreen images */
/* navigation */
/* animation */
/* slider with text */
/* 16:9 ratio */
/* 3:2 ratio */
/* slider with quote */
/* background */
/* editions */
/* single editions */
body {
  background: #ffffff;
  color: #191919;
  font-family: "Museo Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.618;
  -webkit-text-stroke: 0px;
  overflow-y: auto;
  padding-top: 60px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'calt', 'clig', 'kern', 'liga', 'locl', 'rlig';
  /* only for chrome */ }
  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    body {
      -webkit-text-stroke: 0.3px;
      -webkit-font-smoothing: subpixel-antialiased; }
      body nav #nav-menu li a {
        font-weight: 300 !important; } }
  @media (min-width: 992px) {
    body {
      padding-top: 86px; } }

html, body {
  overflow-x: hidden; }

#page-content,
#post-content {
  position: relative;
  display: block; }

hr {
  border: none;
  width: 100%;
  height: 1px;
  background: #E5E5E6; }

/* display flex */
.d-flex {
  height: auto;
  display: flex !important; }
  .d-flex:before, .d-flex:after {
    display: none; }

.no-gutter {
  padding-left: 0 !important;
  padding-right: 0 !important; }

/* rows with same height */
@media (min-width: 768px) {
  .row-eq-height {
    display: flex; } }

/* smaller container for screen-sm */
@media (min-width: 768px) {
  .container-sm-max {
    max-width: 570px; } }

@media (min-width: 992px) {
  .container-sm-max {
    max-width: none; } }

/* force 3D rendering */
.translate3d, nav #nav-menu {
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
  transform-style: preserve-3d;
  backface-visibility: hidden; }

/* user selects */
.no-userselect, .image-wrapper img,
.video-wrapper img, .slider-fade ul li .animate-wrapper img, .slider-single-fade ul li .animate-wrapper img, .container-video-landing .text-wrapper {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */ }

.text-userselect {
  -webkit-user-select: text;
  /* Chrome all / Safari all */
  -moz-user-select: text;
  /* Firefox all */
  -ms-user-select: text;
  /* IE 10+ */
  user-select: text;
  /* Likely future */ }

/* text align */
.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

/* margin */
.mt-large {
  margin-top: 60px; }

/* visible */
.show-only-xs {
  display: block; }
  @media (min-width: 768px) {
    .show-only-xs {
      display: none !important; } }

.show-sm {
  display: none !important; }
  @media (min-width: 768px) {
    .show-sm {
      display: block !important; } }

.hide-sm {
  display: block !important; }
  @media (min-width: 768px) {
    .hide-sm {
      display: none !important; } }

.hide-sm-inline {
  display: none; }
  @media (min-width: 992px) {
    .hide-sm-inline {
      display: inline-block; } }

@media (max-width: 320px) {
  .hide-iphone5 {
    display: none; } }

@media (max-width: 375px) {
  .hide-iphone6 {
    display: none; } }

.visibility-hidden {
  visibility: hidden;
  opacity: 0; }

/* prevent clicks */
.not-clickable {
  pointer-events: none; }

.opacity-fix {
  opacity: 1 !important; }

/* select text */
::selection {
  background: #000000;
  color: #ffffff; }

::-moz-selection {
  background: #000000;
  color: #ffffff; }

.mb-40 {
  margin-bottom: 40px !important; }

.container-animate,
.container-animate-toggle {
  background: transparent;
  /* before fadeIn */ }
  .container-animate .animate-image,
  .container-animate .animate-text,
  .container-animate-toggle .animate-image,
  .container-animate-toggle .animate-text {
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
    transition: none;
    backface-visibility: hidden;
    transition-property: transform, opacity; }
  .container-animate .animate-image,
  .container-animate-toggle .animate-image {
    position: relative;
    display: block;
    overflow: hidden;
    /* fix pseudo background, if there are two backgrounds */ }
    @media screen and (max-width: 991px) {
      .container-animate .animate-image img,
      .container-animate-toggle .animate-image img {
        z-index: 10; } }
    @media (min-width: 992px) {
      .container-animate .animate-image img,
      .container-animate-toggle .animate-image img {
        opacity: 0;
        visibility: hidden; }
      .container-animate .animate-image:before,
      .container-animate-toggle .animate-image:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #ffffff;
        z-index: 1;
        transform: translateX(0); } }
    @media (min-width: 768px) {
      .container-animate .animate-image.slide-multiple-bg:before,
      .container-animate-toggle .animate-image.slide-multiple-bg:before {
        height: 40.4%; } }
    @media (min-width: 992px) {
      .container-animate .animate-image.slide-multiple-bg:before,
      .container-animate-toggle .animate-image.slide-multiple-bg:before {
        height: 50%; } }
    .container-animate .animate-image.slide-multiple-bg:after,
    .container-animate-toggle .animate-image.slide-multiple-bg:after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      background: #191922;
      z-index: 1;
      transform: translateX(0); }
      @media (min-width: 768px) {
        .container-animate .animate-image.slide-multiple-bg:after,
        .container-animate-toggle .animate-image.slide-multiple-bg:after {
          top: 40.4%;
          height: 60.6%;
          display: none; } }
      @media (min-width: 992px) {
        .container-animate .animate-image.slide-multiple-bg:after,
        .container-animate-toggle .animate-image.slide-multiple-bg:after {
          display: block;
          top: 50%;
          height: 50%; } }
  @media (min-width: 992px) {
    .container-animate .animate-text,
    .container-animate-toggle .animate-text {
      visibility: hidden;
      opacity: 0; }
      .container-animate .animate-text.fade-left,
      .container-animate-toggle .animate-text.fade-left {
        transform: translateX(-40px); }
      .container-animate .animate-text.fade-top,
      .container-animate-toggle .animate-text.fade-top {
        transform: translateY(-30px); } }
  .container-animate.visible,
  .container-animate-toggle.visible {
    position: relative !important;
    z-index: 1;
    /* growing delay */
    /* fadeIn and fadeOut animation */
    /* fadeIn later by adding the 'visible' class (with out scroll) */ }
    .container-animate.visible.delay,
    .container-animate-toggle.visible.delay {
      transition-delay: 0.2s; }
    .container-animate.visible.no-delay,
    .container-animate-toggle.visible.no-delay {
      transition-delay: 0 !important; }
    .container-animate.visible .growing-delay[data-delay='1'], .container-animate.visible .growing-delay:before,
    .container-animate-toggle.visible .growing-delay[data-delay='1'],
    .container-animate-toggle.visible .growing-delay:before {
      transition-delay: 0.2s; }
    .container-animate.visible .growing-delay[data-delay='2'], .container-animate.visible .growing-delay:before,
    .container-animate-toggle.visible .growing-delay[data-delay='2'],
    .container-animate-toggle.visible .growing-delay:before {
      transition-delay: 0.4s; }
    .container-animate.visible .growing-delay[data-delay='3'], .container-animate.visible .growing-delay:before,
    .container-animate-toggle.visible .growing-delay[data-delay='3'],
    .container-animate-toggle.visible .growing-delay:before {
      transition-delay: 0.6s; }
    .container-animate.visible .growing-delay[data-delay='4'], .container-animate.visible .growing-delay:before,
    .container-animate-toggle.visible .growing-delay[data-delay='4'],
    .container-animate-toggle.visible .growing-delay:before {
      transition-delay: 0.8s; }
    .container-animate.visible .growing-delay[data-delay='5'], .container-animate.visible .growing-delay:before,
    .container-animate-toggle.visible .growing-delay[data-delay='5'],
    .container-animate-toggle.visible .growing-delay:before {
      transition-delay: 1s; }
    .container-animate.visible .growing-delay[data-delay='6'], .container-animate.visible .growing-delay:before,
    .container-animate-toggle.visible .growing-delay[data-delay='6'],
    .container-animate-toggle.visible .growing-delay:before {
      transition-delay: 1.2s; }
    .container-animate.visible .growing-delay[data-delay='7'], .container-animate.visible .growing-delay:before,
    .container-animate-toggle.visible .growing-delay[data-delay='7'],
    .container-animate-toggle.visible .growing-delay:before {
      transition-delay: 1.4s; }
    .container-animate.visible .growing-delay[data-delay='8'], .container-animate.visible .growing-delay:before,
    .container-animate-toggle.visible .growing-delay[data-delay='8'],
    .container-animate-toggle.visible .growing-delay:before {
      transition-delay: 1.6s; }
    .container-animate.visible .growing-delay[data-delay='9'], .container-animate.visible .growing-delay:before,
    .container-animate-toggle.visible .growing-delay[data-delay='9'],
    .container-animate-toggle.visible .growing-delay:before {
      transition-delay: 1.8s; }
    .container-animate.visible .growing-delay[data-delay='10'], .container-animate.visible .growing-delay:before,
    .container-animate-toggle.visible .growing-delay[data-delay='10'],
    .container-animate-toggle.visible .growing-delay:before {
      transition-delay: 2s; }
    @media (min-width: 992px) {
      .container-animate.visible .animate-image,
      .container-animate-toggle.visible .animate-image {
        /* force white background */
        /* reverse the animation for fadeOut effect */
        /* fadeOut animation */ }
        .container-animate.visible .animate-image img,
        .container-animate-toggle.visible .animate-image img {
          opacity: 1;
          visibility: visible;
          transition: opacity 1.3s cubic-bezier(0.8, 0, 0.2, 1); }
        .container-animate.visible .animate-image.disable-hover,
        .container-animate-toggle.visible .animate-image.disable-hover {
          pointer-events: none; }
        .container-animate.visible .animate-image.slide-left:before, .container-animate.visible .animate-image.slide-left:after,
        .container-animate-toggle.visible .animate-image.slide-left:before,
        .container-animate-toggle.visible .animate-image.slide-left:after {
          transform: translateX(100%);
          transition: transform 1s cubic-bezier(0.8, 0, 0.2, 1); }
        .container-animate.visible .animate-image.bg-white .image-wrapper,
        .container-animate-toggle.visible .animate-image.bg-white .image-wrapper {
          background: #ffffff !important; }
        .container-animate.visible .animate-image.reverse.slide-left:before, .container-animate.visible .animate-image.reverse.slide-left:after,
        .container-animate-toggle.visible .animate-image.reverse.slide-left:before,
        .container-animate-toggle.visible .animate-image.reverse.slide-left:after {
          transform: translateX(-100%) !important;
          transition: none; }
        .container-animate.visible .animate-image.visible-fadeOut.slide-left:before, .container-animate.visible .animate-image.visible-fadeOut.slide-left:after,
        .container-animate-toggle.visible .animate-image.visible-fadeOut.slide-left:before,
        .container-animate-toggle.visible .animate-image.visible-fadeOut.slide-left:after {
          transform: translateX(0) !important;
          transition: transform 1s cubic-bezier(0.8, 0, 0.2, 1); } }
    @media (min-width: 992px) {
      .container-animate.visible .animate-text,
      .container-animate-toggle.visible .animate-text {
        visibility: visible;
        opacity: 1;
        transition: transform 0.75s ease-out, opacity 1.1s ease-out;
        transition-delay: 0.2s;
        /* reverse the animation for fadeOut effect */
        /* fadeOut animation */ }
        .container-animate.visible .animate-text.fade-left,
        .container-animate-toggle.visible .animate-text.fade-left {
          transform: translateX(0); }
        .container-animate.visible .animate-text.fade-top,
        .container-animate-toggle.visible .animate-text.fade-top {
          transform: translateY(0); }
        .container-animate.visible .animate-text.delay,
        .container-animate-toggle.visible .animate-text.delay {
          transition-delay: 0.4s; }
        .container-animate.visible .animate-text.reverse.fade-left,
        .container-animate-toggle.visible .animate-text.reverse.fade-left {
          transform: translateX(80px) !important; }
        .container-animate.visible .animate-text.reverse.fade-top,
        .container-animate-toggle.visible .animate-text.reverse.fade-top {
          transform: translateY(80px) !important; }
        .container-animate.visible .animate-text.visible-fadeOut,
        .container-animate-toggle.visible .animate-text.visible-fadeOut {
          transition: transform 0.5s ease-out, opacity 0.55s ease-out;
          transition-delay: 0.13333s;
          opacity: 0 !important; }
          .container-animate.visible .animate-text.visible-fadeOut.fade-left,
          .container-animate-toggle.visible .animate-text.visible-fadeOut.fade-left {
            transform: translateX(-80px); }
          .container-animate.visible .animate-text.visible-fadeOut.fade-top,
          .container-animate-toggle.visible .animate-text.visible-fadeOut.fade-top {
            transform: translateY(-80px); } }
    @media (min-width: 992px) {
      .container-animate.visible .animate-later.animate-image,
      .container-animate-toggle.visible .animate-later.animate-image {
        visibility: hidden;
        opacity: 0; }
        .container-animate.visible .animate-later.animate-image img,
        .container-animate-toggle.visible .animate-later.animate-image img {
          opacity: 0;
          visibility: hidden; }
        .container-animate.visible .animate-later.animate-image.slide-left:before, .container-animate.visible .animate-later.animate-image.slide-left:after,
        .container-animate-toggle.visible .animate-later.animate-image.slide-left:before,
        .container-animate-toggle.visible .animate-later.animate-image.slide-left:after {
          transform: translateX(0);
          transition: transform 1s cubic-bezier(0.8, 0, 0.2, 1); }
        .container-animate.visible .animate-later.animate-image.visible,
        .container-animate-toggle.visible .animate-later.animate-image.visible {
          visibility: visible;
          opacity: 1; }
          .container-animate.visible .animate-later.animate-image.visible img,
          .container-animate-toggle.visible .animate-later.animate-image.visible img {
            opacity: 1;
            visibility: visible; }
          .container-animate.visible .animate-later.animate-image.visible.slide-left:before, .container-animate.visible .animate-later.animate-image.visible.slide-left:after,
          .container-animate-toggle.visible .animate-later.animate-image.visible.slide-left:before,
          .container-animate-toggle.visible .animate-later.animate-image.visible.slide-left:after {
            transform: translateX(100%); } }
    @media (min-width: 992px) {
      .container-animate.visible .animate-later.animate-text,
      .container-animate-toggle.visible .animate-later.animate-text {
        visibility: hidden;
        opacity: 0; }
        .container-animate.visible .animate-later.animate-text.fade-left,
        .container-animate-toggle.visible .animate-later.animate-text.fade-left {
          transform: translateX(-40px); }
        .container-animate.visible .animate-later.animate-text.fade-top,
        .container-animate-toggle.visible .animate-later.animate-text.fade-top {
          transform: translateY(-30px); }
        .container-animate.visible .animate-later.animate-text.visible,
        .container-animate-toggle.visible .animate-later.animate-text.visible {
          visibility: visible;
          opacity: 1; }
          .container-animate.visible .animate-later.animate-text.visible.fade-left,
          .container-animate-toggle.visible .animate-later.animate-text.visible.fade-left {
            transform: translateX(0); }
          .container-animate.visible .animate-later.animate-text.visible.fade-top,
          .container-animate-toggle.visible .animate-later.animate-text.visible.fade-top {
            transform: translateY(0); } }

/* reset */
h1, h2, h3, h4, h5, h6, ul, p, a, hr, span, label {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  padding: 0;
  margin: 0;
  text-transform: none; }
  h1.gray, h2.gray, h3.gray, h4.gray, h5.gray, h6.gray, ul.gray, p.gray, a.gray, hr.gray, span.gray, label.gray {
    color: #66666C; }
  h1.lightgray, h2.lightgray, h3.lightgray, h4.lightgray, h5.lightgray, h6.lightgray, ul.lightgray, p.lightgray, a.lightgray, hr.lightgray, span.lightgray, label.lightgray {
    color: #B2B2B5; }
  h1.black, h2.black, h3.black, h4.black, h5.black, h6.black, ul.black, p.black, a.black, hr.black, span.black, label.black {
    color: #191919; }
  h1.white, h2.white, h3.white, h4.white, h5.white, h6.white, ul.white, p.white, a.white, hr.white, span.white, label.white {
    color: #f8f8f8; }
  h1.bold, h2.bold, h3.bold, h4.bold, h5.bold, h6.bold, ul.bold, p.bold, a.bold, hr.bold, span.bold, label.bold {
    font-weight: 500; }
  h1.italic, h2.italic, h3.italic, h4.italic, h5.italic, h6.italic, ul.italic, p.italic, a.italic, hr.italic, span.italic, label.italic {
    font-style: italic; }
  h1.uppercase, h2.uppercase, h3.uppercase, h4.uppercase, h5.uppercase, h6.uppercase, ul.uppercase, p.uppercase, a.uppercase, hr.uppercase, span.uppercase, label.uppercase {
    text-transform: uppercase; }

/* auto line break with hyphens */
h1, h1.h1, h2.h1, h3.h1, h4.h1, h5.h1, h6.h1, ul.h1, p.h1, a.h1, hr.h1, span.h1, label.h1 {
  font-size: 1.9375em;
  font-weight: 300;
  letter-spacing: 3.88px;
  line-height: 42px;
  text-transform: uppercase;
  margin-bottom: 0; }
  @media (min-width: 992px) {
    h1, h1.h1, h2.h1, h3.h1, h4.h1, h5.h1, h6.h1, ul.h1, p.h1, a.h1, hr.h1, span.h1, label.h1 {
      font-size: 2.4375em;
      font-weight: 300;
      letter-spacing: 4.88px;
      line-height: 54px;
      margin-bottom: 0; } }

h2, h1.h2, h2.h2, h3.h2, h4.h2, h5.h2, h6.h2, ul.h2, p.h2, a.h2, hr.h2, span.h2, label.h2 {
  font-size: 1.5625em;
  font-weight: 300;
  letter-spacing: 3.13px;
  line-height: 34px;
  text-transform: uppercase;
  margin-bottom: 10px; }
  @media (min-width: 992px) {
    h2, h1.h2, h2.h2, h3.h2, h4.h2, h5.h2, h6.h2, ul.h2, p.h2, a.h2, hr.h2, span.h2, label.h2 {
      font-size: 1.6875em;
      font-weight: 300;
      letter-spacing: 3.38px;
      line-height: 37px;
      margin-bottom: 20px; } }

h3, h1.h3, h2.h3, h3.h3, h4.h3, h5.h3, h6.h3, ul.h3, p.h3, a.h3, hr.h3, span.h3, label.h3 {
  font-size: 1.3125em;
  font-weight: 300;
  letter-spacing: 2.63px;
  line-height: 29px;
  text-transform: uppercase;
  margin-bottom: 0; }
  @media (min-width: 992px) {
    h3, h1.h3, h2.h3, h3.h3, h4.h3, h5.h3, h6.h3, ul.h3, p.h3, a.h3, hr.h3, span.h3, label.h3 {
      font-size: 1.4375em;
      font-weight: 300;
      letter-spacing: 2.88px;
      line-height: 32px;
      margin-bottom: 0; } }

h4, h1.h4, h2.h4, h3.h4, h4.h4, h5.h4, h6.h4, ul.h4, p.h4, a.h4, hr.h4, span.h4, label.h4 {
  font-size: 1.1875em;
  font-weight: 300;
  letter-spacing: 2.38px;
  line-height: 27px;
  text-transform: uppercase;
  margin-bottom: 0; }
  @media (min-width: 992px) {
    h4, h1.h4, h2.h4, h3.h4, h4.h4, h5.h4, h6.h4, ul.h4, p.h4, a.h4, hr.h4, span.h4, label.h4 {
      font-size: 1.1875em;
      font-weight: 300;
      letter-spacing: 2.38px;
      line-height: 28px;
      margin-bottom: 0; } }

h5, h1.h5, h2.h5, h3.h5, .gallery-interface .gallery-caption .description-top h3, h4.h5, h5.h5, h6.h5, ul.h5, p.h5, a.h5, hr.h5, span.h5, label.h5, .container-image-landing .row .text-wrapper .container .row .text-center h2, .container-image-landing .row .text-wrapper .container-post p .row .text-center h2, .container-post .container-image-landing .row .text-wrapper p .row .text-center h2, .container-image-landing .row .text-wrapper .container-post h2 .row .text-center h2, .container-post .container-image-landing .row .text-wrapper h2 .row .text-center h2, .container-image-landing .row .text-wrapper .container-post h3 .row .text-center h2, .container-post .container-image-landing .row .text-wrapper h3 .row .text-center h2, .container-image-landing .row .text-wrapper .container-post h5 .row .text-center h2, .container-post .container-image-landing .row .text-wrapper h5 .row .text-center h2, .container-video-landing .row .text-wrapper .container .row .text-center h2, .container-video-landing .row .text-wrapper .container-post p .row .text-center h2, .container-post .container-video-landing .row .text-wrapper p .row .text-center h2, .container-video-landing .row .text-wrapper .container-post h2 .row .text-center h2, .container-post .container-video-landing .row .text-wrapper h2 .row .text-center h2, .container-video-landing .row .text-wrapper .container-post h3 .row .text-center h2, .container-post .container-video-landing .row .text-wrapper h3 .row .text-center h2, .container-video-landing .row .text-wrapper .container-post h5 .row .text-center h2, .container-post .container-video-landing .row .text-wrapper h5 .row .text-center h2 {
  font-size: 1em;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 23px;
  text-transform: uppercase;
  margin-bottom: 0; }
  @media (min-width: 992px) {
    h5, h1.h5, h2.h5, h3.h5, .gallery-interface .gallery-caption .description-top h3, h4.h5, h5.h5, h6.h5, ul.h5, p.h5, a.h5, hr.h5, span.h5, label.h5, .container-image-landing .row .text-wrapper .container .row .text-center h2, .container-image-landing .row .text-wrapper .container-post p .row .text-center h2, .container-post .container-image-landing .row .text-wrapper p .row .text-center h2, .container-image-landing .row .text-wrapper .container-post h2 .row .text-center h2, .container-post .container-image-landing .row .text-wrapper h2 .row .text-center h2, .container-image-landing .row .text-wrapper .container-post h3 .row .text-center h2, .container-post .container-image-landing .row .text-wrapper h3 .row .text-center h2, .container-image-landing .row .text-wrapper .container-post h5 .row .text-center h2, .container-post .container-image-landing .row .text-wrapper h5 .row .text-center h2, .container-video-landing .row .text-wrapper .container .row .text-center h2, .container-video-landing .row .text-wrapper .container-post p .row .text-center h2, .container-post .container-video-landing .row .text-wrapper p .row .text-center h2, .container-video-landing .row .text-wrapper .container-post h2 .row .text-center h2, .container-post .container-video-landing .row .text-wrapper h2 .row .text-center h2, .container-video-landing .row .text-wrapper .container-post h3 .row .text-center h2, .container-post .container-video-landing .row .text-wrapper h3 .row .text-center h2, .container-video-landing .row .text-wrapper .container-post h5 .row .text-center h2, .container-post .container-video-landing .row .text-wrapper h5 .row .text-center h2 {
      font-size: 1em;
      font-weight: 300;
      letter-spacing: 2px;
      line-height: 23px;
      margin-bottom: 0; } }

h6, h1.h6, h2.h6, h3.h6, h4.h6, h5.h6, h6.h6, ul.h6, p.h6, a.h6, hr.h6, span.h6, .gallery-interface .gallery-caption .description-bottom span, label.h6 {
  font-size: 0.8125em;
  font-weight: 300;
  letter-spacing: 1.6px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 0; }
  @media (min-width: 992px) {
    h6, h1.h6, h2.h6, h3.h6, h4.h6, h5.h6, h6.h6, ul.h6, p.h6, a.h6, hr.h6, span.h6, .gallery-interface .gallery-caption .description-bottom span, label.h6 {
      font-size: 0.8125em;
      font-weight: 300;
      letter-spacing: 1.6px;
      line-height: 20px;
      margin-bottom: 0; } }

p {
  font-size: 1em;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 26px; }
  p.lead {
    font-size: 1.0625em; }
  p.small {
    font-size: 0.8125em; }
  @media (min-width: 992px) {
    p {
      font-size: 1em;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 26px; } }

span {
  display: inline-block;
  position: relative;
  font-size: 1em;
  font-weight: 300;
  letter-spacing: 2px;
  color: #66666C;
  line-height: 26px;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    span {
      font-size: 1em;
      font-weight: 300;
      letter-spacing: 2px;
      line-height: 26px; } }

a {
  cursor: pointer;
  text-decoration: none;
  color: #191919;
  font-size: 1em;
  font-weight: 300;
  line-height: 21px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  /* For some Androids */
  transition: color .23s ease-out; }
  a:active, a:focus, a:visited, a:link, a:any-link {
    color: #191919; }
  a.black {
    color: #191919; }
    @media (min-width: 1px) {
      a.black:hover {
        color: #0c0c0c; } }
  a.white {
    color: #f8f8f8; }
    @media (min-width: 1px) {
      a.white:hover {
        color: #ebebeb; } }
  @media (min-width: 992px) {
    a {
      font-size: 1em;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 21px; } }

b,
strong {
  font-weight: 500; }

.image-wrapper,
.video-wrapper {
  position: relative;
  display: block;
  height: auto;
  overflow: hidden; }
  .image-wrapper a,
  .video-wrapper a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%; }
  .image-wrapper img,
  .video-wrapper img {
    position: relative;
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
    /* zoom in animation on hover */ }
    .image-wrapper img.hover-zoom,
    .video-wrapper img.hover-zoom {
      transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) !important; }
      @media (min-width: 1px) {
        .image-wrapper img.hover-zoom:hover,
        .video-wrapper img.hover-zoom:hover {
          transform: scale(1.1); } }
  .image-wrapper #video,
  .video-wrapper #video {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding-top: 56.66%; }
    .image-wrapper #video iframe,
    .video-wrapper #video iframe {
      display: block;
      overflow: hidden;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 0; }
  .image-wrapper.object-fit img,
  .video-wrapper.object-fit img {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto auto;
    object-fit: cover; }
  .image-wrapper.overlay,
  .video-wrapper.overlay {
    background: #000;
    /* force reset for mobile devices */ }
    .image-wrapper.overlay[data-overlay='1'] img,
    .image-wrapper.overlay[data-overlay='1'] .video,
    .video-wrapper.overlay[data-overlay='1'] img,
    .video-wrapper.overlay[data-overlay='1'] .video {
      opacity: 0.01; }
    .image-wrapper.overlay[data-overlay='2'] img,
    .image-wrapper.overlay[data-overlay='2'] .video,
    .video-wrapper.overlay[data-overlay='2'] img,
    .video-wrapper.overlay[data-overlay='2'] .video {
      opacity: 0.02; }
    .image-wrapper.overlay[data-overlay='3'] img,
    .image-wrapper.overlay[data-overlay='3'] .video,
    .video-wrapper.overlay[data-overlay='3'] img,
    .video-wrapper.overlay[data-overlay='3'] .video {
      opacity: 0.03; }
    .image-wrapper.overlay[data-overlay='4'] img,
    .image-wrapper.overlay[data-overlay='4'] .video,
    .video-wrapper.overlay[data-overlay='4'] img,
    .video-wrapper.overlay[data-overlay='4'] .video {
      opacity: 0.04; }
    .image-wrapper.overlay[data-overlay='5'] img,
    .image-wrapper.overlay[data-overlay='5'] .video,
    .video-wrapper.overlay[data-overlay='5'] img,
    .video-wrapper.overlay[data-overlay='5'] .video {
      opacity: 0.05; }
    .image-wrapper.overlay[data-overlay='6'] img,
    .image-wrapper.overlay[data-overlay='6'] .video,
    .video-wrapper.overlay[data-overlay='6'] img,
    .video-wrapper.overlay[data-overlay='6'] .video {
      opacity: 0.06; }
    .image-wrapper.overlay[data-overlay='7'] img,
    .image-wrapper.overlay[data-overlay='7'] .video,
    .video-wrapper.overlay[data-overlay='7'] img,
    .video-wrapper.overlay[data-overlay='7'] .video {
      opacity: 0.07; }
    .image-wrapper.overlay[data-overlay='8'] img,
    .image-wrapper.overlay[data-overlay='8'] .video,
    .video-wrapper.overlay[data-overlay='8'] img,
    .video-wrapper.overlay[data-overlay='8'] .video {
      opacity: 0.08; }
    .image-wrapper.overlay[data-overlay='9'] img,
    .image-wrapper.overlay[data-overlay='9'] .video,
    .video-wrapper.overlay[data-overlay='9'] img,
    .video-wrapper.overlay[data-overlay='9'] .video {
      opacity: 0.09; }
    .image-wrapper.overlay[data-overlay='10'] img,
    .image-wrapper.overlay[data-overlay='10'] .video,
    .video-wrapper.overlay[data-overlay='10'] img,
    .video-wrapper.overlay[data-overlay='10'] .video {
      opacity: 0.1; }
    .image-wrapper.overlay[data-overlay='11'] img,
    .image-wrapper.overlay[data-overlay='11'] .video,
    .video-wrapper.overlay[data-overlay='11'] img,
    .video-wrapper.overlay[data-overlay='11'] .video {
      opacity: 0.11; }
    .image-wrapper.overlay[data-overlay='12'] img,
    .image-wrapper.overlay[data-overlay='12'] .video,
    .video-wrapper.overlay[data-overlay='12'] img,
    .video-wrapper.overlay[data-overlay='12'] .video {
      opacity: 0.12; }
    .image-wrapper.overlay[data-overlay='13'] img,
    .image-wrapper.overlay[data-overlay='13'] .video,
    .video-wrapper.overlay[data-overlay='13'] img,
    .video-wrapper.overlay[data-overlay='13'] .video {
      opacity: 0.13; }
    .image-wrapper.overlay[data-overlay='14'] img,
    .image-wrapper.overlay[data-overlay='14'] .video,
    .video-wrapper.overlay[data-overlay='14'] img,
    .video-wrapper.overlay[data-overlay='14'] .video {
      opacity: 0.14; }
    .image-wrapper.overlay[data-overlay='15'] img,
    .image-wrapper.overlay[data-overlay='15'] .video,
    .video-wrapper.overlay[data-overlay='15'] img,
    .video-wrapper.overlay[data-overlay='15'] .video {
      opacity: 0.15; }
    .image-wrapper.overlay[data-overlay='16'] img,
    .image-wrapper.overlay[data-overlay='16'] .video,
    .video-wrapper.overlay[data-overlay='16'] img,
    .video-wrapper.overlay[data-overlay='16'] .video {
      opacity: 0.16; }
    .image-wrapper.overlay[data-overlay='17'] img,
    .image-wrapper.overlay[data-overlay='17'] .video,
    .video-wrapper.overlay[data-overlay='17'] img,
    .video-wrapper.overlay[data-overlay='17'] .video {
      opacity: 0.17; }
    .image-wrapper.overlay[data-overlay='18'] img,
    .image-wrapper.overlay[data-overlay='18'] .video,
    .video-wrapper.overlay[data-overlay='18'] img,
    .video-wrapper.overlay[data-overlay='18'] .video {
      opacity: 0.18; }
    .image-wrapper.overlay[data-overlay='19'] img,
    .image-wrapper.overlay[data-overlay='19'] .video,
    .video-wrapper.overlay[data-overlay='19'] img,
    .video-wrapper.overlay[data-overlay='19'] .video {
      opacity: 0.19; }
    .image-wrapper.overlay[data-overlay='20'] img,
    .image-wrapper.overlay[data-overlay='20'] .video,
    .video-wrapper.overlay[data-overlay='20'] img,
    .video-wrapper.overlay[data-overlay='20'] .video {
      opacity: 0.2; }
    .image-wrapper.overlay[data-overlay='21'] img,
    .image-wrapper.overlay[data-overlay='21'] .video,
    .video-wrapper.overlay[data-overlay='21'] img,
    .video-wrapper.overlay[data-overlay='21'] .video {
      opacity: 0.21; }
    .image-wrapper.overlay[data-overlay='22'] img,
    .image-wrapper.overlay[data-overlay='22'] .video,
    .video-wrapper.overlay[data-overlay='22'] img,
    .video-wrapper.overlay[data-overlay='22'] .video {
      opacity: 0.22; }
    .image-wrapper.overlay[data-overlay='23'] img,
    .image-wrapper.overlay[data-overlay='23'] .video,
    .video-wrapper.overlay[data-overlay='23'] img,
    .video-wrapper.overlay[data-overlay='23'] .video {
      opacity: 0.23; }
    .image-wrapper.overlay[data-overlay='24'] img,
    .image-wrapper.overlay[data-overlay='24'] .video,
    .video-wrapper.overlay[data-overlay='24'] img,
    .video-wrapper.overlay[data-overlay='24'] .video {
      opacity: 0.24; }
    .image-wrapper.overlay[data-overlay='25'] img,
    .image-wrapper.overlay[data-overlay='25'] .video,
    .video-wrapper.overlay[data-overlay='25'] img,
    .video-wrapper.overlay[data-overlay='25'] .video {
      opacity: 0.25; }
    .image-wrapper.overlay[data-overlay='26'] img,
    .image-wrapper.overlay[data-overlay='26'] .video,
    .video-wrapper.overlay[data-overlay='26'] img,
    .video-wrapper.overlay[data-overlay='26'] .video {
      opacity: 0.26; }
    .image-wrapper.overlay[data-overlay='27'] img,
    .image-wrapper.overlay[data-overlay='27'] .video,
    .video-wrapper.overlay[data-overlay='27'] img,
    .video-wrapper.overlay[data-overlay='27'] .video {
      opacity: 0.27; }
    .image-wrapper.overlay[data-overlay='28'] img,
    .image-wrapper.overlay[data-overlay='28'] .video,
    .video-wrapper.overlay[data-overlay='28'] img,
    .video-wrapper.overlay[data-overlay='28'] .video {
      opacity: 0.28; }
    .image-wrapper.overlay[data-overlay='29'] img,
    .image-wrapper.overlay[data-overlay='29'] .video,
    .video-wrapper.overlay[data-overlay='29'] img,
    .video-wrapper.overlay[data-overlay='29'] .video {
      opacity: 0.29; }
    .image-wrapper.overlay[data-overlay='30'] img,
    .image-wrapper.overlay[data-overlay='30'] .video,
    .video-wrapper.overlay[data-overlay='30'] img,
    .video-wrapper.overlay[data-overlay='30'] .video {
      opacity: 0.3; }
    .image-wrapper.overlay[data-overlay='31'] img,
    .image-wrapper.overlay[data-overlay='31'] .video,
    .video-wrapper.overlay[data-overlay='31'] img,
    .video-wrapper.overlay[data-overlay='31'] .video {
      opacity: 0.31; }
    .image-wrapper.overlay[data-overlay='32'] img,
    .image-wrapper.overlay[data-overlay='32'] .video,
    .video-wrapper.overlay[data-overlay='32'] img,
    .video-wrapper.overlay[data-overlay='32'] .video {
      opacity: 0.32; }
    .image-wrapper.overlay[data-overlay='33'] img,
    .image-wrapper.overlay[data-overlay='33'] .video,
    .video-wrapper.overlay[data-overlay='33'] img,
    .video-wrapper.overlay[data-overlay='33'] .video {
      opacity: 0.33; }
    .image-wrapper.overlay[data-overlay='34'] img,
    .image-wrapper.overlay[data-overlay='34'] .video,
    .video-wrapper.overlay[data-overlay='34'] img,
    .video-wrapper.overlay[data-overlay='34'] .video {
      opacity: 0.34; }
    .image-wrapper.overlay[data-overlay='35'] img,
    .image-wrapper.overlay[data-overlay='35'] .video,
    .video-wrapper.overlay[data-overlay='35'] img,
    .video-wrapper.overlay[data-overlay='35'] .video {
      opacity: 0.35; }
    .image-wrapper.overlay[data-overlay='36'] img,
    .image-wrapper.overlay[data-overlay='36'] .video,
    .video-wrapper.overlay[data-overlay='36'] img,
    .video-wrapper.overlay[data-overlay='36'] .video {
      opacity: 0.36; }
    .image-wrapper.overlay[data-overlay='37'] img,
    .image-wrapper.overlay[data-overlay='37'] .video,
    .video-wrapper.overlay[data-overlay='37'] img,
    .video-wrapper.overlay[data-overlay='37'] .video {
      opacity: 0.37; }
    .image-wrapper.overlay[data-overlay='38'] img,
    .image-wrapper.overlay[data-overlay='38'] .video,
    .video-wrapper.overlay[data-overlay='38'] img,
    .video-wrapper.overlay[data-overlay='38'] .video {
      opacity: 0.38; }
    .image-wrapper.overlay[data-overlay='39'] img,
    .image-wrapper.overlay[data-overlay='39'] .video,
    .video-wrapper.overlay[data-overlay='39'] img,
    .video-wrapper.overlay[data-overlay='39'] .video {
      opacity: 0.39; }
    .image-wrapper.overlay[data-overlay='40'] img,
    .image-wrapper.overlay[data-overlay='40'] .video,
    .video-wrapper.overlay[data-overlay='40'] img,
    .video-wrapper.overlay[data-overlay='40'] .video {
      opacity: 0.4; }
    .image-wrapper.overlay[data-overlay='41'] img,
    .image-wrapper.overlay[data-overlay='41'] .video,
    .video-wrapper.overlay[data-overlay='41'] img,
    .video-wrapper.overlay[data-overlay='41'] .video {
      opacity: 0.41; }
    .image-wrapper.overlay[data-overlay='42'] img,
    .image-wrapper.overlay[data-overlay='42'] .video,
    .video-wrapper.overlay[data-overlay='42'] img,
    .video-wrapper.overlay[data-overlay='42'] .video {
      opacity: 0.42; }
    .image-wrapper.overlay[data-overlay='43'] img,
    .image-wrapper.overlay[data-overlay='43'] .video,
    .video-wrapper.overlay[data-overlay='43'] img,
    .video-wrapper.overlay[data-overlay='43'] .video {
      opacity: 0.43; }
    .image-wrapper.overlay[data-overlay='44'] img,
    .image-wrapper.overlay[data-overlay='44'] .video,
    .video-wrapper.overlay[data-overlay='44'] img,
    .video-wrapper.overlay[data-overlay='44'] .video {
      opacity: 0.44; }
    .image-wrapper.overlay[data-overlay='45'] img,
    .image-wrapper.overlay[data-overlay='45'] .video,
    .video-wrapper.overlay[data-overlay='45'] img,
    .video-wrapper.overlay[data-overlay='45'] .video {
      opacity: 0.45; }
    .image-wrapper.overlay[data-overlay='46'] img,
    .image-wrapper.overlay[data-overlay='46'] .video,
    .video-wrapper.overlay[data-overlay='46'] img,
    .video-wrapper.overlay[data-overlay='46'] .video {
      opacity: 0.46; }
    .image-wrapper.overlay[data-overlay='47'] img,
    .image-wrapper.overlay[data-overlay='47'] .video,
    .video-wrapper.overlay[data-overlay='47'] img,
    .video-wrapper.overlay[data-overlay='47'] .video {
      opacity: 0.47; }
    .image-wrapper.overlay[data-overlay='48'] img,
    .image-wrapper.overlay[data-overlay='48'] .video,
    .video-wrapper.overlay[data-overlay='48'] img,
    .video-wrapper.overlay[data-overlay='48'] .video {
      opacity: 0.48; }
    .image-wrapper.overlay[data-overlay='49'] img,
    .image-wrapper.overlay[data-overlay='49'] .video,
    .video-wrapper.overlay[data-overlay='49'] img,
    .video-wrapper.overlay[data-overlay='49'] .video {
      opacity: 0.49; }
    .image-wrapper.overlay[data-overlay='50'] img,
    .image-wrapper.overlay[data-overlay='50'] .video,
    .video-wrapper.overlay[data-overlay='50'] img,
    .video-wrapper.overlay[data-overlay='50'] .video {
      opacity: 0.5; }
    .image-wrapper.overlay[data-overlay='51'] img,
    .image-wrapper.overlay[data-overlay='51'] .video,
    .video-wrapper.overlay[data-overlay='51'] img,
    .video-wrapper.overlay[data-overlay='51'] .video {
      opacity: 0.51; }
    .image-wrapper.overlay[data-overlay='52'] img,
    .image-wrapper.overlay[data-overlay='52'] .video,
    .video-wrapper.overlay[data-overlay='52'] img,
    .video-wrapper.overlay[data-overlay='52'] .video {
      opacity: 0.52; }
    .image-wrapper.overlay[data-overlay='53'] img,
    .image-wrapper.overlay[data-overlay='53'] .video,
    .video-wrapper.overlay[data-overlay='53'] img,
    .video-wrapper.overlay[data-overlay='53'] .video {
      opacity: 0.53; }
    .image-wrapper.overlay[data-overlay='54'] img,
    .image-wrapper.overlay[data-overlay='54'] .video,
    .video-wrapper.overlay[data-overlay='54'] img,
    .video-wrapper.overlay[data-overlay='54'] .video {
      opacity: 0.54; }
    .image-wrapper.overlay[data-overlay='55'] img,
    .image-wrapper.overlay[data-overlay='55'] .video,
    .video-wrapper.overlay[data-overlay='55'] img,
    .video-wrapper.overlay[data-overlay='55'] .video {
      opacity: 0.55; }
    .image-wrapper.overlay[data-overlay='56'] img,
    .image-wrapper.overlay[data-overlay='56'] .video,
    .video-wrapper.overlay[data-overlay='56'] img,
    .video-wrapper.overlay[data-overlay='56'] .video {
      opacity: 0.56; }
    .image-wrapper.overlay[data-overlay='57'] img,
    .image-wrapper.overlay[data-overlay='57'] .video,
    .video-wrapper.overlay[data-overlay='57'] img,
    .video-wrapper.overlay[data-overlay='57'] .video {
      opacity: 0.57; }
    .image-wrapper.overlay[data-overlay='58'] img,
    .image-wrapper.overlay[data-overlay='58'] .video,
    .video-wrapper.overlay[data-overlay='58'] img,
    .video-wrapper.overlay[data-overlay='58'] .video {
      opacity: 0.58; }
    .image-wrapper.overlay[data-overlay='59'] img,
    .image-wrapper.overlay[data-overlay='59'] .video,
    .video-wrapper.overlay[data-overlay='59'] img,
    .video-wrapper.overlay[data-overlay='59'] .video {
      opacity: 0.59; }
    .image-wrapper.overlay[data-overlay='60'] img,
    .image-wrapper.overlay[data-overlay='60'] .video,
    .video-wrapper.overlay[data-overlay='60'] img,
    .video-wrapper.overlay[data-overlay='60'] .video {
      opacity: 0.6; }
    .image-wrapper.overlay[data-overlay='61'] img,
    .image-wrapper.overlay[data-overlay='61'] .video,
    .video-wrapper.overlay[data-overlay='61'] img,
    .video-wrapper.overlay[data-overlay='61'] .video {
      opacity: 0.61; }
    .image-wrapper.overlay[data-overlay='62'] img,
    .image-wrapper.overlay[data-overlay='62'] .video,
    .video-wrapper.overlay[data-overlay='62'] img,
    .video-wrapper.overlay[data-overlay='62'] .video {
      opacity: 0.62; }
    .image-wrapper.overlay[data-overlay='63'] img,
    .image-wrapper.overlay[data-overlay='63'] .video,
    .video-wrapper.overlay[data-overlay='63'] img,
    .video-wrapper.overlay[data-overlay='63'] .video {
      opacity: 0.63; }
    .image-wrapper.overlay[data-overlay='64'] img,
    .image-wrapper.overlay[data-overlay='64'] .video,
    .video-wrapper.overlay[data-overlay='64'] img,
    .video-wrapper.overlay[data-overlay='64'] .video {
      opacity: 0.64; }
    .image-wrapper.overlay[data-overlay='65'] img,
    .image-wrapper.overlay[data-overlay='65'] .video,
    .video-wrapper.overlay[data-overlay='65'] img,
    .video-wrapper.overlay[data-overlay='65'] .video {
      opacity: 0.65; }
    .image-wrapper.overlay[data-overlay='66'] img,
    .image-wrapper.overlay[data-overlay='66'] .video,
    .video-wrapper.overlay[data-overlay='66'] img,
    .video-wrapper.overlay[data-overlay='66'] .video {
      opacity: 0.66; }
    .image-wrapper.overlay[data-overlay='67'] img,
    .image-wrapper.overlay[data-overlay='67'] .video,
    .video-wrapper.overlay[data-overlay='67'] img,
    .video-wrapper.overlay[data-overlay='67'] .video {
      opacity: 0.67; }
    .image-wrapper.overlay[data-overlay='68'] img,
    .image-wrapper.overlay[data-overlay='68'] .video,
    .video-wrapper.overlay[data-overlay='68'] img,
    .video-wrapper.overlay[data-overlay='68'] .video {
      opacity: 0.68; }
    .image-wrapper.overlay[data-overlay='69'] img,
    .image-wrapper.overlay[data-overlay='69'] .video,
    .video-wrapper.overlay[data-overlay='69'] img,
    .video-wrapper.overlay[data-overlay='69'] .video {
      opacity: 0.69; }
    .image-wrapper.overlay[data-overlay='70'] img,
    .image-wrapper.overlay[data-overlay='70'] .video,
    .video-wrapper.overlay[data-overlay='70'] img,
    .video-wrapper.overlay[data-overlay='70'] .video {
      opacity: 0.7; }
    .image-wrapper.overlay[data-overlay='71'] img,
    .image-wrapper.overlay[data-overlay='71'] .video,
    .video-wrapper.overlay[data-overlay='71'] img,
    .video-wrapper.overlay[data-overlay='71'] .video {
      opacity: 0.71; }
    .image-wrapper.overlay[data-overlay='72'] img,
    .image-wrapper.overlay[data-overlay='72'] .video,
    .video-wrapper.overlay[data-overlay='72'] img,
    .video-wrapper.overlay[data-overlay='72'] .video {
      opacity: 0.72; }
    .image-wrapper.overlay[data-overlay='73'] img,
    .image-wrapper.overlay[data-overlay='73'] .video,
    .video-wrapper.overlay[data-overlay='73'] img,
    .video-wrapper.overlay[data-overlay='73'] .video {
      opacity: 0.73; }
    .image-wrapper.overlay[data-overlay='74'] img,
    .image-wrapper.overlay[data-overlay='74'] .video,
    .video-wrapper.overlay[data-overlay='74'] img,
    .video-wrapper.overlay[data-overlay='74'] .video {
      opacity: 0.74; }
    .image-wrapper.overlay[data-overlay='75'] img,
    .image-wrapper.overlay[data-overlay='75'] .video,
    .video-wrapper.overlay[data-overlay='75'] img,
    .video-wrapper.overlay[data-overlay='75'] .video {
      opacity: 0.75; }
    .image-wrapper.overlay[data-overlay='76'] img,
    .image-wrapper.overlay[data-overlay='76'] .video,
    .video-wrapper.overlay[data-overlay='76'] img,
    .video-wrapper.overlay[data-overlay='76'] .video {
      opacity: 0.76; }
    .image-wrapper.overlay[data-overlay='77'] img,
    .image-wrapper.overlay[data-overlay='77'] .video,
    .video-wrapper.overlay[data-overlay='77'] img,
    .video-wrapper.overlay[data-overlay='77'] .video {
      opacity: 0.77; }
    .image-wrapper.overlay[data-overlay='78'] img,
    .image-wrapper.overlay[data-overlay='78'] .video,
    .video-wrapper.overlay[data-overlay='78'] img,
    .video-wrapper.overlay[data-overlay='78'] .video {
      opacity: 0.78; }
    .image-wrapper.overlay[data-overlay='79'] img,
    .image-wrapper.overlay[data-overlay='79'] .video,
    .video-wrapper.overlay[data-overlay='79'] img,
    .video-wrapper.overlay[data-overlay='79'] .video {
      opacity: 0.79; }
    .image-wrapper.overlay[data-overlay='80'] img,
    .image-wrapper.overlay[data-overlay='80'] .video,
    .video-wrapper.overlay[data-overlay='80'] img,
    .video-wrapper.overlay[data-overlay='80'] .video {
      opacity: 0.8; }
    .image-wrapper.overlay[data-overlay='81'] img,
    .image-wrapper.overlay[data-overlay='81'] .video,
    .video-wrapper.overlay[data-overlay='81'] img,
    .video-wrapper.overlay[data-overlay='81'] .video {
      opacity: 0.81; }
    .image-wrapper.overlay[data-overlay='82'] img,
    .image-wrapper.overlay[data-overlay='82'] .video,
    .video-wrapper.overlay[data-overlay='82'] img,
    .video-wrapper.overlay[data-overlay='82'] .video {
      opacity: 0.82; }
    .image-wrapper.overlay[data-overlay='83'] img,
    .image-wrapper.overlay[data-overlay='83'] .video,
    .video-wrapper.overlay[data-overlay='83'] img,
    .video-wrapper.overlay[data-overlay='83'] .video {
      opacity: 0.83; }
    .image-wrapper.overlay[data-overlay='84'] img,
    .image-wrapper.overlay[data-overlay='84'] .video,
    .video-wrapper.overlay[data-overlay='84'] img,
    .video-wrapper.overlay[data-overlay='84'] .video {
      opacity: 0.84; }
    .image-wrapper.overlay[data-overlay='85'] img,
    .image-wrapper.overlay[data-overlay='85'] .video,
    .video-wrapper.overlay[data-overlay='85'] img,
    .video-wrapper.overlay[data-overlay='85'] .video {
      opacity: 0.85; }
    .image-wrapper.overlay[data-overlay='86'] img,
    .image-wrapper.overlay[data-overlay='86'] .video,
    .video-wrapper.overlay[data-overlay='86'] img,
    .video-wrapper.overlay[data-overlay='86'] .video {
      opacity: 0.86; }
    .image-wrapper.overlay[data-overlay='87'] img,
    .image-wrapper.overlay[data-overlay='87'] .video,
    .video-wrapper.overlay[data-overlay='87'] img,
    .video-wrapper.overlay[data-overlay='87'] .video {
      opacity: 0.87; }
    .image-wrapper.overlay[data-overlay='88'] img,
    .image-wrapper.overlay[data-overlay='88'] .video,
    .video-wrapper.overlay[data-overlay='88'] img,
    .video-wrapper.overlay[data-overlay='88'] .video {
      opacity: 0.88; }
    .image-wrapper.overlay[data-overlay='89'] img,
    .image-wrapper.overlay[data-overlay='89'] .video,
    .video-wrapper.overlay[data-overlay='89'] img,
    .video-wrapper.overlay[data-overlay='89'] .video {
      opacity: 0.89; }
    .image-wrapper.overlay[data-overlay='90'] img,
    .image-wrapper.overlay[data-overlay='90'] .video,
    .video-wrapper.overlay[data-overlay='90'] img,
    .video-wrapper.overlay[data-overlay='90'] .video {
      opacity: 0.9; }
    .image-wrapper.overlay[data-overlay='91'] img,
    .image-wrapper.overlay[data-overlay='91'] .video,
    .video-wrapper.overlay[data-overlay='91'] img,
    .video-wrapper.overlay[data-overlay='91'] .video {
      opacity: 0.91; }
    .image-wrapper.overlay[data-overlay='92'] img,
    .image-wrapper.overlay[data-overlay='92'] .video,
    .video-wrapper.overlay[data-overlay='92'] img,
    .video-wrapper.overlay[data-overlay='92'] .video {
      opacity: 0.92; }
    .image-wrapper.overlay[data-overlay='93'] img,
    .image-wrapper.overlay[data-overlay='93'] .video,
    .video-wrapper.overlay[data-overlay='93'] img,
    .video-wrapper.overlay[data-overlay='93'] .video {
      opacity: 0.93; }
    .image-wrapper.overlay[data-overlay='94'] img,
    .image-wrapper.overlay[data-overlay='94'] .video,
    .video-wrapper.overlay[data-overlay='94'] img,
    .video-wrapper.overlay[data-overlay='94'] .video {
      opacity: 0.94; }
    .image-wrapper.overlay[data-overlay='95'] img,
    .image-wrapper.overlay[data-overlay='95'] .video,
    .video-wrapper.overlay[data-overlay='95'] img,
    .video-wrapper.overlay[data-overlay='95'] .video {
      opacity: 0.95; }
    .image-wrapper.overlay[data-overlay='96'] img,
    .image-wrapper.overlay[data-overlay='96'] .video,
    .video-wrapper.overlay[data-overlay='96'] img,
    .video-wrapper.overlay[data-overlay='96'] .video {
      opacity: 0.96; }
    .image-wrapper.overlay[data-overlay='97'] img,
    .image-wrapper.overlay[data-overlay='97'] .video,
    .video-wrapper.overlay[data-overlay='97'] img,
    .video-wrapper.overlay[data-overlay='97'] .video {
      opacity: 0.97; }
    .image-wrapper.overlay[data-overlay='98'] img,
    .image-wrapper.overlay[data-overlay='98'] .video,
    .video-wrapper.overlay[data-overlay='98'] img,
    .video-wrapper.overlay[data-overlay='98'] .video {
      opacity: 0.98; }
    .image-wrapper.overlay[data-overlay='99'] img,
    .image-wrapper.overlay[data-overlay='99'] .video,
    .video-wrapper.overlay[data-overlay='99'] img,
    .video-wrapper.overlay[data-overlay='99'] .video {
      opacity: 0.99; }
    .image-wrapper.overlay[data-overlay='100'] img,
    .image-wrapper.overlay[data-overlay='100'] .video,
    .video-wrapper.overlay[data-overlay='100'] img,
    .video-wrapper.overlay[data-overlay='100'] .video {
      opacity: 1; }
    @media (max-width: 991px) {
      .image-wrapper.overlay img,
      .video-wrapper.overlay img {
        transform: translate3d(0, 0, 0) !important; }
      .image-wrapper.overlay[data-overlay='1'] img,
      .image-wrapper.overlay[data-overlay='1'] .video,
      .video-wrapper.overlay[data-overlay='1'] img,
      .video-wrapper.overlay[data-overlay='1'] .video {
        opacity: 0.01 !important; }
      .image-wrapper.overlay[data-overlay='2'] img,
      .image-wrapper.overlay[data-overlay='2'] .video,
      .video-wrapper.overlay[data-overlay='2'] img,
      .video-wrapper.overlay[data-overlay='2'] .video {
        opacity: 0.02 !important; }
      .image-wrapper.overlay[data-overlay='3'] img,
      .image-wrapper.overlay[data-overlay='3'] .video,
      .video-wrapper.overlay[data-overlay='3'] img,
      .video-wrapper.overlay[data-overlay='3'] .video {
        opacity: 0.03 !important; }
      .image-wrapper.overlay[data-overlay='4'] img,
      .image-wrapper.overlay[data-overlay='4'] .video,
      .video-wrapper.overlay[data-overlay='4'] img,
      .video-wrapper.overlay[data-overlay='4'] .video {
        opacity: 0.04 !important; }
      .image-wrapper.overlay[data-overlay='5'] img,
      .image-wrapper.overlay[data-overlay='5'] .video,
      .video-wrapper.overlay[data-overlay='5'] img,
      .video-wrapper.overlay[data-overlay='5'] .video {
        opacity: 0.05 !important; }
      .image-wrapper.overlay[data-overlay='6'] img,
      .image-wrapper.overlay[data-overlay='6'] .video,
      .video-wrapper.overlay[data-overlay='6'] img,
      .video-wrapper.overlay[data-overlay='6'] .video {
        opacity: 0.06 !important; }
      .image-wrapper.overlay[data-overlay='7'] img,
      .image-wrapper.overlay[data-overlay='7'] .video,
      .video-wrapper.overlay[data-overlay='7'] img,
      .video-wrapper.overlay[data-overlay='7'] .video {
        opacity: 0.07 !important; }
      .image-wrapper.overlay[data-overlay='8'] img,
      .image-wrapper.overlay[data-overlay='8'] .video,
      .video-wrapper.overlay[data-overlay='8'] img,
      .video-wrapper.overlay[data-overlay='8'] .video {
        opacity: 0.08 !important; }
      .image-wrapper.overlay[data-overlay='9'] img,
      .image-wrapper.overlay[data-overlay='9'] .video,
      .video-wrapper.overlay[data-overlay='9'] img,
      .video-wrapper.overlay[data-overlay='9'] .video {
        opacity: 0.09 !important; }
      .image-wrapper.overlay[data-overlay='10'] img,
      .image-wrapper.overlay[data-overlay='10'] .video,
      .video-wrapper.overlay[data-overlay='10'] img,
      .video-wrapper.overlay[data-overlay='10'] .video {
        opacity: 0.1 !important; }
      .image-wrapper.overlay[data-overlay='11'] img,
      .image-wrapper.overlay[data-overlay='11'] .video,
      .video-wrapper.overlay[data-overlay='11'] img,
      .video-wrapper.overlay[data-overlay='11'] .video {
        opacity: 0.11 !important; }
      .image-wrapper.overlay[data-overlay='12'] img,
      .image-wrapper.overlay[data-overlay='12'] .video,
      .video-wrapper.overlay[data-overlay='12'] img,
      .video-wrapper.overlay[data-overlay='12'] .video {
        opacity: 0.12 !important; }
      .image-wrapper.overlay[data-overlay='13'] img,
      .image-wrapper.overlay[data-overlay='13'] .video,
      .video-wrapper.overlay[data-overlay='13'] img,
      .video-wrapper.overlay[data-overlay='13'] .video {
        opacity: 0.13 !important; }
      .image-wrapper.overlay[data-overlay='14'] img,
      .image-wrapper.overlay[data-overlay='14'] .video,
      .video-wrapper.overlay[data-overlay='14'] img,
      .video-wrapper.overlay[data-overlay='14'] .video {
        opacity: 0.14 !important; }
      .image-wrapper.overlay[data-overlay='15'] img,
      .image-wrapper.overlay[data-overlay='15'] .video,
      .video-wrapper.overlay[data-overlay='15'] img,
      .video-wrapper.overlay[data-overlay='15'] .video {
        opacity: 0.15 !important; }
      .image-wrapper.overlay[data-overlay='16'] img,
      .image-wrapper.overlay[data-overlay='16'] .video,
      .video-wrapper.overlay[data-overlay='16'] img,
      .video-wrapper.overlay[data-overlay='16'] .video {
        opacity: 0.16 !important; }
      .image-wrapper.overlay[data-overlay='17'] img,
      .image-wrapper.overlay[data-overlay='17'] .video,
      .video-wrapper.overlay[data-overlay='17'] img,
      .video-wrapper.overlay[data-overlay='17'] .video {
        opacity: 0.17 !important; }
      .image-wrapper.overlay[data-overlay='18'] img,
      .image-wrapper.overlay[data-overlay='18'] .video,
      .video-wrapper.overlay[data-overlay='18'] img,
      .video-wrapper.overlay[data-overlay='18'] .video {
        opacity: 0.18 !important; }
      .image-wrapper.overlay[data-overlay='19'] img,
      .image-wrapper.overlay[data-overlay='19'] .video,
      .video-wrapper.overlay[data-overlay='19'] img,
      .video-wrapper.overlay[data-overlay='19'] .video {
        opacity: 0.19 !important; }
      .image-wrapper.overlay[data-overlay='20'] img,
      .image-wrapper.overlay[data-overlay='20'] .video,
      .video-wrapper.overlay[data-overlay='20'] img,
      .video-wrapper.overlay[data-overlay='20'] .video {
        opacity: 0.2 !important; }
      .image-wrapper.overlay[data-overlay='21'] img,
      .image-wrapper.overlay[data-overlay='21'] .video,
      .video-wrapper.overlay[data-overlay='21'] img,
      .video-wrapper.overlay[data-overlay='21'] .video {
        opacity: 0.21 !important; }
      .image-wrapper.overlay[data-overlay='22'] img,
      .image-wrapper.overlay[data-overlay='22'] .video,
      .video-wrapper.overlay[data-overlay='22'] img,
      .video-wrapper.overlay[data-overlay='22'] .video {
        opacity: 0.22 !important; }
      .image-wrapper.overlay[data-overlay='23'] img,
      .image-wrapper.overlay[data-overlay='23'] .video,
      .video-wrapper.overlay[data-overlay='23'] img,
      .video-wrapper.overlay[data-overlay='23'] .video {
        opacity: 0.23 !important; }
      .image-wrapper.overlay[data-overlay='24'] img,
      .image-wrapper.overlay[data-overlay='24'] .video,
      .video-wrapper.overlay[data-overlay='24'] img,
      .video-wrapper.overlay[data-overlay='24'] .video {
        opacity: 0.24 !important; }
      .image-wrapper.overlay[data-overlay='25'] img,
      .image-wrapper.overlay[data-overlay='25'] .video,
      .video-wrapper.overlay[data-overlay='25'] img,
      .video-wrapper.overlay[data-overlay='25'] .video {
        opacity: 0.25 !important; }
      .image-wrapper.overlay[data-overlay='26'] img,
      .image-wrapper.overlay[data-overlay='26'] .video,
      .video-wrapper.overlay[data-overlay='26'] img,
      .video-wrapper.overlay[data-overlay='26'] .video {
        opacity: 0.26 !important; }
      .image-wrapper.overlay[data-overlay='27'] img,
      .image-wrapper.overlay[data-overlay='27'] .video,
      .video-wrapper.overlay[data-overlay='27'] img,
      .video-wrapper.overlay[data-overlay='27'] .video {
        opacity: 0.27 !important; }
      .image-wrapper.overlay[data-overlay='28'] img,
      .image-wrapper.overlay[data-overlay='28'] .video,
      .video-wrapper.overlay[data-overlay='28'] img,
      .video-wrapper.overlay[data-overlay='28'] .video {
        opacity: 0.28 !important; }
      .image-wrapper.overlay[data-overlay='29'] img,
      .image-wrapper.overlay[data-overlay='29'] .video,
      .video-wrapper.overlay[data-overlay='29'] img,
      .video-wrapper.overlay[data-overlay='29'] .video {
        opacity: 0.29 !important; }
      .image-wrapper.overlay[data-overlay='30'] img,
      .image-wrapper.overlay[data-overlay='30'] .video,
      .video-wrapper.overlay[data-overlay='30'] img,
      .video-wrapper.overlay[data-overlay='30'] .video {
        opacity: 0.3 !important; }
      .image-wrapper.overlay[data-overlay='31'] img,
      .image-wrapper.overlay[data-overlay='31'] .video,
      .video-wrapper.overlay[data-overlay='31'] img,
      .video-wrapper.overlay[data-overlay='31'] .video {
        opacity: 0.31 !important; }
      .image-wrapper.overlay[data-overlay='32'] img,
      .image-wrapper.overlay[data-overlay='32'] .video,
      .video-wrapper.overlay[data-overlay='32'] img,
      .video-wrapper.overlay[data-overlay='32'] .video {
        opacity: 0.32 !important; }
      .image-wrapper.overlay[data-overlay='33'] img,
      .image-wrapper.overlay[data-overlay='33'] .video,
      .video-wrapper.overlay[data-overlay='33'] img,
      .video-wrapper.overlay[data-overlay='33'] .video {
        opacity: 0.33 !important; }
      .image-wrapper.overlay[data-overlay='34'] img,
      .image-wrapper.overlay[data-overlay='34'] .video,
      .video-wrapper.overlay[data-overlay='34'] img,
      .video-wrapper.overlay[data-overlay='34'] .video {
        opacity: 0.34 !important; }
      .image-wrapper.overlay[data-overlay='35'] img,
      .image-wrapper.overlay[data-overlay='35'] .video,
      .video-wrapper.overlay[data-overlay='35'] img,
      .video-wrapper.overlay[data-overlay='35'] .video {
        opacity: 0.35 !important; }
      .image-wrapper.overlay[data-overlay='36'] img,
      .image-wrapper.overlay[data-overlay='36'] .video,
      .video-wrapper.overlay[data-overlay='36'] img,
      .video-wrapper.overlay[data-overlay='36'] .video {
        opacity: 0.36 !important; }
      .image-wrapper.overlay[data-overlay='37'] img,
      .image-wrapper.overlay[data-overlay='37'] .video,
      .video-wrapper.overlay[data-overlay='37'] img,
      .video-wrapper.overlay[data-overlay='37'] .video {
        opacity: 0.37 !important; }
      .image-wrapper.overlay[data-overlay='38'] img,
      .image-wrapper.overlay[data-overlay='38'] .video,
      .video-wrapper.overlay[data-overlay='38'] img,
      .video-wrapper.overlay[data-overlay='38'] .video {
        opacity: 0.38 !important; }
      .image-wrapper.overlay[data-overlay='39'] img,
      .image-wrapper.overlay[data-overlay='39'] .video,
      .video-wrapper.overlay[data-overlay='39'] img,
      .video-wrapper.overlay[data-overlay='39'] .video {
        opacity: 0.39 !important; }
      .image-wrapper.overlay[data-overlay='40'] img,
      .image-wrapper.overlay[data-overlay='40'] .video,
      .video-wrapper.overlay[data-overlay='40'] img,
      .video-wrapper.overlay[data-overlay='40'] .video {
        opacity: 0.4 !important; }
      .image-wrapper.overlay[data-overlay='41'] img,
      .image-wrapper.overlay[data-overlay='41'] .video,
      .video-wrapper.overlay[data-overlay='41'] img,
      .video-wrapper.overlay[data-overlay='41'] .video {
        opacity: 0.41 !important; }
      .image-wrapper.overlay[data-overlay='42'] img,
      .image-wrapper.overlay[data-overlay='42'] .video,
      .video-wrapper.overlay[data-overlay='42'] img,
      .video-wrapper.overlay[data-overlay='42'] .video {
        opacity: 0.42 !important; }
      .image-wrapper.overlay[data-overlay='43'] img,
      .image-wrapper.overlay[data-overlay='43'] .video,
      .video-wrapper.overlay[data-overlay='43'] img,
      .video-wrapper.overlay[data-overlay='43'] .video {
        opacity: 0.43 !important; }
      .image-wrapper.overlay[data-overlay='44'] img,
      .image-wrapper.overlay[data-overlay='44'] .video,
      .video-wrapper.overlay[data-overlay='44'] img,
      .video-wrapper.overlay[data-overlay='44'] .video {
        opacity: 0.44 !important; }
      .image-wrapper.overlay[data-overlay='45'] img,
      .image-wrapper.overlay[data-overlay='45'] .video,
      .video-wrapper.overlay[data-overlay='45'] img,
      .video-wrapper.overlay[data-overlay='45'] .video {
        opacity: 0.45 !important; }
      .image-wrapper.overlay[data-overlay='46'] img,
      .image-wrapper.overlay[data-overlay='46'] .video,
      .video-wrapper.overlay[data-overlay='46'] img,
      .video-wrapper.overlay[data-overlay='46'] .video {
        opacity: 0.46 !important; }
      .image-wrapper.overlay[data-overlay='47'] img,
      .image-wrapper.overlay[data-overlay='47'] .video,
      .video-wrapper.overlay[data-overlay='47'] img,
      .video-wrapper.overlay[data-overlay='47'] .video {
        opacity: 0.47 !important; }
      .image-wrapper.overlay[data-overlay='48'] img,
      .image-wrapper.overlay[data-overlay='48'] .video,
      .video-wrapper.overlay[data-overlay='48'] img,
      .video-wrapper.overlay[data-overlay='48'] .video {
        opacity: 0.48 !important; }
      .image-wrapper.overlay[data-overlay='49'] img,
      .image-wrapper.overlay[data-overlay='49'] .video,
      .video-wrapper.overlay[data-overlay='49'] img,
      .video-wrapper.overlay[data-overlay='49'] .video {
        opacity: 0.49 !important; }
      .image-wrapper.overlay[data-overlay='50'] img,
      .image-wrapper.overlay[data-overlay='50'] .video,
      .video-wrapper.overlay[data-overlay='50'] img,
      .video-wrapper.overlay[data-overlay='50'] .video {
        opacity: 0.5 !important; }
      .image-wrapper.overlay[data-overlay='51'] img,
      .image-wrapper.overlay[data-overlay='51'] .video,
      .video-wrapper.overlay[data-overlay='51'] img,
      .video-wrapper.overlay[data-overlay='51'] .video {
        opacity: 0.51 !important; }
      .image-wrapper.overlay[data-overlay='52'] img,
      .image-wrapper.overlay[data-overlay='52'] .video,
      .video-wrapper.overlay[data-overlay='52'] img,
      .video-wrapper.overlay[data-overlay='52'] .video {
        opacity: 0.52 !important; }
      .image-wrapper.overlay[data-overlay='53'] img,
      .image-wrapper.overlay[data-overlay='53'] .video,
      .video-wrapper.overlay[data-overlay='53'] img,
      .video-wrapper.overlay[data-overlay='53'] .video {
        opacity: 0.53 !important; }
      .image-wrapper.overlay[data-overlay='54'] img,
      .image-wrapper.overlay[data-overlay='54'] .video,
      .video-wrapper.overlay[data-overlay='54'] img,
      .video-wrapper.overlay[data-overlay='54'] .video {
        opacity: 0.54 !important; }
      .image-wrapper.overlay[data-overlay='55'] img,
      .image-wrapper.overlay[data-overlay='55'] .video,
      .video-wrapper.overlay[data-overlay='55'] img,
      .video-wrapper.overlay[data-overlay='55'] .video {
        opacity: 0.55 !important; }
      .image-wrapper.overlay[data-overlay='56'] img,
      .image-wrapper.overlay[data-overlay='56'] .video,
      .video-wrapper.overlay[data-overlay='56'] img,
      .video-wrapper.overlay[data-overlay='56'] .video {
        opacity: 0.56 !important; }
      .image-wrapper.overlay[data-overlay='57'] img,
      .image-wrapper.overlay[data-overlay='57'] .video,
      .video-wrapper.overlay[data-overlay='57'] img,
      .video-wrapper.overlay[data-overlay='57'] .video {
        opacity: 0.57 !important; }
      .image-wrapper.overlay[data-overlay='58'] img,
      .image-wrapper.overlay[data-overlay='58'] .video,
      .video-wrapper.overlay[data-overlay='58'] img,
      .video-wrapper.overlay[data-overlay='58'] .video {
        opacity: 0.58 !important; }
      .image-wrapper.overlay[data-overlay='59'] img,
      .image-wrapper.overlay[data-overlay='59'] .video,
      .video-wrapper.overlay[data-overlay='59'] img,
      .video-wrapper.overlay[data-overlay='59'] .video {
        opacity: 0.59 !important; }
      .image-wrapper.overlay[data-overlay='60'] img,
      .image-wrapper.overlay[data-overlay='60'] .video,
      .video-wrapper.overlay[data-overlay='60'] img,
      .video-wrapper.overlay[data-overlay='60'] .video {
        opacity: 0.6 !important; }
      .image-wrapper.overlay[data-overlay='61'] img,
      .image-wrapper.overlay[data-overlay='61'] .video,
      .video-wrapper.overlay[data-overlay='61'] img,
      .video-wrapper.overlay[data-overlay='61'] .video {
        opacity: 0.61 !important; }
      .image-wrapper.overlay[data-overlay='62'] img,
      .image-wrapper.overlay[data-overlay='62'] .video,
      .video-wrapper.overlay[data-overlay='62'] img,
      .video-wrapper.overlay[data-overlay='62'] .video {
        opacity: 0.62 !important; }
      .image-wrapper.overlay[data-overlay='63'] img,
      .image-wrapper.overlay[data-overlay='63'] .video,
      .video-wrapper.overlay[data-overlay='63'] img,
      .video-wrapper.overlay[data-overlay='63'] .video {
        opacity: 0.63 !important; }
      .image-wrapper.overlay[data-overlay='64'] img,
      .image-wrapper.overlay[data-overlay='64'] .video,
      .video-wrapper.overlay[data-overlay='64'] img,
      .video-wrapper.overlay[data-overlay='64'] .video {
        opacity: 0.64 !important; }
      .image-wrapper.overlay[data-overlay='65'] img,
      .image-wrapper.overlay[data-overlay='65'] .video,
      .video-wrapper.overlay[data-overlay='65'] img,
      .video-wrapper.overlay[data-overlay='65'] .video {
        opacity: 0.65 !important; }
      .image-wrapper.overlay[data-overlay='66'] img,
      .image-wrapper.overlay[data-overlay='66'] .video,
      .video-wrapper.overlay[data-overlay='66'] img,
      .video-wrapper.overlay[data-overlay='66'] .video {
        opacity: 0.66 !important; }
      .image-wrapper.overlay[data-overlay='67'] img,
      .image-wrapper.overlay[data-overlay='67'] .video,
      .video-wrapper.overlay[data-overlay='67'] img,
      .video-wrapper.overlay[data-overlay='67'] .video {
        opacity: 0.67 !important; }
      .image-wrapper.overlay[data-overlay='68'] img,
      .image-wrapper.overlay[data-overlay='68'] .video,
      .video-wrapper.overlay[data-overlay='68'] img,
      .video-wrapper.overlay[data-overlay='68'] .video {
        opacity: 0.68 !important; }
      .image-wrapper.overlay[data-overlay='69'] img,
      .image-wrapper.overlay[data-overlay='69'] .video,
      .video-wrapper.overlay[data-overlay='69'] img,
      .video-wrapper.overlay[data-overlay='69'] .video {
        opacity: 0.69 !important; }
      .image-wrapper.overlay[data-overlay='70'] img,
      .image-wrapper.overlay[data-overlay='70'] .video,
      .video-wrapper.overlay[data-overlay='70'] img,
      .video-wrapper.overlay[data-overlay='70'] .video {
        opacity: 0.7 !important; }
      .image-wrapper.overlay[data-overlay='71'] img,
      .image-wrapper.overlay[data-overlay='71'] .video,
      .video-wrapper.overlay[data-overlay='71'] img,
      .video-wrapper.overlay[data-overlay='71'] .video {
        opacity: 0.71 !important; }
      .image-wrapper.overlay[data-overlay='72'] img,
      .image-wrapper.overlay[data-overlay='72'] .video,
      .video-wrapper.overlay[data-overlay='72'] img,
      .video-wrapper.overlay[data-overlay='72'] .video {
        opacity: 0.72 !important; }
      .image-wrapper.overlay[data-overlay='73'] img,
      .image-wrapper.overlay[data-overlay='73'] .video,
      .video-wrapper.overlay[data-overlay='73'] img,
      .video-wrapper.overlay[data-overlay='73'] .video {
        opacity: 0.73 !important; }
      .image-wrapper.overlay[data-overlay='74'] img,
      .image-wrapper.overlay[data-overlay='74'] .video,
      .video-wrapper.overlay[data-overlay='74'] img,
      .video-wrapper.overlay[data-overlay='74'] .video {
        opacity: 0.74 !important; }
      .image-wrapper.overlay[data-overlay='75'] img,
      .image-wrapper.overlay[data-overlay='75'] .video,
      .video-wrapper.overlay[data-overlay='75'] img,
      .video-wrapper.overlay[data-overlay='75'] .video {
        opacity: 0.75 !important; }
      .image-wrapper.overlay[data-overlay='76'] img,
      .image-wrapper.overlay[data-overlay='76'] .video,
      .video-wrapper.overlay[data-overlay='76'] img,
      .video-wrapper.overlay[data-overlay='76'] .video {
        opacity: 0.76 !important; }
      .image-wrapper.overlay[data-overlay='77'] img,
      .image-wrapper.overlay[data-overlay='77'] .video,
      .video-wrapper.overlay[data-overlay='77'] img,
      .video-wrapper.overlay[data-overlay='77'] .video {
        opacity: 0.77 !important; }
      .image-wrapper.overlay[data-overlay='78'] img,
      .image-wrapper.overlay[data-overlay='78'] .video,
      .video-wrapper.overlay[data-overlay='78'] img,
      .video-wrapper.overlay[data-overlay='78'] .video {
        opacity: 0.78 !important; }
      .image-wrapper.overlay[data-overlay='79'] img,
      .image-wrapper.overlay[data-overlay='79'] .video,
      .video-wrapper.overlay[data-overlay='79'] img,
      .video-wrapper.overlay[data-overlay='79'] .video {
        opacity: 0.79 !important; }
      .image-wrapper.overlay[data-overlay='80'] img,
      .image-wrapper.overlay[data-overlay='80'] .video,
      .video-wrapper.overlay[data-overlay='80'] img,
      .video-wrapper.overlay[data-overlay='80'] .video {
        opacity: 0.8 !important; }
      .image-wrapper.overlay[data-overlay='81'] img,
      .image-wrapper.overlay[data-overlay='81'] .video,
      .video-wrapper.overlay[data-overlay='81'] img,
      .video-wrapper.overlay[data-overlay='81'] .video {
        opacity: 0.81 !important; }
      .image-wrapper.overlay[data-overlay='82'] img,
      .image-wrapper.overlay[data-overlay='82'] .video,
      .video-wrapper.overlay[data-overlay='82'] img,
      .video-wrapper.overlay[data-overlay='82'] .video {
        opacity: 0.82 !important; }
      .image-wrapper.overlay[data-overlay='83'] img,
      .image-wrapper.overlay[data-overlay='83'] .video,
      .video-wrapper.overlay[data-overlay='83'] img,
      .video-wrapper.overlay[data-overlay='83'] .video {
        opacity: 0.83 !important; }
      .image-wrapper.overlay[data-overlay='84'] img,
      .image-wrapper.overlay[data-overlay='84'] .video,
      .video-wrapper.overlay[data-overlay='84'] img,
      .video-wrapper.overlay[data-overlay='84'] .video {
        opacity: 0.84 !important; }
      .image-wrapper.overlay[data-overlay='85'] img,
      .image-wrapper.overlay[data-overlay='85'] .video,
      .video-wrapper.overlay[data-overlay='85'] img,
      .video-wrapper.overlay[data-overlay='85'] .video {
        opacity: 0.85 !important; }
      .image-wrapper.overlay[data-overlay='86'] img,
      .image-wrapper.overlay[data-overlay='86'] .video,
      .video-wrapper.overlay[data-overlay='86'] img,
      .video-wrapper.overlay[data-overlay='86'] .video {
        opacity: 0.86 !important; }
      .image-wrapper.overlay[data-overlay='87'] img,
      .image-wrapper.overlay[data-overlay='87'] .video,
      .video-wrapper.overlay[data-overlay='87'] img,
      .video-wrapper.overlay[data-overlay='87'] .video {
        opacity: 0.87 !important; }
      .image-wrapper.overlay[data-overlay='88'] img,
      .image-wrapper.overlay[data-overlay='88'] .video,
      .video-wrapper.overlay[data-overlay='88'] img,
      .video-wrapper.overlay[data-overlay='88'] .video {
        opacity: 0.88 !important; }
      .image-wrapper.overlay[data-overlay='89'] img,
      .image-wrapper.overlay[data-overlay='89'] .video,
      .video-wrapper.overlay[data-overlay='89'] img,
      .video-wrapper.overlay[data-overlay='89'] .video {
        opacity: 0.89 !important; }
      .image-wrapper.overlay[data-overlay='90'] img,
      .image-wrapper.overlay[data-overlay='90'] .video,
      .video-wrapper.overlay[data-overlay='90'] img,
      .video-wrapper.overlay[data-overlay='90'] .video {
        opacity: 0.9 !important; }
      .image-wrapper.overlay[data-overlay='91'] img,
      .image-wrapper.overlay[data-overlay='91'] .video,
      .video-wrapper.overlay[data-overlay='91'] img,
      .video-wrapper.overlay[data-overlay='91'] .video {
        opacity: 0.91 !important; }
      .image-wrapper.overlay[data-overlay='92'] img,
      .image-wrapper.overlay[data-overlay='92'] .video,
      .video-wrapper.overlay[data-overlay='92'] img,
      .video-wrapper.overlay[data-overlay='92'] .video {
        opacity: 0.92 !important; }
      .image-wrapper.overlay[data-overlay='93'] img,
      .image-wrapper.overlay[data-overlay='93'] .video,
      .video-wrapper.overlay[data-overlay='93'] img,
      .video-wrapper.overlay[data-overlay='93'] .video {
        opacity: 0.93 !important; }
      .image-wrapper.overlay[data-overlay='94'] img,
      .image-wrapper.overlay[data-overlay='94'] .video,
      .video-wrapper.overlay[data-overlay='94'] img,
      .video-wrapper.overlay[data-overlay='94'] .video {
        opacity: 0.94 !important; }
      .image-wrapper.overlay[data-overlay='95'] img,
      .image-wrapper.overlay[data-overlay='95'] .video,
      .video-wrapper.overlay[data-overlay='95'] img,
      .video-wrapper.overlay[data-overlay='95'] .video {
        opacity: 0.95 !important; }
      .image-wrapper.overlay[data-overlay='96'] img,
      .image-wrapper.overlay[data-overlay='96'] .video,
      .video-wrapper.overlay[data-overlay='96'] img,
      .video-wrapper.overlay[data-overlay='96'] .video {
        opacity: 0.96 !important; }
      .image-wrapper.overlay[data-overlay='97'] img,
      .image-wrapper.overlay[data-overlay='97'] .video,
      .video-wrapper.overlay[data-overlay='97'] img,
      .video-wrapper.overlay[data-overlay='97'] .video {
        opacity: 0.97 !important; }
      .image-wrapper.overlay[data-overlay='98'] img,
      .image-wrapper.overlay[data-overlay='98'] .video,
      .video-wrapper.overlay[data-overlay='98'] img,
      .video-wrapper.overlay[data-overlay='98'] .video {
        opacity: 0.98 !important; }
      .image-wrapper.overlay[data-overlay='99'] img,
      .image-wrapper.overlay[data-overlay='99'] .video,
      .video-wrapper.overlay[data-overlay='99'] img,
      .video-wrapper.overlay[data-overlay='99'] .video {
        opacity: 0.99 !important; }
      .image-wrapper.overlay[data-overlay='100'] img,
      .image-wrapper.overlay[data-overlay='100'] .video,
      .video-wrapper.overlay[data-overlay='100'] img,
      .video-wrapper.overlay[data-overlay='100'] .video {
        opacity: 1 !important; } }

.slider, .slider-single {
  position: relative;
  overflow: hidden;
  margin: 0 0 30px 0;
  padding: 0;
  background: transparent;
  /* Reset any weird spacing */
  /* carousel */
  /* vertical slides */
  /* fadeing slide */
  /* navigation */ }
  @media (min-width: 992px) {
    .slider, .slider-single {
      margin: 0 0 0 0; } }
  .slider ul, .slider li, .slider-single ul, .slider-single li {
    list-style: none;
    margin: 0;
    padding: 0;
    border: none;
    position: relative;
    height: 100%;
    overflow: hidden; }
    .slider ul img, .slider li img, .slider-single ul img, .slider-single li img {
      position: absolute;
      display: block;
      left: 0;
      top: -100%;
      bottom: -100%;
      right: 0;
      margin: auto 0;
      min-width: 100%;
      max-width: 100%;
      cursor: pointer; }
  .slider-horizontal, .slider-single-horizontal {
    position: relative; }
    .slider-horizontal ul li, .slider-single-horizontal ul li {
      float: left;
      display: inline-block; }
  .slider-vertical, .slider-single-vertical {
    position: relative; }
    .slider-vertical ul, .slider-single-vertical ul {
      height: 100%;
      left: 0 !important; }
      .slider-vertical ul li, .slider-single-vertical ul li {
        float: none;
        width: 100%;
        display: block; }
  .slider-fade, .slider-single-fade {
    position: relative; }
    .slider-fade ul li, .slider-single-fade ul li {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      z-index: 8; }
      .slider-fade ul li .animate-wrapper, .slider-single-fade ul li .animate-wrapper {
        overflow: hidden;
        position: absolute;
        background: transparent;
        left: 0;
        top: 0;
        bottom: 0;
        perspective: 1000px;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        width: 0;
        transition: 0;
        transition-delay: 0.9s; }
        .slider-fade ul li .animate-wrapper img, .slider-single-fade ul li .animate-wrapper img {
          perspective: 1000px;
          transform-style: preserve-3d;
          backface-visibility: hidden;
          transform: translateX(-20%) !important;
          transition: all 0s 0s linear !important;
          transition-delay: 1.8s !important;
          min-width: none;
          max-width: none; }
      .slider-fade ul li.active, .slider-single-fade ul li.active {
        z-index: 10; }
        .slider-fade ul li.active .animate-wrapper, .slider-single-fade ul li.active .animate-wrapper {
          transition-delay: 0;
          transition: width 0.9s cubic-bezier(0.8, 0, 0.2, 1);
          width: 100%; }
          .slider-fade ul li.active .animate-wrapper img, .slider-single-fade ul li.active .animate-wrapper img {
            transform: translateX(0) !important;
            transition-delay: 0;
            transition: transform 0.3s cubic-bezier(0.8, 0, 0.2, 1) !important; }
  .slider-nav, .slider-single-nav {
    position: absolute;
    display: block;
    height: 80px;
    top: -110px;
    right: 0;
    left: 0;
    padding-left: 25px;
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
    pointer-events: none; }
    @media (min-width: 768px) {
      .slider-nav, .slider-single-nav {
        top: -110px;
        opacity: 0;
        transition: opacity .4s ease;
        transition-delay: 0.75s; } }
    @media (min-width: 992px) {
      .slider-nav, .slider-single-nav {
        display: block;
        position: relative;
        margin-top: 40px;
        top: 0;
        width: auto;
        padding-left: 0;
        background-image: none;
        height: auto;
        pointer-events: all;
        opacity: 1;
        transition: none;
        transition-delay: none; } }
    .slider-nav .slider-nav-button, .slider-single-nav .slider-nav-button {
      position: relative;
      display: inline-block;
      width: 40px;
      margin-right: 10px;
      height: 40px;
      background: transparent;
      cursor: pointer;
      user-select: none;
      margin-top: 30px;
      pointer-events: all; }
      @media (min-width: 992px) {
        .slider-nav .slider-nav-button, .slider-single-nav .slider-nav-button {
          margin-top: -21px; } }
      .slider-nav .slider-nav-button .line, .slider-single-nav .slider-nav-button .line {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 2px;
        margin-top: 21px;
        background: rgba(255, 255, 255, 0.4);
        overflow: hidden;
        pointer-events: none; }
        @media (min-width: 992px) {
          .slider-nav .slider-nav-button .line, .slider-single-nav .slider-nav-button .line {
            background: #CCCCCC; } }
        .slider-nav .slider-nav-button .line:after, .slider-single-nav .slider-nav-button .line:after {
          content: '';
          position: absolute;
          left: -41px;
          top: 0;
          width: 41px;
          height: 2px;
          background: #ffffff;
          transform: translateX(0); }
          @media (min-width: 992px) {
            .slider-nav .slider-nav-button .line:after, .slider-single-nav .slider-nav-button .line:after {
              background: #333333; } }
      .slider-nav .slider-nav-button.active .line:after, .slider-single-nav .slider-nav-button.active .line:after {
        transform: translate3d(100%, 0, 0); }
      .slider-nav .slider-nav-button.active[data-delay='3000'] .line:after, .slider-single-nav .slider-nav-button.active[data-delay='3000'] .line:after {
        transition: transform 3s linear; }
      .slider-nav .slider-nav-button.active[data-delay='4000'] .line:after, .slider-single-nav .slider-nav-button.active[data-delay='4000'] .line:after {
        transition: transform 4s linear; }
      .slider-nav .slider-nav-button.active[data-delay='5000'] .line:after, .slider-single-nav .slider-nav-button.active[data-delay='5000'] .line:after {
        transition: transform 5s linear; }
      .slider-nav .slider-nav-button.active[data-delay='6000'] .line:after, .slider-single-nav .slider-nav-button.active[data-delay='6000'] .line:after {
        transition: transform 6s linear; }
      .slider-nav .slider-nav-button.active[data-delay='7000'] .line:after, .slider-single-nav .slider-nav-button.active[data-delay='7000'] .line:after {
        transition: transform 7s linear; }
      .slider-nav .slider-nav-button.active[data-delay='8000'] .line:after, .slider-single-nav .slider-nav-button.active[data-delay='8000'] .line:after {
        transition: transform 8s linear; }
      .slider-nav .slider-nav-button.active[data-delay='9000'] .line:after, .slider-single-nav .slider-nav-button.active[data-delay='9000'] .line:after {
        transition: transform 9s linear; }
      .slider-nav .slider-nav-button.active[data-delay='10000'] .line:after, .slider-single-nav .slider-nav-button.active[data-delay='10000'] .line:after {
        transition: transform 10s linear; }
    .slider-nav.slider-stop .active .line:after, .slider-single-nav.slider-stop .active .line:after {
      transition: transform 0s linear !important; }

.visible .slider-nav {
  opacity: 1; }

.slider-single ul li img {
  cursor: default; }

a.button,
button {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.8125em;
  letter-spacing: 1.6px;
  font-weight: 500;
  min-width: 200px;
  height: 45px;
  line-height: 44px;
  color: #191922;
  border: 1px solid #191922;
  background: transparent;
  transition: all 0.43s cubic-bezier(0.65, 0.1, 0.3, 1), color 0.473s cubic-bezier(0.65, 0.1, 0.3, 1);
  text-align: center;
  margin-top: 40px;
  overflow: hidden;
  z-index: 1;
  padding: 0 15px;
  /* pseudo elements for animation */
  /* white button */
  /* hover animation */ }
  @media (min-width: 992px) {
    a.button,
    button {
      margin-top: 40px; } }
  a.button:before, a.button:after,
  button:before,
  button:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #191922;
    z-index: -1; }
  a.button:before,
  button:before {
    transform: translate3d(-100%, 0, 0); }
  a.button:after,
  button:after {
    transform: translate3d(100%, 0, 0);
    transition: transform 0.43s cubic-bezier(0.65, 0.1, 0.3, 1); }
  a.button.white,
  button.white {
    color: #ffffff;
    border: 1px solid #ffffff; }
    @media (min-width: 1px) {
      a.button.white:hover,
      button.white:hover {
        color: #191922; } }
    a.button.white:before, a.button.white:after,
    button.white:before,
    button.white:after {
      background: #ffffff; }
  @media (min-width: 1px) {
    a.button:hover,
    button:hover {
      color: #ffffff; }
      a.button:hover:after, a.button:hover:before,
      button:hover:after,
      button:hover:before {
        transform: translate3d(0, 0, 0); }
      a.button:hover:before,
      button:hover:before {
        transition: transform 0.43s cubic-bezier(0.65, 0.1, 0.3, 1); }
      a.button:hover:after,
      button:hover:after {
        transition: transform 0s 0.43s linear; } }
  a.button.arrow,
  button.arrow {
    z-index: auto;
    text-align: left;
    padding-right: 46px; }
    a.button.arrow span.arrow,
    button.arrow span.arrow {
      position: absolute;
      display: inline-block;
      right: 15px;
      top: 50%;
      width: 16px;
      height: 8px;
      margin-left: 10px;
      margin-top: -4px;
      background: url("../img/arrow-right-black.svg");
      background-repeat: no-repeat;
      background-size: contain;
      overflow: hidden;
      transition: background-position 0.35s cubic-bezier(0.2, 0.2, 0.3, 1); }
    a.button.arrow.white span.arrow,
    button.arrow.white span.arrow {
      background: url("../img/arrow-right-white.svg");
      background-repeat: no-repeat;
      background-size: contain; }
    a.button.arrow.bottom.span.arrow,
    button.arrow.bottom.span.arrow {
      transform: rotate(90deg); }
    @media (min-width: 1px) {
      a.button.arrow:hover,
      button.arrow:hover {
        min-width: 220px; }
        a.button.arrow:hover span.arrow,
        button.arrow:hover span.arrow {
          background: url("../img/arrow-right-white.svg");
          background-repeat: no-repeat;
          background-size: contain;
          animation-name: button-arrow-hover;
          animation-duration: 0.7s; }
      @keyframes button-arrow-hover {
        0% {
          background-position-x: 0; }
        50% {
          background-position-x: 16px; }
        51% {
          display: none;
          background-position: -16px; }
        52% {
          display: block; }
        100% {
          background-position-x: 0; } }
        a.button.arrow:hover.white span.arrow,
        button.arrow:hover.white span.arrow {
          background: url("../img/arrow-right-black.svg");
          background-repeat: no-repeat;
          background-size: contain; } }
  a.button.inactive,
  button.inactive {
    pointer-events: none;
    background: transparent;
    color: #888888 !important;
    border: 1px solid #888888; }
    a.button.inactive:before, a.button.inactive:after,
    button.inactive:before,
    button.inactive:after {
      display: none; }

form#contact-form {
  position: relative;
  display: block; }
  form#contact-form label {
    transition: color .23s ease-out;
    position: relative;
    font-size: 0.8125em;
    text-transform: uppercase;
    margin-bottom: 6px;
    color: #66666C;
    letter-spacing: 1px;
    font-weight: 300;
    line-height: 26px; }
    form#contact-form label.error {
      color: #D0011B; }
  form#contact-form input, form#contact-form textarea {
    position: relative;
    display: block;
    width: 100%;
    background: #F7F7F7;
    -webkit-appearance: none !important;
    height: 46px;
    border: 1px solid #fff;
    border-radius: 0;
    margin-bottom: 20px;
    -webkit-writing-mode: horizontal-tb !important;
    -webkit-appearance: textfield;
    -webkit-rtl-ordering: logical;
    font-size: 0.875em;
    padding: 0 10px;
    color: #66666C;
    font-weight: 300;
    transition: border .23s ease-out;
    overflow: hidden; }
    form#contact-form input:focus, form#contact-form input:active, form#contact-form textarea:focus, form#contact-form textarea:active {
      outline: none;
      outline-width: 0; }
    form#contact-form input.error, form#contact-form textarea.error {
      border: 1px solid #D0011B; }
  form#contact-form textarea {
    padding-top: 10px;
    margin-bottom: 0;
    -webkit-appearance: none;
    -webkit-rtl-ordering: logical;
    -webkit-user-select: text;
    flex-direction: column;
    resize: none;
    cursor: auto;
    overflow-x: hidden;
    overflow: auto; }
  form#contact-form #contact-response {
    font-size: 0.75em;
    color: #D0011B;
    letter-spacing: 0px;
    font-weight: 300;
    position: relative;
    margin-top: 15px; }
    @media (min-width: 992px) {
      form#contact-form #contact-response {
        position: absolute; } }
  form#contact-form a#contact-submit {
    position: relative;
    display: inline-block;
    margin-top: 25px; }
    @media (min-width: 992px) {
      form#contact-form a#contact-submit {
        margin-top: 25px; } }

.dropdown-wrapper {
  position: relative;
  display: block;
  border-bottom: 2px solid #E5E5E6;
  /* double dropdown icon fix */ }
  .dropdown-wrapper:before, .dropdown-wrapper:after {
    content: '';
    position: absolute;
    display: block;
    z-index: 10;
    background: #191922; }
  .dropdown-wrapper:before {
    width: 1px;
    height: 8px;
    right: 4px;
    top: 11px;
    transform: rotate(45deg); }
  .dropdown-wrapper:after {
    width: 1px;
    height: 8px;
    right: 9px;
    bottom: 11px;
    transform: rotate(-45deg); }
  .dropdown-wrapper select {
    position: relative;
    display: block;
    width: 100%;
    height: 30px;
    -webkit-appearance: none !important;
    border: none;
    border-radius: 0;
    -webkit-writing-mode: horizontal-tb !important;
    -webkit-appearance: textfield;
    -webkit-rtl-ordering: logical;
    background: #ffffff;
    color: #191922;
    font-size: 14px;
    font-weight: 500;
    padding-left: 0 !important;
    text-transform: uppercase;
    /* hide 'select your country' on focus */ }
    .dropdown-wrapper select:focus, .dropdown-wrapper select:active {
      outline: none;
      outline-width: 0;
      border: none; }
    .dropdown-wrapper select:focus option:first-child {
      display: none; }
  .dropdown-wrapper select.dropdown::-ms-expand {
    display: none; }
  .dropdown-wrapper select.dropdown {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    -webkit-writing-mode: horizontal-tb !important;
    -webkit-appearance: textfield;
    -webkit-rtl-ordering: logical;
    overflow: hidden; }
  .dropdown-wrapper option {
    padding: 2px 0;
    color: #191922;
    font-size: 14px; }

a.link-block {
  position: relative;
  display: block;
  text-transform: uppercase;
  font-size: 0.8125em;
  letter-spacing: 1px;
  font-weight: 500;
  color: #f8f8f8;
  transition: color .23s ease-out;
  text-align: left;
  margin-top: 25px;
  background: none;
  border: none;
  /* arrow right */
  /* arrow left */
  /* hover animation */ }
  a.link-block.black {
    color: #191919; }
    a.link-block.black:hover {
      color: #191919; }
  a.link-block.arrow {
    will-change: transform; }
    a.link-block.arrow:after {
      content: '';
      position: relative;
      display: inline-block;
      left: 0;
      top: -1px;
      width: 16px;
      height: 8px;
      margin-left: 10px;
      transition: transform .23s ease-out;
      background: url("../img/arrow-right-white.svg");
      background-repeat: no-repeat;
      background-size: contain;
      will-change: transform; }
    a.link-block.arrow.white:after, a.link-block.arrow.white:before {
      background: url("../img/arrow-right-white.svg");
      background-repeat: no-repeat;
      background-size: contain; }
    a.link-block.arrow.black:after, a.link-block.arrow.black:before {
      background: url("../img/arrow-right-black.svg");
      background-repeat: no-repeat;
      background-size: contain; }
  a.link-block.arrow.arrow-left:after {
    display: none; }
  a.link-block.arrow.arrow-left:before {
    content: '';
    position: relative;
    display: inline-block;
    left: 0;
    top: -1px;
    width: 16px;
    height: 8px;
    margin-right: 10px;
    transition: transform .23s ease-out;
    transform: scale(-1, 1); }
  @media (min-width: 1px) {
    a.link-block:hover {
      color: #dfdfdf; }
      a.link-block:hover.black {
        color: black; }
      a.link-block:hover:after, a.link-block:hover:before {
        animation-name: linkblock-arrow-hover;
        animation-duration: 0.6s;
        transition: transform 0.3s cubic-bezier(0.2, 0.2, 0.3, 1), background-position 0.6s cubic-bezier(0.2, 0.2, 0.3, 1);
        transform: translateX(8px);
        overflow: hidden; }
    @keyframes linkblock-arrow-hover {
      0% {
        background-position-x: 0;
        opacity: 1; }
      50% {
        background-position-x: 16px; }
      51% {
        display: none;
        background-position: -16px; }
      52% {
        display: block; }
      100% {
        background-position-x: 0; } } }

.gallery-interface {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 1500;
  -webkit-text-size-adjust: 100%;
  backface-visibility: hidden;
  outline: none;
  /* reset button */
  /* style is added when JS option showHideOpacity is true */
  /* change cursor when zoom is enabled */
  /* hide description on small screens */
  /* gallery img animation */
  /* animation on drag */
  /* prevent selection and tap highlights */
  /* for open/close transition */
  /*  avoid flickering in webkit/blink when layers overlap */
  /* error message appears when image is not loaded */
  /* single image */ }
  .gallery-interface button {
    min-width: auto !important;
    margin-top: 0; }
  .gallery-interface--animate_opacity {
    opacity: 0.001;
    will-change: opacity;
    transition: opacity 280ms cubic-bezier(0.4, 0, 0.22, 1); }
  .gallery-interface--animated-in .gallery-bg,
  .gallery-interface--animated-in .gallery-zoom-wrap {
    transition: none; }
  .gallery-interface--open {
    display: block; }
  .gallery-interface--zoom-allowed .gallery-img {
    cursor: default; }
  .gallery-interface--zoomed-in .gallery-img {
    cursor: default; }
  .gallery-interface--dragging .gallery-img {
    cursor: default; }
  .gallery-interface .gallery-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    transform: translateZ(0);
    backface-visibility: hidden;
    will-change: opacity;
    transition: opacity 280ms cubic-bezier(0.4, 0, 0.22, 1); }
  .gallery-interface .gallery-ui {
    position: absolute;
    display: block;
    z-index: 1500;
    height: 100%;
    width: 100%; }
    .gallery-interface .gallery-ui .gallery-button--share,
    .gallery-interface .gallery-ui .gallery-button--fs,
    .gallery-interface .gallery-ui .gallery-button--zoom {
      display: none !important; }
  .gallery-interface .gallery-top-bar {
    position: relative;
    display: block;
    height: 50px;
    text-align: left;
    margin-top: 25px;
    z-index: 2000; }
    @media (min-width: 992px) {
      .gallery-interface .gallery-top-bar {
        text-align: center;
        margin-top: 60px; } }
    .gallery-interface .gallery-top-bar .gallery-button--close {
      position: absolute;
      top: 0;
      display: inline-block;
      width: 60px;
      height: 60px;
      right: 0; }
      @media (min-width: 992px) {
        .gallery-interface .gallery-top-bar .gallery-button--close {
          width: auto;
          height: auto;
          top: -3px; } }
      @media (min-width: 992px) {
        .gallery-interface .gallery-top-bar .gallery-button--close .gallery-close {
          margin-right: 30px; } }
      .gallery-interface .gallery-top-bar .gallery-button--close span.icon-close {
        position: absolute;
        top: 1px;
        right: 25px; }
        @media (max-width: 374px) {
          .gallery-interface .gallery-top-bar .gallery-button--close span.icon-close {
            right: 15px; } }
        @media (min-width: 768px) {
          .gallery-interface .gallery-top-bar .gallery-button--close span.icon-close {
            right: 15px; } }
        @media (min-width: 992px) {
          .gallery-interface .gallery-top-bar .gallery-button--close span.icon-close {
            position: relative;
            top: 6px; } }
      @media (min-width: 1px) {
        .gallery-interface .gallery-top-bar .gallery-button--close:hover:before, .gallery-interface .gallery-top-bar .gallery-button--close:hover:after {
          display: none; } }
  .gallery-interface .gallery-title {
    position: relative;
    width: 200px;
    margin-top: 7px; }
    @media (min-width: 768px) {
      .gallery-interface .gallery-title {
        width: 260px;
        left: 50%;
        margin-left: -130px;
        margin-top: 5px; } }
    @media (min-width: 1200px) {
      .gallery-interface .gallery-title {
        margin-top: 8px; } }
    .gallery-interface .gallery-title img {
      position: relative;
      display: block;
      max-width: 100%; }
  .gallery-interface .gallery-button {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    z-index: 2100;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .gallery-interface .gallery-button:before, .gallery-interface .gallery-button:after {
      display: none; }
    .gallery-interface .gallery-button span {
      text-transform: uppercase;
      color: #191919;
      font-weight: 500;
      letter-spacing: 1px;
      z-index: -1;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .gallery-interface .gallery-button span.size {
        text-transform: lowercase; }
  .gallery-interface .gallery-button--arrow--left,
  .gallery-interface .gallery-button--arrow--right {
    position: absolute;
    display: block;
    bottom: 35px;
    z-index: 2100; }
    .gallery-interface .gallery-button--arrow--left span,
    .gallery-interface .gallery-button--arrow--right span {
      pointer-events: none; }
    @media (max-width: 767px) {
      .gallery-interface .gallery-button--arrow--left.bar-visible,
      .gallery-interface .gallery-button--arrow--right.bar-visible {
        bottom: 17.5px; } }
    @media (min-width: 992px) {
      .gallery-interface .gallery-button--arrow--left,
      .gallery-interface .gallery-button--arrow--right {
        top: 50%;
        height: 50px;
        margin-top: -25px; } }
    .gallery-interface .gallery-button--arrow--left:after,
    .gallery-interface .gallery-button--arrow--right:after {
      top: -0.5px !important; }
  .gallery-interface .gallery-button--arrow--left {
    left: 25px; }
    @media (max-width: 374px) {
      .gallery-interface .gallery-button--arrow--left {
        left: 15px; } }
    @media (min-width: 768px) {
      .gallery-interface .gallery-button--arrow--left {
        left: 30px; } }
    @media (min-width: 992px) {
      .gallery-interface .gallery-button--arrow--left {
        left: 60px; } }
  .gallery-interface .gallery-button--arrow--right {
    right: 25px; }
    @media (max-width: 374px) {
      .gallery-interface .gallery-button--arrow--right {
        right: 15px; } }
    @media (min-width: 768px) {
      .gallery-interface .gallery-button--arrow--right {
        right: 30px; } }
    @media (min-width: 992px) {
      .gallery-interface .gallery-button--arrow--right {
        right: 60px; } }
  .gallery-interface .gallery-caption {
    position: absolute;
    display: block;
    margin-top: -10px;
    /* adjust description font size */ }
    @media (max-width: 767px) {
      .gallery-interface .gallery-caption {
        bottom: 85px;
        left: 25px;
        right: 25px; } }
    @media (max-width: 320px) {
      .gallery-interface .gallery-caption {
        display: none; } }
    @media screen and (min-width: 0px) and (max-width: 992px) and (orientation: landscape) {
      .gallery-interface .gallery-caption {
        display: none; } }
    @media (min-width: 768px) {
      .gallery-interface .gallery-caption {
        margin-top: 20px; } }
    @media (min-width: 992px) {
      .gallery-interface .gallery-caption {
        transition: opacity 280ms ease-out, transform 450ms ease-out;
        transform: scale(0.95);
        opacity: 0;
        width: auto !important;
        top: auto !important;
        bottom: 40px !important;
        left: 60px !important; }
        .gallery-interface .gallery-caption.active {
          transform: scale(1);
          opacity: 1; }
        .gallery-interface .gallery-caption.delay {
          transition: opacity 450ms ease-out, transform 450ms ease-out;
          transition-delay: .3s; } }
    .gallery-interface .gallery-caption .slash,
    .gallery-interface .gallery-caption .copyrightYear {
      display: inline-block; }
    .gallery-interface .gallery-caption .slash {
      padding: 0 5px; }
    .gallery-interface .gallery-caption .size {
      text-transform: none !important; }
    .gallery-interface .gallery-caption .copyrightYear-placeholder {
      display: none; }
    .gallery-interface .gallery-caption .description-bottom span {
      display: inline;
      word-break: break-word;
      line-height: 23px; }
  @media (max-width: 767px) {
    .gallery-interface span.description, .gallery-interface .slash.description {
      display: none !important; } }
  .gallery-interface .gallery-counter {
    position: absolute;
    width: 100px;
    left: 50%;
    margin-left: -50px;
    bottom: 35px;
    text-align: center;
    color: #99999D;
    font-weight: 500;
    font-size: 0.8125em;
    letter-spacing: 1px; }
    .gallery-interface .gallery-counter span.dark {
      color: #191919;
      font-weight: 500;
      letter-spacing: 1px; }
    @media (max-width: 767px) {
      .gallery-interface .gallery-counter.bar-visible {
        bottom: 17.5px; } }
    @media (min-width: 992px) {
      .gallery-interface .gallery-counter {
        bottom: 40px; } }
  .gallery-interface .gallery-item {
    position: absolute;
    left: 0;
    right: 0;
    top: -30px;
    bottom: 0; }
    @media (min-width: 768px) {
      .gallery-interface .gallery-item {
        top: 0; } }
  .gallery-interface .gallery-img {
    position: absolute;
    width: auto;
    height: auto;
    top: 0;
    left: 0;
    max-width: none;
    display: block; }
    .gallery-interface .gallery-img--placeholder {
      animation: box-shadow-mobile 800ms backwards;
      animation-iteration-count: 1;
      animation-delay: 0s !important;
      animation: box-shadow-mobile 1200ms forwards;
      transition: box-shadow 1200ms ease !important; }

@keyframes box-shadow-mobile {
  0% {
    box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.9); }
  100% {
    box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0); } }
      @media (min-width: 992px) {
        .gallery-interface .gallery-img--placeholder {
          animation: box-shadow-desktop 1200ms forwards;
          transition: box-shadow 1200ms ease !important; }
        @keyframes box-shadow-desktop {
          0% {
            box-shadow: 30px 30px 60px 0 rgba(0, 0, 0, 0.9); }
          100% {
            box-shadow: 30px 30px 160px 0 rgba(0, 0, 0, 0); } } }
  .gallery-interface .gallery-zoom-wrap .gallery-img {
    transform: scale(0.92);
    opacity: 0.85;
    box-shadow: none;
    transition: box-shadow 800ms ease-out, transform 450ms ease-out, opacity 800ms ease-out; }
  .gallery-interface .gallery-zoom-wrap.gallery-img-active .gallery-img {
    transform: scale(1);
    opacity: 1;
    box-shadow: 10px 10px 30px 0 #ccccce; }
    @media (min-width: 992px) {
      .gallery-interface .gallery-zoom-wrap.gallery-img-active .gallery-img {
        box-shadow: 30px 30px 160px 0 #ccccce; } }
  .gallery-interface--dragging .gallery-img {
    transform: scale(0.92) !important;
    opacity: 0.85 !important;
    transition: all 280ms ease-out !important;
    box-shadow: 10px 10px 10px 0 #e5e5e6 !important; }
    @media (min-width: 992px) {
      .gallery-interface--dragging .gallery-img {
        box-shadow: 30px 30px 60px 0 #e5e5e6 !important; } }
  .gallery-interface--dragging .gallery-caption {
    transform: scale(0.92) !important;
    opacity: 0 !important;
    transition-delay: 0s !important; }
  .gallery-interface .gallery-scroll-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
  .gallery-interface .gallery-container,
  .gallery-interface .gallery-zoom-wrap {
    -ms-touch-action: none;
    touch-action: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    backface-visibility: hidden; }
  .gallery-interface .gallery-container,
  .gallery-interface .gallery-img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .gallery-interface .gallery-zoom-wrap {
    position: absolute;
    width: 100%;
    transform-origin: left top;
    transition: transform 450ms cubic-bezier(0.4, 0, 0.5, 1); }
  .gallery-interface .gallery-img--placeholder {
    backface-visibility: hidden; }
  .gallery-interface .gallery-img--placeholder--blank {
    background: #222; }
  .gallery-interface .gallery-interface--ie .gallery-img {
    width: 100% !important;
    height: auto !important;
    left: 0;
    top: 0; }
  .gallery-interface .gallery-error-msg {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    font-size: 0.875em; }
  .gallery-interface.single-image .gallery-counter,
  .gallery-interface.single-image .gallery-button--arrow--left,
  .gallery-interface.single-image .gallery-button--arrow--right {
    display: none; }
  .gallery-interface.single-image.gallery-interface--dragging .gallery-img,
  .gallery-interface.single-image.gallery-interface--dragging .gallery-caption {
    transform: scale(1) !important;
    opacity: 1 !important; }
  .gallery-interface.single-image.gallery-interface--dragging .gallery-img {
    transition: box-shadow 280ms ease-out !important; }
    @media (min-width: 992px) {
      .gallery-interface.single-image.gallery-interface--dragging .gallery-img {
        box-shadow: 30px 30px 160px 0 #ccccce !important; } }

/* no css transform */
body.no-csstransforms {
  /* fadeIn images on scroll */
  /* fadeIn text on scroll */
  /* contact form textarea */
  /* open accordions */ }
  body.no-csstransforms .animate-image {
    transition: none !important;
    opacity: 1 !important;
    transform: none !important;
    width: 100% !important;
    visibility: visible !important; }
    body.no-csstransforms .animate-image img {
      opacity: 1;
      visibility: visible; }
    body.no-csstransforms .animate-image:before, body.no-csstransforms .animate-image:after {
      display: none; }
  body.no-csstransforms .animate-text {
    transition: none !important;
    opacity: 1 !important;
    transform: none !important;
    visibility: visible !important; }
    body.no-csstransforms .animate-text:before, body.no-csstransforms .animate-text:after {
      display: none; }
  body.no-csstransforms textarea#contact-message {
    height: 200px !important;
    overflow: scroll; }
  body.no-csstransforms .accordion .accordion-bar {
    background: #191922;
    cursor: default;
    pointer-events: none; }
    body.no-csstransforms .accordion .accordion-bar h3 {
      color: #f8f8f8; }
  body.no-csstransforms .accordion .accordion-content {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
    background: #F7F7F7; }
  body.no-csstransforms .accordion .accordion-icon {
    display: none !important; }

/* icon close */
.icon-close {
  position: relative;
  display: inline-block;
  height: 24px;
  width: 24px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .icon-close:before, .icon-close:after {
    content: '';
    position: absolute;
    width: 24px;
    height: 1px;
    background: #191919;
    top: 12px;
    transition: background .23s ease-out; }
  .icon-close:before {
    transform: rotate(-45deg); }
  .icon-close:after {
    transform: rotate(45deg); }
  @media (min-width: 1px) {
    .icon-close:hover:before, .icon-close:hover:after {
      background: #000000; } }

/*---------------------------------------------
  modals
---------------------------------------------*/
.modal {
  position: fixed;
  display: block;
  bottom: 25px;
  right: 25px;
  left: 25px;
  z-index: 60;
  background: #191922;
  max-width: 390px;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  transition: transform 0.5s ease-out, opacity 0.35s ease-out, visibility 0.35s 0.45s ease-out; }
  @media all and (min-width: 480px) {
    .modal {
      left: auto; } }
  .modal .inner {
    padding: 25px 25px; }
  .modal p, .modal a.learn-more {
    color: #ffffff;
    display: inline;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: .1px; }
  .modal a.learn-more {
    text-transform: none;
    text-decoration: underline;
    font-weight: 500; }
  .modal .button-container {
    margin-top: 25px; }
  .modal .button.primary {
    background: #ffffff;
    color: #191922;
    min-width: 130px;
    margin-top: 0; }
    .modal .button.primary:hover {
      background: #ffffff; }
      .modal .button.primary:hover:before, .modal .button.primary:hover:after {
        display: none; }
  .modal .button.deny {
    background: #191922;
    color: #ffffff;
    text-decoration: none; }
    .modal .button.deny:hover {
      background: #191922 !important; }
  .modal.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: transform 0.5s 0.45s ease-out, opacity 0.35s 0.45s ease-out, visibility 0.35s 0.45s ease-out; }
    @media all and (min-width: 768px) {
      .modal.open {
        transition: transform 0.5s 0.45s ease-out, opacity 0.35s 0.45s ease-out, visibility 0.35s 0.45s ease-out; } }

nav {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  max-height: 60px;
  background: #ffffff;
  z-index: 100;
  /* list items */ }
  @media (min-width: 992px) {
    nav {
      height: 86px;
      max-height: 86px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15); } }
  nav .container, nav .container-post p, .container-post nav p, nav .container-post h2, .container-post nav h2, nav .container-post h3, .container-post nav h3, nav .container-post h5, .container-post nav h5 {
    height: 100%; }
    @media all and (min-width: 992px) and (max-width: 1199px) {
      nav .container, nav .container-post p, .container-post nav p, nav .container-post h2, .container-post nav h2, nav .container-post h3, .container-post nav h3, nav .container-post h5, .container-post nav h5 {
        width: 100%;
        padding-left: 25px;
        padding-right: 25px; }
        nav .container #nav-menu li, nav .container-post p #nav-menu li, .container-post nav p #nav-menu li, nav .container-post h2 #nav-menu li, .container-post nav h2 #nav-menu li, nav .container-post h3 #nav-menu li, .container-post nav h3 #nav-menu li, nav .container-post h5 #nav-menu li, .container-post nav h5 #nav-menu li {
          margin-left: 20px !important; }
        nav .container #nav-languages-desktop, nav .container-post p #nav-languages-desktop, .container-post nav p #nav-languages-desktop, nav .container-post h2 #nav-languages-desktop, .container-post nav h2 #nav-languages-desktop, nav .container-post h3 #nav-languages-desktop, .container-post nav h3 #nav-languages-desktop, nav .container-post h5 #nav-languages-desktop, .container-post nav h5 #nav-languages-desktop {
          margin-left: 25px !important; } }
  nav #nav-wrapper {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 60px;
    background: #ffffff;
    z-index: 110;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15); }
    @media (min-width: 992px) {
      nav #nav-wrapper {
        height: 86px;
        width: 257px;
        position: relative;
        display: inline;
        box-shadow: none;
        background: transparent; } }
    @media (min-width: 1200px) {
      nav #nav-wrapper {
        width: 257px;
        height: 16px; } }
    nav #nav-wrapper #nav-logo {
      position: relative;
      display: inline-block;
      left: 0;
      height: 16px;
      width: 257px;
      margin-left: 25px;
      background: transparent;
      top: 23px;
      cursor: pointer; }
      @media (max-width: 320px) {
        nav #nav-wrapper #nav-logo {
          height: 16px;
          width: 257px;
          top: 23px; } }
      @media (max-width: 374px) {
        nav #nav-wrapper #nav-logo {
          margin-left: 15px; } }
      @media (min-width: 992px) {
        nav #nav-wrapper #nav-logo {
          margin-left: 0;
          width: 257px;
          height: 16px;
          top: 36px; } }
      @media (min-width: 1200px) {
        nav #nav-wrapper #nav-logo {
          width: 257px;
          height: 16px;
          top: 36px; } }
      nav #nav-wrapper #nav-logo a {
        position: relative;
        display: block;
        height: 16px;
        width: 257px; }
        @media (max-width: 320px) {
          nav #nav-wrapper #nav-logo a {
            height: 16px;
            width: 257px; } }
        @media (min-width: 992px) {
          nav #nav-wrapper #nav-logo a {
            width: 257px;
            height: 16px; } }
        @media (min-width: 1200px) {
          nav #nav-wrapper #nav-logo a {
            width: 257px;
            height: 16px; } }
        nav #nav-wrapper #nav-logo a img {
          position: relative;
          display: block;
          width: 100%;
          height: auto; }
    nav #nav-wrapper #nav-icon {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 25px;
      height: 100%;
      width: 24px;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
      @media (max-width: 374px) {
        nav #nav-wrapper #nav-icon {
          margin-right: 15px; } }
      @media (min-width: 992px) {
        nav #nav-wrapper #nav-icon {
          display: none; } }
      nav #nav-wrapper #nav-icon .line {
        position: relative;
        display: block;
        width: 24px;
        height: 1px;
        top: 50%;
        margin-top: -0.5px;
        background: #000;
        transition-property: transform;
        transition-duration: 150ms;
        transition-delay: 150ms;
        transition-timing-function: ease-in; }
        nav #nav-wrapper #nav-icon .line:before, nav #nav-wrapper #nav-icon .line:after {
          content: '';
          position: absolute;
          width: 24px;
          height: 1px;
          background: #000;
          transition-property: transform; }
        nav #nav-wrapper #nav-icon .line:before {
          top: -5px;
          transition: top 300ms 300ms cubic-bezier(0.215, 0.61, 0.355, 0.6), transform 300ms ease-in; }
        nav #nav-wrapper #nav-icon .line:after {
          bottom: -5px;
          transition: bottom 300ms 300ms cubic-bezier(0.215, 0.61, 0.355, 0.6), transform 300ms ease-in; }
      nav #nav-wrapper #nav-icon.open .line {
        transform: translate3d(0, 0, 0) rotate(-45deg);
        transition-delay: 300ms;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 0.6); }
        nav #nav-wrapper #nav-icon.open .line:before {
          top: 0;
          transform: rotate(-90deg);
          transition: top 300ms ease-in, transform 150ms 300ms cubic-bezier(0.215, 0.61, 0.355, 0.6); }
        nav #nav-wrapper #nav-icon.open .line:after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 300ms ease-in, transform 150ms 300ms cubic-bezier(0.215, 0.61, 0.355, 0.6); }
  nav #nav-menu {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding: 50px 25px 0 50px;
    background: rgba(255, 255, 255, 0.85);
    background-position: -99999px -99999px;
    list-style: none;
    z-index: 105;
    /* background image */
    /* container fix */ }
    nav #nav-menu:before {
      content: ' ';
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      visibility: hidden;
      width: 100%;
      height: 100%;
      height: 100vh;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
      @media (max-width: 767px) {
        nav #nav-menu:before {
          background-image: url("/build/assets/img/menu_mobile.jpg"); } }
  @media (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 767px) and (min-device-pixel-ratio: 2), (max-width: 767px) and (min-resolution: 192dpi) {
    nav #nav-menu:before {
      background-image: url("/build/assets/img/menu_mobile@2x.jpg"); } }
      @media (min-width: 768px) {
        nav #nav-menu:before {
          background-image: url("/build/assets/img/menu_tablet.jpg"); } }
  @media (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 768px) and (min-device-pixel-ratio: 2), (min-width: 768px) and (min-resolution: 192dpi) {
    nav #nav-menu:before {
      background-image: url("/build/assets/img/menu_tablet@2x.jpg"); } }
    @media (max-width: 374px) {
      nav #nav-menu {
        padding: 50px 15px 0 30px; } }
    @media (min-width: 992px) {
      nav #nav-menu {
        position: relative;
        width: auto;
        display: inline;
        float: right;
        height: 86px !important;
        padding: 0;
        margin: 0;
        background: transparent;
        top: 0; }
        nav #nav-menu:before {
          display: none; } }
    nav #nav-menu .container, nav #nav-menu .container-post p, .container-post nav #nav-menu p, nav #nav-menu .container-post h2, .container-post nav #nav-menu h2, nav #nav-menu .container-post h3, .container-post nav #nav-menu h3, nav #nav-menu .container-post h5, .container-post nav #nav-menu h5 {
      height: 100%;
      padding-left: 0;
      padding-right: 0; }
      @media (min-width: 992px) {
        nav #nav-menu .container, nav #nav-menu .container-post p, .container-post nav #nav-menu p, nav #nav-menu .container-post h2, .container-post nav #nav-menu h2, nav #nav-menu .container-post h3, .container-post nav #nav-menu h3, nav #nav-menu .container-post h5, .container-post nav #nav-menu h5 {
          padding-left: 15px;
          padding-right: 15px; } }
    nav #nav-menu li {
      margin-top: 25px;
      height: auto;
      /* active classes */ }
      @media (min-width: 992px) {
        nav #nav-menu li {
          display: inline-block;
          height: 53px;
          margin-left: 50px;
          margin-top: 33px; }
          nav #nav-menu li:first-child {
            margin-left: 0; } }
      @media (min-width: 992px) {
        nav #nav-menu li {
          margin-left: 30px; } }
      @media (min-width: 1200px) {
        nav #nav-menu li {
          margin-left: 30px; } }
      @media (min-width: 1440px) {
        nav #nav-menu li {
          margin-left: 30px; } }
      nav #nav-menu li a {
        text-transform: uppercase;
        font-size: 1.25em;
        font-weight: 500;
        color: #99999D;
        letter-spacing: 2px; }
        @media (min-width: 992px) {
          nav #nav-menu li a {
            font-size: 0.8125em;
            letter-spacing: 1px; }
            nav #nav-menu li a:hover {
              color: #000000; } }
      nav #nav-menu li .wrapper-sub-menu {
        display: none; }
        @media (min-width: 992px) {
          nav #nav-menu li .wrapper-sub-menu {
            display: block;
            position: fixed;
            top: 86px;
            left: 0;
            width: 100%;
            height: 50px;
            background: rgba(0, 0, 10, 0.8); } }
        nav #nav-menu li .wrapper-sub-menu ul.sub-menu {
          list-style: none; }
          nav #nav-menu li .wrapper-sub-menu ul.sub-menu li {
            padding-left: 30px; }
            nav #nav-menu li .wrapper-sub-menu ul.sub-menu li:before {
              content: '';
              position: absolute;
              left: 0;
              margin-top: 11px;
              width: 18px;
              height: 1px;
              background: #99999D; }
            @media (min-width: 992px) {
              nav #nav-menu li .wrapper-sub-menu ul.sub-menu li {
                margin-top: 15px;
                height: 35px;
                padding-left: 0; }
                nav #nav-menu li .wrapper-sub-menu ul.sub-menu li:before {
                  display: none; } }
            nav #nav-menu li .wrapper-sub-menu ul.sub-menu li a {
              color: #AAAAAA; }
              nav #nav-menu li .wrapper-sub-menu ul.sub-menu li a:hover {
                color: #ffffff; }
      nav #nav-menu li.current-menu-item {
        /* sub menu only on desktop */ }
        nav #nav-menu li.current-menu-item a {
          color: #000000; }
        @media (min-width: 992px) {
          nav #nav-menu li.current-menu-item ul.sub-menu li a.active {
            color: #f8f8f8; } }
  nav #nav-languages-mobile {
    position: relative;
    display: block;
    background: transparent;
    text-align: left;
    z-index: 110;
    visibility: hidden;
    opacity: 0;
    margin-top: 40px !important; }
    nav #nav-languages-mobile ul {
      list-style: none; }
    nav #nav-languages-mobile li {
      margin-top: 0; }
    @media (min-width: 992px) {
      nav #nav-languages-mobile {
        display: none !important; } }
  nav #nav-languages-desktop {
    position: relative;
    float: right;
    right: 0;
    height: 86px;
    line-height: 91px;
    margin-left: 50px; }
    nav #nav-languages-desktop a:hover {
      color: #191922 !important; }
    @media (max-width: 991px) {
      nav #nav-languages-desktop {
        display: none !important; } }
    @media (min-width: 992px) {
      nav #nav-languages-desktop {
        display: block; } }
  nav #nav-social {
    position: relative;
    display: block;
    padding-bottom: 35px;
    margin-bottom: 15px;
    margin-top: 25px !important;
    visibility: hidden;
    opacity: 0; }
    @media (min-width: 992px) {
      nav #nav-social {
        display: none !important; } }
    nav #nav-social ul {
      list-style: none; }
    nav #nav-social li {
      margin-right: 25px;
      margin-top: 0 !important;
      display: inline-block; }
      nav #nav-social li a img {
        position: relative;
        display: block;
        width: 30px;
        height: 30px;
        opacity: .4;
        transition: opacity .23s ease-out; }
        nav #nav-social li a img:hover {
          opacity: 1; }
  nav #nav-languages-mobile li,
  nav #nav-languages-desktop li {
    margin-right: 10px;
    display: inline-block;
    /* seperator */
    /* reset the last child */ }
    nav #nav-languages-mobile li a,
    nav #nav-languages-desktop li a {
      text-transform: uppercase;
      font-size: 0.9375em;
      font-weight: 500;
      color: #99999D !important;
      letter-spacing: 2px; }
      nav #nav-languages-mobile li a.active,
      nav #nav-languages-desktop li a.active {
        color: #000000 !important;
        pointer-events: none; }
      @media (min-width: 992px) {
        nav #nav-languages-mobile li a,
        nav #nav-languages-desktop li a {
          font-size: 0.8125em;
          letter-spacing: 1px; }
          nav #nav-languages-mobile li a:hover,
          nav #nav-languages-desktop li a:hover {
            color: #000000; } }
    nav #nav-languages-mobile li:after,
    nav #nav-languages-desktop li:after {
      content: '|';
      position: relative;
      right: -5px;
      color: #888888; }
    nav #nav-languages-mobile li.last,
    nav #nav-languages-desktop li.last {
      margin-right: 0; }
      nav #nav-languages-mobile li.last:after,
      nav #nav-languages-desktop li.last:after {
        display: none; }

@media (max-width: 991px) {
  nav #nav-wrapper {
    transition: box-shadow 200ms ease-out;
    transition-delay: 550ms; }
  nav #nav-menu {
    overflow-y: scroll;
    transition: background 200ms ease-out;
    transition-delay: 550ms;
    /* social */ }
    nav #nav-menu:before {
      transition: all 200ms ease-out;
      transform: translateY(-75px); }
    nav #nav-menu li {
      transition: opacity 200ms ease-out, transform 200ms ease-out;
      transform: translateX(-25px) scale(1);
      opacity: 0;
      /* closing animation */
      /* reset for sub menu */ }
      nav #nav-menu li:nth-child(1) {
        transition-delay: 500ms, 500ms; }
      nav #nav-menu li:nth-child(2) {
        transition-delay: 450ms, 450ms; }
      nav #nav-menu li:nth-child(3) {
        transition-delay: 400ms, 400ms; }
      nav #nav-menu li:nth-child(4) {
        transition-delay: 350ms, 350ms; }
      nav #nav-menu li:nth-child(5) {
        transition-delay: 300ms, 300ms; }
      nav #nav-menu li:nth-child(6) {
        transition-delay: 250ms, 250ms; }
      nav #nav-menu li:nth-child(7) {
        transition-delay: 200ms, 200ms; }
      nav #nav-menu li:nth-child(8) {
        transition-delay: 150ms, 150ms; }
      nav #nav-menu li:nth-child(9) {
        transition-delay: 100ms, 100ms; }
      nav #nav-menu li ul.sub-menu li {
        transition: none;
        transform: none;
        opacity: 1;
        visibility: visible;
        transition-delay: 0; }
    nav #nav-menu li.social {
      transform: translateX(0) scale(1);
      opacity: 1; }
  nav #nav-social ul {
    transition: opacity 200ms ease-out, transform 200ms ease-out;
    transform: translateX(-25px) scale(1);
    opacity: 0; }
  nav #nav-languages-mobile ul {
    transition: all 200ms ease-out;
    transition-delay: 0;
    transform: translateX(-25px) scale(1);
    visibility: hidden;
    opacity: 0; }
  nav.open #nav-wrapper {
    transition: box-shadow 200ms ease-out;
    box-shadow: none;
    background: transparent; }
  nav.open #nav-menu {
    background-color: #ffffff;
    transition-delay: 0; }
    nav.open #nav-menu:before {
      opacity: 1 !important;
      visibility: visible;
      transition: all 600ms ease;
      transform: translateY(0);
      transition-delay: 250ms; }
    nav.open #nav-menu li {
      transform: translateX(0) scale(1);
      opacity: 1;
      /* opening animation */ }
      nav.open #nav-menu li:nth-child(1) {
        transition-delay: 150ms, 150ms; }
      nav.open #nav-menu li:nth-child(2) {
        transition-delay: 200ms, 200ms; }
      nav.open #nav-menu li:nth-child(3) {
        transition-delay: 250ms, 250ms; }
      nav.open #nav-menu li:nth-child(4) {
        transition-delay: 300ms, 300ms; }
      nav.open #nav-menu li:nth-child(5) {
        transition-delay: 350ms, 350ms; }
      nav.open #nav-menu li:nth-child(6) {
        transition-delay: 400ms, 400ms; }
      nav.open #nav-menu li:nth-child(7) {
        transition-delay: 450ms, 450ms; }
      nav.open #nav-menu li:nth-child(8) {
        transition-delay: 500ms, 500ms; }
      nav.open #nav-menu li:nth-child(9) {
        transition-delay: 550ms, 550ms; }
    nav.open #nav-menu #nav-social ul {
      transform: translateX(0) scale(1);
      opacity: 1;
      visibility: visible;
      transition-delay: 600ms; }
  nav.open #nav-languages-mobile ul {
    visibility: visible;
    opacity: 1;
    transform: translateX(0) scale(1);
    transition-delay: 550ms; } }

@media (min-width: 992px) {
  nav #nav-menu .wrapper-sub-menu {
    z-index: 1;
    visibility: hidden;
    opacity: 0; }
    nav #nav-menu .wrapper-sub-menu.open {
      opacity: 1;
      visibility: visible; } }

body[class*="post"] nav #nav-menu li:nth-child(5) a {
  color: #191919; }

body[class*="workshops"] nav #nav-menu li:nth-child(4) a {
  color: #191919; }

.container, .container-post p, .container-post h2, .container-post h3, .container-post h5,
.container-mb {
  margin-bottom: 60px; }
  @media (min-width: 768px) {
    .container, .container-post p, .container-post h2, .container-post h3, .container-post h5,
    .container-mb {
      margin-bottom: 80px; } }
  @media (min-width: 992px) {
    .container, .container-post p, .container-post h2, .container-post h3, .container-post h5,
    .container-mb {
      margin-bottom: 80px; } }

.container-mb-large {
  margin-bottom: 80px; }
  @media (min-width: 768px) {
    .container-mb-large {
      margin-bottom: 80px; } }
  @media (min-width: 992px) {
    .container-mb-large {
      margin-bottom: 120px; } }

.container-mt {
  margin-top: 60px; }
  @media (min-width: 768px) {
    .container-mt {
      margin-top: 80px; } }
  @media (min-width: 992px) {
    .container-mt {
      margin-top: 80px; } }

.container-no-mt {
  margin-top: -60px; }
  @media (min-width: 768px) {
    .container-no-mt {
      margin-top: -80px; } }
  @media (min-width: 992px) {
    .container-no-mt {
      margin-top: -80px; } }

.container-no-mb {
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .container-no-mb {
      margin-bottom: 0; } }
  @media (min-width: 992px) {
    .container-no-mb {
      margin-bottom: 0; } }

/* fix the gutter if a container is nested in a container-fluid */
.container-fluid-gutter-fix .container, .container-fluid-gutter-fix .container-post p, .container-post .container-fluid-gutter-fix p, .container-fluid-gutter-fix .container-post h2, .container-post .container-fluid-gutter-fix h2, .container-fluid-gutter-fix .container-post h3, .container-post .container-fluid-gutter-fix h3, .container-fluid-gutter-fix .container-post h5, .container-post .container-fluid-gutter-fix h5 {
  padding-left: 0;
  padding-right: 0; }
  @media (min-width: 768px) {
    .container-fluid-gutter-fix .container, .container-fluid-gutter-fix .container-post p, .container-post .container-fluid-gutter-fix p, .container-fluid-gutter-fix .container-post h2, .container-post .container-fluid-gutter-fix h2, .container-fluid-gutter-fix .container-post h3, .container-post .container-fluid-gutter-fix h3, .container-fluid-gutter-fix .container-post h5, .container-post .container-fluid-gutter-fix h5 {
      padding-left: 15px;
      padding-right: 15px; } }

.container-video-landing .video-loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: transparent;
  z-index: 10;
  visibility: visible;
  opacity: 1;
  overflow: hidden;
  /* artist names */
  /* fadeOut animation */
  /* finished the animation */ }
  @media (max-width: 991px) {
    .container-video-landing .video-loading {
      display: none !important; } }
  .container-video-landing .video-loading .loading-top,
  .container-video-landing .video-loading .loading-bot {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    visibility: visible;
    opacity: 1;
    height: 50%;
    transition: transform 1.2s ease-out;
    background: #000; }
  .container-video-landing .video-loading .loading-top {
    top: 0; }
  .container-video-landing .video-loading .loading-bot {
    top: 50%; }
  .container-video-landing .video-loading span.artist-name {
    position: absolute;
    display: block;
    width: 100%;
    text-align: center;
    top: 50%;
    color: #fff;
    display: none;
    opacity: 0;
    margin-top: -12px; }
  .container-video-landing .video-loading.close .span-wrapper {
    opacity: 0;
    transition: opacity .6s ease-out; }
  .container-video-landing .video-loading.close .loading-top {
    transform: translateY(-100%); }
  .container-video-landing .video-loading.close .loading-bot {
    transform: translateY(100%); }
  .container-video-landing .video-loading.finished {
    transition-delay: 1.2s;
    visibility: hidden;
    opacity: 0;
    pointer-events: none; }

.container-image .full-width {
  position: relative;
  margin-bottom: 60px; }
  @media (min-width: 768px) {
    .container-image .full-width {
      margin-bottom: 80px; } }
  @media (min-width: 992px) {
    .container-image .full-width {
      margin-bottom: 80px; } }
  .container-image .full-width .text-wrapper {
    position: relative; }
    @media (min-width: 992px) {
      .container-image .full-width .text-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; } }
    .container-image .full-width .text-wrapper .container, .container-image .full-width .text-wrapper .container-post p, .container-post .container-image .full-width .text-wrapper p, .container-image .full-width .text-wrapper .container-post h2, .container-post .container-image .full-width .text-wrapper h2, .container-image .full-width .text-wrapper .container-post h3, .container-post .container-image .full-width .text-wrapper h3, .container-image .full-width .text-wrapper .container-post h5, .container-post .container-image .full-width .text-wrapper h5 {
      height: 100%;
      position: relative; }
      .container-image .full-width .text-wrapper .container .row, .container-image .full-width .text-wrapper .container-post p .row, .container-post .container-image .full-width .text-wrapper p .row, .container-image .full-width .text-wrapper .container-post h2 .row, .container-post .container-image .full-width .text-wrapper h2 .row, .container-image .full-width .text-wrapper .container-post h3 .row, .container-post .container-image .full-width .text-wrapper h3 .row, .container-image .full-width .text-wrapper .container-post h5 .row, .container-post .container-image .full-width .text-wrapper h5 .row {
        height: 100%;
        position: relative; }
        .container-image .full-width .text-wrapper .container .row .text-center, .container-image .full-width .text-wrapper .container-post p .row .text-center, .container-post .container-image .full-width .text-wrapper p .row .text-center, .container-image .full-width .text-wrapper .container-post h2 .row .text-center, .container-post .container-image .full-width .text-wrapper h2 .row .text-center, .container-image .full-width .text-wrapper .container-post h3 .row .text-center, .container-post .container-image .full-width .text-wrapper h3 .row .text-center, .container-image .full-width .text-wrapper .container-post h5 .row .text-center, .container-post .container-image .full-width .text-wrapper h5 .row .text-center {
          text-align: left;
          color: #191919;
          margin-top: 30px; }
          @media (min-width: 992px) {
            .container-image .full-width .text-wrapper .container .row .text-center, .container-image .full-width .text-wrapper .container-post p .row .text-center, .container-post .container-image .full-width .text-wrapper p .row .text-center, .container-image .full-width .text-wrapper .container-post h2 .row .text-center, .container-post .container-image .full-width .text-wrapper h2 .row .text-center, .container-image .full-width .text-wrapper .container-post h3 .row .text-center, .container-post .container-image .full-width .text-wrapper h3 .row .text-center, .container-image .full-width .text-wrapper .container-post h5 .row .text-center, .container-post .container-image .full-width .text-wrapper h5 .row .text-center {
              margin-top: 0;
              position: absolute;
              text-align: center;
              color: #ffffff;
              top: 50%;
              transform: translateY(-50%); } }

.container-image-landing, .container-video-landing {
  margin-bottom: 60px;
  height: 100%; }
  @media (min-width: 768px) {
    .container-image-landing, .container-video-landing {
      margin-bottom: 80px; } }
  @media (min-width: 992px) {
    .container-image-landing, .container-video-landing {
      margin-bottom: 80px; } }
  .container-image-landing .row, .container-video-landing .row {
    position: relative; }
    .container-image-landing .row .image-wrapper, .container-video-landing .row .image-wrapper {
      height: 100%;
      height: calc(100vh - 60px);
      overflow: hidden; }
      @media (min-width: 992px) {
        .container-image-landing .row .image-wrapper, .container-video-landing .row .image-wrapper {
          height: 100%;
          height: calc(100vh - 86px); } }
      .container-image-landing .row .image-wrapper img, .container-video-landing .row .image-wrapper img {
        position: absolute;
        display: block;
        max-width: none;
        width: auto;
        top: -100%;
        bottom: -100%;
        left: -100%;
        right: -100%;
        margin: auto auto;
        min-height: 100%;
        min-width: 100%; }
    .container-image-landing .row .text-wrapper, .container-video-landing .row .text-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      .container-image-landing .row .text-wrapper .container, .container-image-landing .row .text-wrapper .container-post p, .container-post .container-image-landing .row .text-wrapper p, .container-image-landing .row .text-wrapper .container-post h2, .container-post .container-image-landing .row .text-wrapper h2, .container-image-landing .row .text-wrapper .container-post h3, .container-post .container-image-landing .row .text-wrapper h3, .container-image-landing .row .text-wrapper .container-post h5, .container-post .container-image-landing .row .text-wrapper h5, .container-video-landing .row .text-wrapper .container, .container-video-landing .row .text-wrapper .container-post p, .container-post .container-video-landing .row .text-wrapper p, .container-video-landing .row .text-wrapper .container-post h2, .container-post .container-video-landing .row .text-wrapper h2, .container-video-landing .row .text-wrapper .container-post h3, .container-post .container-video-landing .row .text-wrapper h3, .container-video-landing .row .text-wrapper .container-post h5, .container-post .container-video-landing .row .text-wrapper h5 {
        height: 100%;
        position: relative; }
        .container-image-landing .row .text-wrapper .container .row, .container-image-landing .row .text-wrapper .container-post p .row, .container-post .container-image-landing .row .text-wrapper p .row, .container-image-landing .row .text-wrapper .container-post h2 .row, .container-post .container-image-landing .row .text-wrapper h2 .row, .container-image-landing .row .text-wrapper .container-post h3 .row, .container-post .container-image-landing .row .text-wrapper h3 .row, .container-image-landing .row .text-wrapper .container-post h5 .row, .container-post .container-image-landing .row .text-wrapper h5 .row, .container-video-landing .row .text-wrapper .container .row, .container-video-landing .row .text-wrapper .container-post p .row, .container-post .container-video-landing .row .text-wrapper p .row, .container-video-landing .row .text-wrapper .container-post h2 .row, .container-post .container-video-landing .row .text-wrapper h2 .row, .container-video-landing .row .text-wrapper .container-post h3 .row, .container-post .container-video-landing .row .text-wrapper h3 .row, .container-video-landing .row .text-wrapper .container-post h5 .row, .container-post .container-video-landing .row .text-wrapper h5 .row {
          height: 100%;
          position: relative; }
          .container-image-landing .row .text-wrapper .container .row .text-center, .container-image-landing .row .text-wrapper .container-post p .row .text-center, .container-post .container-image-landing .row .text-wrapper p .row .text-center, .container-image-landing .row .text-wrapper .container-post h2 .row .text-center, .container-post .container-image-landing .row .text-wrapper h2 .row .text-center, .container-image-landing .row .text-wrapper .container-post h3 .row .text-center, .container-post .container-image-landing .row .text-wrapper h3 .row .text-center, .container-image-landing .row .text-wrapper .container-post h5 .row .text-center, .container-post .container-image-landing .row .text-wrapper h5 .row .text-center, .container-video-landing .row .text-wrapper .container .row .text-center, .container-video-landing .row .text-wrapper .container-post p .row .text-center, .container-post .container-video-landing .row .text-wrapper p .row .text-center, .container-video-landing .row .text-wrapper .container-post h2 .row .text-center, .container-post .container-video-landing .row .text-wrapper h2 .row .text-center, .container-video-landing .row .text-wrapper .container-post h3 .row .text-center, .container-post .container-video-landing .row .text-wrapper h3 .row .text-center, .container-video-landing .row .text-wrapper .container-post h5 .row .text-center, .container-post .container-video-landing .row .text-wrapper h5 .row .text-center {
            margin-top: 0;
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translateY(-50%); }
            .container-image-landing .row .text-wrapper .container .row .text-center h1, .container-image-landing .row .text-wrapper .container-post p .row .text-center h1, .container-post .container-image-landing .row .text-wrapper p .row .text-center h1, .container-image-landing .row .text-wrapper .container-post h2 .row .text-center h1, .container-post .container-image-landing .row .text-wrapper h2 .row .text-center h1, .container-image-landing .row .text-wrapper .container-post h3 .row .text-center h1, .container-post .container-image-landing .row .text-wrapper h3 .row .text-center h1, .container-image-landing .row .text-wrapper .container-post h5 .row .text-center h1, .container-post .container-image-landing .row .text-wrapper h5 .row .text-center h1, .container-video-landing .row .text-wrapper .container .row .text-center h1, .container-video-landing .row .text-wrapper .container-post p .row .text-center h1, .container-post .container-video-landing .row .text-wrapper p .row .text-center h1, .container-video-landing .row .text-wrapper .container-post h2 .row .text-center h1, .container-post .container-video-landing .row .text-wrapper h2 .row .text-center h1, .container-video-landing .row .text-wrapper .container-post h3 .row .text-center h1, .container-post .container-video-landing .row .text-wrapper h3 .row .text-center h1, .container-video-landing .row .text-wrapper .container-post h5 .row .text-center h1, .container-post .container-video-landing .row .text-wrapper h5 .row .text-center h1 {
              color: #ffffff;
              margin-bottom: 15px; }
              @media (min-width: 992px) {
                .container-image-landing .row .text-wrapper .container .row .text-center h1, .container-image-landing .row .text-wrapper .container-post p .row .text-center h1, .container-post .container-image-landing .row .text-wrapper p .row .text-center h1, .container-image-landing .row .text-wrapper .container-post h2 .row .text-center h1, .container-post .container-image-landing .row .text-wrapper h2 .row .text-center h1, .container-image-landing .row .text-wrapper .container-post h3 .row .text-center h1, .container-post .container-image-landing .row .text-wrapper h3 .row .text-center h1, .container-image-landing .row .text-wrapper .container-post h5 .row .text-center h1, .container-post .container-image-landing .row .text-wrapper h5 .row .text-center h1, .container-video-landing .row .text-wrapper .container .row .text-center h1, .container-video-landing .row .text-wrapper .container-post p .row .text-center h1, .container-post .container-video-landing .row .text-wrapper p .row .text-center h1, .container-video-landing .row .text-wrapper .container-post h2 .row .text-center h1, .container-post .container-video-landing .row .text-wrapper h2 .row .text-center h1, .container-video-landing .row .text-wrapper .container-post h3 .row .text-center h1, .container-post .container-video-landing .row .text-wrapper h3 .row .text-center h1, .container-video-landing .row .text-wrapper .container-post h5 .row .text-center h1, .container-post .container-video-landing .row .text-wrapper h5 .row .text-center h1 {
                  margin-bottom: 30px; } }
            .container-image-landing .row .text-wrapper .container .row .text-center h2, .container-image-landing .row .text-wrapper .container-post p .row .text-center h2, .container-post .container-image-landing .row .text-wrapper p .row .text-center h2, .container-image-landing .row .text-wrapper .container-post h2 .row .text-center h2, .container-post .container-image-landing .row .text-wrapper h2 .row .text-center h2, .container-image-landing .row .text-wrapper .container-post h3 .row .text-center h2, .container-post .container-image-landing .row .text-wrapper h3 .row .text-center h2, .container-image-landing .row .text-wrapper .container-post h5 .row .text-center h2, .container-post .container-image-landing .row .text-wrapper h5 .row .text-center h2, .container-video-landing .row .text-wrapper .container .row .text-center h2, .container-video-landing .row .text-wrapper .container-post p .row .text-center h2, .container-post .container-video-landing .row .text-wrapper p .row .text-center h2, .container-video-landing .row .text-wrapper .container-post h2 .row .text-center h2, .container-post .container-video-landing .row .text-wrapper h2 .row .text-center h2, .container-video-landing .row .text-wrapper .container-post h3 .row .text-center h2, .container-post .container-video-landing .row .text-wrapper h3 .row .text-center h2, .container-video-landing .row .text-wrapper .container-post h5 .row .text-center h2, .container-post .container-video-landing .row .text-wrapper h5 .row .text-center h2 {
              color: #ffffff; }
              @media (min-width: 992px) {
                .container-image-landing .row .text-wrapper .container .row .text-center h2, .container-image-landing .row .text-wrapper .container-post p .row .text-center h2, .container-post .container-image-landing .row .text-wrapper p .row .text-center h2, .container-image-landing .row .text-wrapper .container-post h2 .row .text-center h2, .container-post .container-image-landing .row .text-wrapper h2 .row .text-center h2, .container-image-landing .row .text-wrapper .container-post h3 .row .text-center h2, .container-post .container-image-landing .row .text-wrapper h3 .row .text-center h2, .container-image-landing .row .text-wrapper .container-post h5 .row .text-center h2, .container-post .container-image-landing .row .text-wrapper h5 .row .text-center h2, .container-video-landing .row .text-wrapper .container .row .text-center h2, .container-video-landing .row .text-wrapper .container-post p .row .text-center h2, .container-post .container-video-landing .row .text-wrapper p .row .text-center h2, .container-video-landing .row .text-wrapper .container-post h2 .row .text-center h2, .container-post .container-video-landing .row .text-wrapper h2 .row .text-center h2, .container-video-landing .row .text-wrapper .container-post h3 .row .text-center h2, .container-post .container-video-landing .row .text-wrapper h3 .row .text-center h2, .container-video-landing .row .text-wrapper .container-post h5 .row .text-center h2, .container-post .container-video-landing .row .text-wrapper h5 .row .text-center h2 {
                  font-size: 1.6875em;
                  font-weight: 300;
                  letter-spacing: 3.38px;
                  line-height: 37px; } }
    .container-image-landing .row .scroll-down-wrapper, .container-video-landing .row .scroll-down-wrapper {
      position: absolute;
      width: 100%;
      height: auto;
      bottom: 0;
      left: 0;
      right: 0; }
      @media (min-width: 992px) {
        .container-image-landing .row .scroll-down-wrapper, .container-video-landing .row .scroll-down-wrapper {
          width: 80%;
          left: 10%; } }
      .container-image-landing .row .scroll-down-wrapper .scroll-down, .container-video-landing .row .scroll-down-wrapper .scroll-down {
        position: relative;
        top: 0;
        left: 50%;
        margin-left: -22.5px;
        width: 45px;
        height: 45px;
        background: transparent;
        margin-bottom: 40px;
        text-align: center;
        transition: border .23s ease-out;
        cursor: pointer;
        overflow: hidden;
        z-index: 50; }
        @media (min-width: 992px) {
          .container-image-landing .row .scroll-down-wrapper .scroll-down, .container-video-landing .row .scroll-down-wrapper .scroll-down {
            left: 50%; }
            .container-image-landing .row .scroll-down-wrapper .scroll-down:hover img, .container-video-landing .row .scroll-down-wrapper .scroll-down:hover img {
              animation-name: scrolldown-arrow-hover;
              animation-duration: 0.7s;
              transition: all 0.35s cubic-bezier(0.2, 0.2, 0.3, 1); }
          @keyframes scrolldown-arrow-hover {
            0% {
              transform: translateY(0);
              opacity: 1; }
            50% {
              transform: translateY(16px); }
            51% {
              opacity: 0;
              transform: translateY(-16px); }
            52% {
              opacity: 1; }
            100% {
              transform: translateY(0); } } }
        .container-image-landing .row .scroll-down-wrapper .scroll-down.icon-white, .container-video-landing .row .scroll-down-wrapper .scroll-down.icon-white {
          border: 1px solid rgba(255, 255, 255, 0.6); }
          @media (min-width: 1px) {
            .container-image-landing .row .scroll-down-wrapper .scroll-down.icon-white:hover, .container-video-landing .row .scroll-down-wrapper .scroll-down.icon-white:hover {
              border: 1px solid white; } }
        .container-image-landing .row .scroll-down-wrapper .scroll-down.icon-black, .container-video-landing .row .scroll-down-wrapper .scroll-down.icon-black {
          border: 1px solid rgba(0, 0, 0, 0.6); }
          @media (min-width: 1px) {
            .container-image-landing .row .scroll-down-wrapper .scroll-down.icon-black:hover, .container-video-landing .row .scroll-down-wrapper .scroll-down.icon-black:hover {
              border: 1px solid black; } }
        .container-image-landing .row .scroll-down-wrapper .scroll-down img, .container-video-landing .row .scroll-down-wrapper .scroll-down img {
          position: relative;
          display: block;
          top: 50%;
          left: 50%;
          margin-top: -8px;
          margin-left: -16px;
          width: 32px;
          height: 16px; }

.container-image-landing-fixed {
  overflow: hidden;
  margin-bottom: 60px; }
  @media (min-width: 768px) {
    .container-image-landing-fixed {
      margin-bottom: 80px; } }
  @media (min-width: 992px) {
    .container-image-landing-fixed {
      margin-bottom: 80px; } }
  .container-image-landing-fixed .row {
    position: relative; }
    .container-image-landing-fixed .row .image-wrapper {
      min-height: 200px;
      overflow: hidden; }
      @media (min-width: 768px) {
        .container-image-landing-fixed .row .image-wrapper {
          min-height: 250px; } }
      @media (min-width: 992px) {
        .container-image-landing-fixed .row .image-wrapper {
          min-height: 350px; } }
      @media (min-width: 1600px) {
        .container-image-landing-fixed .row .image-wrapper {
          min-height: 400px; } }
      .container-image-landing-fixed .row .image-wrapper img {
        position: absolute;
        display: block;
        max-width: none;
        width: auto;
        top: -100%;
        bottom: -100%;
        left: -100%;
        right: -100%;
        margin: auto auto;
        min-height: 100%;
        min-width: 100%;
        will-change: transform; }
    .container-image-landing-fixed .row .text-wrapper .container, .container-image-landing-fixed .row .text-wrapper .container-post p, .container-post .container-image-landing-fixed .row .text-wrapper p, .container-image-landing-fixed .row .text-wrapper .container-post h2, .container-post .container-image-landing-fixed .row .text-wrapper h2, .container-image-landing-fixed .row .text-wrapper .container-post h3, .container-post .container-image-landing-fixed .row .text-wrapper h3, .container-image-landing-fixed .row .text-wrapper .container-post h5, .container-post .container-image-landing-fixed .row .text-wrapper h5 {
      margin-bottom: 0; }
      .container-image-landing-fixed .row .text-wrapper .container .row div, .container-image-landing-fixed .row .text-wrapper .container-post p .row div, .container-post .container-image-landing-fixed .row .text-wrapper p .row div, .container-image-landing-fixed .row .text-wrapper .container-post h2 .row div, .container-post .container-image-landing-fixed .row .text-wrapper h2 .row div, .container-image-landing-fixed .row .text-wrapper .container-post h3 .row div, .container-post .container-image-landing-fixed .row .text-wrapper h3 .row div, .container-image-landing-fixed .row .text-wrapper .container-post h5 .row div, .container-post .container-image-landing-fixed .row .text-wrapper h5 .row div {
        position: absolute;
        color: #ffffff;
        bottom: 25px; }
        @media (min-width: 768px) {
          .container-image-landing-fixed .row .text-wrapper .container .row div, .container-image-landing-fixed .row .text-wrapper .container-post p .row div, .container-post .container-image-landing-fixed .row .text-wrapper p .row div, .container-image-landing-fixed .row .text-wrapper .container-post h2 .row div, .container-post .container-image-landing-fixed .row .text-wrapper h2 .row div, .container-image-landing-fixed .row .text-wrapper .container-post h3 .row div, .container-post .container-image-landing-fixed .row .text-wrapper h3 .row div, .container-image-landing-fixed .row .text-wrapper .container-post h5 .row div, .container-post .container-image-landing-fixed .row .text-wrapper h5 .row div {
            bottom: 50px; } }

.container-video-landing {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 60px);
  background: #000;
  /* volume button */ }
  .container-video-landing .row {
    height: 100%; }
  .container-video-landing .text-wrapper {
    pointer-events: none; }
  @media (min-width: 992px) {
    .container-video-landing {
      height: 100%;
      height: calc(100vh - 86px); } }
  .container-video-landing #video-volume {
    display: none;
    position: absolute;
    left: 40px;
    bottom: 0;
    z-index: 50;
    cursor: pointer;
    width: 45px;
    height: 45px;
    background: transparent;
    margin-bottom: 40px;
    text-align: center;
    transition: border .23s ease-out;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.6); }
    @media (min-width: 1px) {
      .container-video-landing #video-volume:hover {
        border: 1px solid white; } }
    @media (min-width: 992px) {
      .container-video-landing #video-volume {
        display: block; } }
    .container-video-landing #video-volume img {
      position: relative;
      display: block;
      left: 10px;
      top: 12.5px; }
  .container-video-landing .video-wrapper {
    display: block;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform-origin: 50% 0; }
    .container-video-landing .video-wrapper .video {
      display: block;
      overflow: hidden;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0; }
      @media (min-width: 992px) {
        .container-video-landing .video-wrapper .video {
          height: calc(100% + 80px); } }
      .container-video-landing .video-wrapper .video #video-fallback-mobile {
        display: block;
        z-index: 1; }
        @media (min-width: 992px) {
          .container-video-landing .video-wrapper .video #video-fallback-mobile {
            display: none !important; } }
      .container-video-landing .video-wrapper .video #video-fallback-desktop {
        display: none;
        z-index: 1; }
        @media (min-width: 992px) {
          .container-video-landing .video-wrapper .video #video-fallback-desktop {
            display: block; } }
      .container-video-landing .video-wrapper .video iframe {
        display: none;
        pointer-events: none;
        width: 100%;
        height: 56.25%;
        min-height: 150%;
        min-width: 177.77%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10; }
        @media (min-width: 992px) {
          .container-video-landing .video-wrapper .video iframe {
            display: block; } }
        @media all and (min-width: 1600px) {
          .container-video-landing .video-wrapper .video iframe {
            min-height: 170%; } }

@media (min-width: 992px) {
  .container-news {
    margin-bottom: 0; } }

.container-news .image-wrapper {
  height: 186px;
  margin-bottom: 10px; }
  @media (min-width: 992px) {
    .container-news .image-wrapper {
      height: 300px;
      margin-bottom: 0; } }
  .container-news .image-wrapper img {
    position: absolute;
    display: block;
    min-width: 100%;
    max-width: none !important;
    width: auto;
    height: 100%;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto; }

@media (min-width: 992px) {
  .container-news .text-wrapper {
    height: 300px; } }

.container-news .text-wrapper h2 {
  margin: 6px 0 15px 0; }
  @media (min-width: 992px) {
    .container-news .text-wrapper h2 {
      margin: 6px 0 20px 0; } }

.container-news .text-wrapper p {
  color: #66666C; }

.container-news .text-wrapper .post-information span {
  color: #66666C;
  display: block; }
  .container-news .text-wrapper .post-information span.date {
    display: none; }

@media (min-width: 768px) {
  .container-news .text-wrapper .post-information span {
    display: inline-block; }
    .container-news .text-wrapper .post-information span.date {
      display: inline-block; } }

.container-news .text-wrapper .more {
  display: none; }
  @media (min-width: 992px) {
    .container-news .text-wrapper .more {
      display: inline-block;
      position: absolute;
      left: 15px;
      bottom: 0;
      font-size: 0.8125em;
      letter-spacing: 1px;
      color: #191919;
      text-transform: uppercase;
      font-weight: 500; } }

.container-news hr {
  display: none; }
  @media (min-width: 992px) {
    .container-news hr {
      display: block;
      margin: 40px 0 40px 0; } }

.container-news.last-child hr {
  opacity: 0 !important; }

.container-post-information {
  margin-bottom: 0 !important;
  max-width: 540px !important; }
  .container-post-information .post-information {
    margin-top: 0;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .container-post-information .post-information {
        margin-top: 0;
        margin-bottom: 25px; } }
    .container-post-information .post-information span.date {
      float: right; }
  .container-post-information .post-title h1 {
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .container-post-information .post-title h1 {
        margin-bottom: 60px; } }

.container-post {
  margin-bottom: 60px;
  /* elements should be centered as a container with max-width */
  /* headline - we have to overwrite all margins in every breakpoint */
  /* image full width */
  /* image description */ }
  @media (min-width: 768px) {
    .container-post {
      margin-bottom: 80px; } }
  @media (min-width: 992px) {
    .container-post {
      margin-bottom: 80px; } }
  .container-post p, .container-post h2, .container-post h3, .container-post h5 {
    max-width: 540px !important;
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .container-post p, .container-post h2, .container-post h3, .container-post h5 {
        margin-bottom: 0; } }
    @media (min-width: 992px) {
      .container-post p, .container-post h2, .container-post h3, .container-post h5 {
        margin-bottom: 0; } }
  .container-post h2 {
    margin-top: 30px;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .container-post h2 {
        margin-top: 60px;
        margin-bottom: 25px; } }
    @media (min-width: 992px) {
      .container-post h2 {
        margin-top: 60px;
        margin-bottom: 25px; } }
  .container-post h4 {
    position: relative;
    display: block;
    margin-top: 60px; }
  .container-post h5 {
    font-size: 0.625em;
    color: #66666C;
    margin-top: 8px;
    font-weight: 300; }
  .container-post p {
    color: #66666C; }
  .container-post em, .container-post i {
    color: #191919; }
  .container-post img {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 25px 0; }
    @media (min-width: 992px) {
      .container-post img {
        margin: 60px 0; } }

.container-text.textblock-with-image {
  padding-left: 0;
  padding-right: 0;
  background: #191922;
  margin-top: 155px;
  margin-bottom: 60px; }
  @media (min-width: 480px) {
    .container-text.textblock-with-image {
      margin-top: 186.5px; } }
  @media (min-width: 768px) {
    .container-text.textblock-with-image {
      margin-top: 279.5px;
      margin-bottom: 80px; } }
  @media (min-width: 992px) {
    .container-text.textblock-with-image {
      margin-top: 344.5px;
      margin-bottom: 80px; } }
  @media (min-width: 1200px) {
    .container-text.textblock-with-image {
      margin-top: 392px; } }
  .container-text.textblock-with-image.no-image {
    margin-top: 0;
    padding-top: 60px; }
    @media (min-width: 480px) {
      .container-text.textblock-with-image.no-image {
        padding-top: 60px; } }
    @media (min-width: 768px) {
      .container-text.textblock-with-image.no-image {
        padding-top: 80px; } }
    @media (min-width: 992px) {
      .container-text.textblock-with-image.no-image {
        padding-top: 80px; } }
    @media (min-width: 1200px) {
      .container-text.textblock-with-image.no-image {
        padding-top: 80px; } }
  .container-text.textblock-with-image .textblock h2 {
    color: #f8f8f8;
    margin-bottom: 20px; }
  .container-text.textblock-with-image .textblock p {
    color: #B2B2B5; }
  .container-text.textblock-with-image .textblock-second {
    margin-top: 40px; }
    @media (min-width: 768px) {
      .container-text.textblock-with-image .textblock-second {
        margin-top: 60px; } }
  .container-text.textblock-with-image .textblock-image {
    margin-bottom: 40px; }
    @media (min-width: 992px) {
      .container-text.textblock-with-image .textblock-image {
        margin-bottom: 60px; } }
    .container-text.textblock-with-image .textblock-image .image-wrapper {
      margin-top: -95px;
      height: 190px;
      /* force 100% height */
      /* youtube video */ }
      @media (min-width: 480px) {
        .container-text.textblock-with-image .textblock-image .image-wrapper {
          margin-top: -126.5px;
          height: 253px; } }
      @media (min-width: 768px) {
        .container-text.textblock-with-image .textblock-image .image-wrapper {
          margin-top: -199.5px;
          height: 399px; } }
      @media (min-width: 992px) {
        .container-text.textblock-with-image .textblock-image .image-wrapper {
          margin-top: -264.5px;
          height: 529px; } }
      @media (min-width: 1200px) {
        .container-text.textblock-with-image .textblock-image .image-wrapper {
          margin-top: -312px;
          height: 624px; } }
      .container-text.textblock-with-image .textblock-image .image-wrapper img {
        position: absolute;
        left: -100%;
        top: -100%;
        right: -100%;
        bottom: -100%;
        margin: auto auto;
        max-width: none; }
      .container-text.textblock-with-image .textblock-image .image-wrapper .animate-image {
        height: 100%; }
        .container-text.textblock-with-image .textblock-image .image-wrapper .animate-image iframe {
          position: absolute;
          display: block;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0; }

.container-text.textblock h2,
.container-text .with-headline h2 {
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .container-text.textblock h2,
    .container-text .with-headline h2 {
      margin-bottom: 20px; } }

.container-text.with-image {
  position: relative;
  /* last child */
  /* if there is only one child, we place the image after the text */ }
  @media (min-width: 992px) {
    .container-text.with-image {
      margin-bottom: 0; } }
  .container-text.with-image hr {
    display: none; }
    @media (min-width: 992px) {
      .container-text.with-image hr {
        display: block;
        margin-top: 60px;
        margin-bottom: 60px; } }
    @media (min-width: 1200px) {
      .container-text.with-image hr {
        margin-top: 40px;
        margin-bottom: 40px; } }
  .container-text.with-image .row {
    flex-direction: column;
    /* position left / right */ }
    @media (min-width: 768px) {
      .container-text.with-image .row {
        flex-direction: row; } }
    @media (min-width: 768px) {
      .container-text.with-image .row {
        justify-content: space-between; } }
    .container-text.with-image .row .text-wrapper {
      text-align: left; }
      @media (min-width: 992px) {
        .container-text.with-image .row .text-wrapper {
          align-self: center;
          margin-right: auto; } }
      .container-text.with-image .row .text-wrapper h3 {
        margin-bottom: 20px; }
        @media (min-width: 992px) {
          .container-text.with-image .row .text-wrapper h3 {
            margin-bottom: 20px; } }
    .container-text.with-image .row .image-wrapper {
      margin-bottom: 30px; }
      @media (min-width: 768px) {
        .container-text.with-image .row .image-wrapper {
          margin-bottom: 0; } }
    @media (min-width: 992px) {
      .container-text.with-image .row.text-left {
        flex-direction: row-reverse; } }
  .container-text.with-image.last-child hr {
    display: none; }
  @media (min-width: 992px) {
    .container-text.with-image.last-child {
      margin-bottom: 80px; } }
  .container-text.with-image.one-child .row {
    flex-direction: column-reverse; }
    @media (min-width: 768px) {
      .container-text.with-image.one-child .row {
        flex-direction: row; }
        .container-text.with-image.one-child .row.text-left {
          flex-direction: row-reverse; } }
    .container-text.with-image.one-child .row .text-wrapper {
      margin-bottom: 60px; }
      @media (min-width: 768px) {
        .container-text.with-image.one-child .row .text-wrapper {
          margin-bottom: 0; } }

.container-contact-form {
  padding: 0 !important;
  background: #fff; }
  .container-contact-form .container, .container-contact-form .container-post p, .container-post .container-contact-form p, .container-contact-form .container-post h2, .container-post .container-contact-form h2, .container-contact-form .container-post h3, .container-post .container-contact-form h3, .container-contact-form .container-post h5, .container-post .container-contact-form h5 {
    margin-top: 60px;
    margin-bottom: 80px;
    max-width: 570px; }
    @media (min-width: 768px) {
      .container-contact-form .container, .container-contact-form .container-post p, .container-post .container-contact-form p, .container-contact-form .container-post h2, .container-post .container-contact-form h2, .container-contact-form .container-post h3, .container-post .container-contact-form h3, .container-contact-form .container-post h5, .container-post .container-contact-form h5 {
        margin-top: 60px;
        margin-bottom: 120px; } }
    @media (min-width: 992px) {
      .container-contact-form .container, .container-contact-form .container-post p, .container-post .container-contact-form p, .container-contact-form .container-post h2, .container-post .container-contact-form h2, .container-contact-form .container-post h3, .container-post .container-contact-form h3, .container-contact-form .container-post h5, .container-post .container-contact-form h5 {
        max-width: 540px; } }
    .container-contact-form .container h2, .container-contact-form .container-post p h2, .container-post .container-contact-form p h2, .container-contact-form .container-post h2 h2, .container-post .container-contact-form h2 h2, .container-contact-form .container-post h3 h2, .container-post .container-contact-form h3 h2, .container-contact-form .container-post h5 h2, .container-post .container-contact-form h5 h2 {
      margin-top: 0;
      margin-bottom: 40px; }
      @media (min-width: 992px) {
        .container-contact-form .container h2, .container-contact-form .container-post p h2, .container-post .container-contact-form p h2, .container-contact-form .container-post h2 h2, .container-post .container-contact-form h2 h2, .container-contact-form .container-post h3 h2, .container-post .container-contact-form h3 h2, .container-contact-form .container-post h5 h2, .container-post .container-contact-form h5 h2 {
          margin-bottom: 40px; } }
    .container-contact-form .container p.privacy, .container-contact-form .container-post p p.privacy, .container-post .container-contact-form p p.privacy, .container-contact-form .container-post h2 p.privacy, .container-post .container-contact-form h2 p.privacy, .container-contact-form .container-post h3 p.privacy, .container-post .container-contact-form h3 p.privacy, .container-contact-form .container-post h5 p.privacy, .container-post .container-contact-form h5 p.privacy {
      margin-top: 25px; }
      @media (min-width: 992px) {
        .container-contact-form .container p.privacy, .container-contact-form .container-post p p.privacy, .container-post .container-contact-form p p.privacy, .container-contact-form .container-post h2 p.privacy, .container-post .container-contact-form h2 p.privacy, .container-contact-form .container-post h3 p.privacy, .container-post .container-contact-form h3 p.privacy, .container-contact-form .container-post h5 p.privacy, .container-post .container-contact-form h5 p.privacy {
          margin-top: 40px; } }
      .container-contact-form .container p.privacy a, .container-contact-form .container-post p p.privacy a, .container-post .container-contact-form p p.privacy a, .container-contact-form .container-post h2 p.privacy a, .container-post .container-contact-form h2 p.privacy a, .container-contact-form .container-post h3 p.privacy a, .container-post .container-contact-form h3 p.privacy a, .container-contact-form .container-post h5 p.privacy a, .container-post .container-contact-form h5 p.privacy a {
        text-decoration: underline; }

.container-contact-field {
  padding: 0 !important;
  /* buttons */
  /* map */
  /* adjust margin for imprint page */
  /* multiple locations */ }
  .container-contact-field h2 {
    margin-bottom: 5px; }
    @media (min-width: 992px) {
      .container-contact-field h2 {
        margin-bottom: 5px; } }
  .container-contact-field h2.follow {
    margin-bottom: 15px;
    margin-top: 30px; }
    @media (min-width: 768px) {
      .container-contact-field h2.follow {
        margin-top: 0; } }
    @media (min-width: 992px) {
      .container-contact-field h2.follow {
        margin-bottom: 15px; } }
  .container-contact-field .block {
    margin-bottom: 30px; }
    .container-contact-field .block.last-child,
    .container-contact-field .block p.adress {
      margin-bottom: 0; }
    @media (max-width: 767px) {
      .container-contact-field .block.no-mb-xs {
        margin-bottom: 0; } }
  .container-contact-field a.social {
    margin-bottom: 15px;
    margin-right: 15px;
    margin-top: 0; }
    @media (min-width: 768px) {
      .container-contact-field a.social img {
        width: 26px;
        height: 26px; } }
  .container-contact-field a.button.ga {
    margin-top: 40px; }
    @media (min-width: 992px) {
      .container-contact-field a.button.ga {
        margin-top: 40px; } }
  .container-contact-field .map {
    overflow: hidden; }
    @media (min-width: 992px) {
      .container-contact-field .map {
        display: block; } }
    .container-contact-field .map .iframe-wrapper {
      background: #fff;
      position: relative;
      height: 0;
      padding-top: 100%; }
      .container-contact-field .map .iframe-wrapper iframe#iframe-map {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%; }
  .container-contact-field.imprint {
    margin-top: -30px !important; }
  .container-contact-field.multiple {
    /* reset mb */ }
    .container-contact-field.multiple a.link-block {
      margin-top: 15px; }
    .container-contact-field.multiple h2.location-name {
      margin-bottom: 20px; }
      @media (min-width: 992px) {
        .container-contact-field.multiple h2.location-name {
          margin-bottom: 20px; } }
    .container-contact-field.multiple .image-wrapper {
      margin-bottom: 20px; }
    .container-contact-field.multiple .subblock {
      margin-bottom: 14px; }
    @media (min-width: 768px) {
      .container-contact-field.multiple .row:last-child .container-mb {
        margin-bottom: 0 !important; } }

nav {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  max-height: 60px;
  background: #ffffff;
  z-index: 100;
  /* list items */ }
  @media (min-width: 992px) {
    nav {
      height: 86px;
      max-height: 86px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15); } }
  nav .container, nav .container-post p, .container-post nav p, nav .container-post h2, .container-post nav h2, nav .container-post h3, .container-post nav h3, nav .container-post h5, .container-post nav h5 {
    height: 100%; }
    @media all and (min-width: 992px) and (max-width: 1199px) {
      nav .container, nav .container-post p, .container-post nav p, nav .container-post h2, .container-post nav h2, nav .container-post h3, .container-post nav h3, nav .container-post h5, .container-post nav h5 {
        width: 100%;
        padding-left: 25px;
        padding-right: 25px; }
        nav .container #nav-menu li, nav .container-post p #nav-menu li, .container-post nav p #nav-menu li, nav .container-post h2 #nav-menu li, .container-post nav h2 #nav-menu li, nav .container-post h3 #nav-menu li, .container-post nav h3 #nav-menu li, nav .container-post h5 #nav-menu li, .container-post nav h5 #nav-menu li {
          margin-left: 20px !important; }
        nav .container #nav-languages-desktop, nav .container-post p #nav-languages-desktop, .container-post nav p #nav-languages-desktop, nav .container-post h2 #nav-languages-desktop, .container-post nav h2 #nav-languages-desktop, nav .container-post h3 #nav-languages-desktop, .container-post nav h3 #nav-languages-desktop, nav .container-post h5 #nav-languages-desktop, .container-post nav h5 #nav-languages-desktop {
          margin-left: 25px !important; } }
  nav #nav-wrapper {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 60px;
    background: #ffffff;
    z-index: 110;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15); }
    @media (min-width: 992px) {
      nav #nav-wrapper {
        height: 86px;
        width: 257px;
        position: relative;
        display: inline;
        box-shadow: none;
        background: transparent; } }
    @media (min-width: 1200px) {
      nav #nav-wrapper {
        width: 257px;
        height: 16px; } }
    nav #nav-wrapper #nav-logo {
      position: relative;
      display: inline-block;
      left: 0;
      height: 16px;
      width: 257px;
      margin-left: 25px;
      background: transparent;
      top: 23px;
      cursor: pointer; }
      @media (max-width: 320px) {
        nav #nav-wrapper #nav-logo {
          height: 16px;
          width: 257px;
          top: 23px; } }
      @media (max-width: 374px) {
        nav #nav-wrapper #nav-logo {
          margin-left: 15px; } }
      @media (min-width: 992px) {
        nav #nav-wrapper #nav-logo {
          margin-left: 0;
          width: 257px;
          height: 16px;
          top: 36px; } }
      @media (min-width: 1200px) {
        nav #nav-wrapper #nav-logo {
          width: 257px;
          height: 16px;
          top: 36px; } }
      nav #nav-wrapper #nav-logo a {
        position: relative;
        display: block;
        height: 16px;
        width: 257px; }
        @media (max-width: 320px) {
          nav #nav-wrapper #nav-logo a {
            height: 16px;
            width: 257px; } }
        @media (min-width: 992px) {
          nav #nav-wrapper #nav-logo a {
            width: 257px;
            height: 16px; } }
        @media (min-width: 1200px) {
          nav #nav-wrapper #nav-logo a {
            width: 257px;
            height: 16px; } }
        nav #nav-wrapper #nav-logo a img {
          position: relative;
          display: block;
          width: 100%;
          height: auto; }
    nav #nav-wrapper #nav-icon {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 25px;
      height: 100%;
      width: 24px;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
      @media (max-width: 374px) {
        nav #nav-wrapper #nav-icon {
          margin-right: 15px; } }
      @media (min-width: 992px) {
        nav #nav-wrapper #nav-icon {
          display: none; } }
      nav #nav-wrapper #nav-icon .line {
        position: relative;
        display: block;
        width: 24px;
        height: 1px;
        top: 50%;
        margin-top: -0.5px;
        background: #000;
        transition-property: transform;
        transition-duration: 150ms;
        transition-delay: 150ms;
        transition-timing-function: ease-in; }
        nav #nav-wrapper #nav-icon .line:before, nav #nav-wrapper #nav-icon .line:after {
          content: '';
          position: absolute;
          width: 24px;
          height: 1px;
          background: #000;
          transition-property: transform; }
        nav #nav-wrapper #nav-icon .line:before {
          top: -5px;
          transition: top 300ms 300ms cubic-bezier(0.215, 0.61, 0.355, 0.6), transform 300ms ease-in; }
        nav #nav-wrapper #nav-icon .line:after {
          bottom: -5px;
          transition: bottom 300ms 300ms cubic-bezier(0.215, 0.61, 0.355, 0.6), transform 300ms ease-in; }
      nav #nav-wrapper #nav-icon.open .line {
        transform: translate3d(0, 0, 0) rotate(-45deg);
        transition-delay: 300ms;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 0.6); }
        nav #nav-wrapper #nav-icon.open .line:before {
          top: 0;
          transform: rotate(-90deg);
          transition: top 300ms ease-in, transform 150ms 300ms cubic-bezier(0.215, 0.61, 0.355, 0.6); }
        nav #nav-wrapper #nav-icon.open .line:after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 300ms ease-in, transform 150ms 300ms cubic-bezier(0.215, 0.61, 0.355, 0.6); }
  nav #nav-menu {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding: 50px 25px 0 50px;
    background: rgba(255, 255, 255, 0.85);
    background-position: -99999px -99999px;
    list-style: none;
    z-index: 105;
    /* background image */
    /* container fix */ }
    nav #nav-menu:before {
      content: ' ';
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      visibility: hidden;
      width: 100%;
      height: 100%;
      height: 100vh;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
      @media (max-width: 767px) {
        nav #nav-menu:before {
          background-image: url("/build/assets/img/menu_mobile.jpg"); } }
  @media (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 767px) and (min-device-pixel-ratio: 2), (max-width: 767px) and (min-resolution: 192dpi) {
    nav #nav-menu:before {
      background-image: url("/build/assets/img/menu_mobile@2x.jpg"); } }
      @media (min-width: 768px) {
        nav #nav-menu:before {
          background-image: url("/build/assets/img/menu_tablet.jpg"); } }
  @media (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 768px) and (min-device-pixel-ratio: 2), (min-width: 768px) and (min-resolution: 192dpi) {
    nav #nav-menu:before {
      background-image: url("/build/assets/img/menu_tablet@2x.jpg"); } }
    @media (max-width: 374px) {
      nav #nav-menu {
        padding: 50px 15px 0 30px; } }
    @media (min-width: 992px) {
      nav #nav-menu {
        position: relative;
        width: auto;
        display: inline;
        float: right;
        height: 86px !important;
        padding: 0;
        margin: 0;
        background: transparent;
        top: 0; }
        nav #nav-menu:before {
          display: none; } }
    nav #nav-menu .container, nav #nav-menu .container-post p, .container-post nav #nav-menu p, nav #nav-menu .container-post h2, .container-post nav #nav-menu h2, nav #nav-menu .container-post h3, .container-post nav #nav-menu h3, nav #nav-menu .container-post h5, .container-post nav #nav-menu h5 {
      height: 100%;
      padding-left: 0;
      padding-right: 0; }
      @media (min-width: 992px) {
        nav #nav-menu .container, nav #nav-menu .container-post p, .container-post nav #nav-menu p, nav #nav-menu .container-post h2, .container-post nav #nav-menu h2, nav #nav-menu .container-post h3, .container-post nav #nav-menu h3, nav #nav-menu .container-post h5, .container-post nav #nav-menu h5 {
          padding-left: 15px;
          padding-right: 15px; } }
    nav #nav-menu li {
      margin-top: 25px;
      height: auto;
      /* active classes */ }
      @media (min-width: 992px) {
        nav #nav-menu li {
          display: inline-block;
          height: 53px;
          margin-left: 50px;
          margin-top: 33px; }
          nav #nav-menu li:first-child {
            margin-left: 0; } }
      @media (min-width: 992px) {
        nav #nav-menu li {
          margin-left: 30px; } }
      @media (min-width: 1200px) {
        nav #nav-menu li {
          margin-left: 30px; } }
      @media (min-width: 1440px) {
        nav #nav-menu li {
          margin-left: 30px; } }
      nav #nav-menu li a {
        text-transform: uppercase;
        font-size: 1.25em;
        font-weight: 500;
        color: #99999D;
        letter-spacing: 2px; }
        @media (min-width: 992px) {
          nav #nav-menu li a {
            font-size: 0.8125em;
            letter-spacing: 1px; }
            nav #nav-menu li a:hover {
              color: #000000; } }
      nav #nav-menu li .wrapper-sub-menu {
        display: none; }
        @media (min-width: 992px) {
          nav #nav-menu li .wrapper-sub-menu {
            display: block;
            position: fixed;
            top: 86px;
            left: 0;
            width: 100%;
            height: 50px;
            background: rgba(0, 0, 10, 0.8); } }
        nav #nav-menu li .wrapper-sub-menu ul.sub-menu {
          list-style: none; }
          nav #nav-menu li .wrapper-sub-menu ul.sub-menu li {
            padding-left: 30px; }
            nav #nav-menu li .wrapper-sub-menu ul.sub-menu li:before {
              content: '';
              position: absolute;
              left: 0;
              margin-top: 11px;
              width: 18px;
              height: 1px;
              background: #99999D; }
            @media (min-width: 992px) {
              nav #nav-menu li .wrapper-sub-menu ul.sub-menu li {
                margin-top: 15px;
                height: 35px;
                padding-left: 0; }
                nav #nav-menu li .wrapper-sub-menu ul.sub-menu li:before {
                  display: none; } }
            nav #nav-menu li .wrapper-sub-menu ul.sub-menu li a {
              color: #AAAAAA; }
              nav #nav-menu li .wrapper-sub-menu ul.sub-menu li a:hover {
                color: #ffffff; }
      nav #nav-menu li.current-menu-item {
        /* sub menu only on desktop */ }
        nav #nav-menu li.current-menu-item a {
          color: #000000; }
        @media (min-width: 992px) {
          nav #nav-menu li.current-menu-item ul.sub-menu li a.active {
            color: #f8f8f8; } }
  nav #nav-languages-mobile {
    position: relative;
    display: block;
    background: transparent;
    text-align: left;
    z-index: 110;
    visibility: hidden;
    opacity: 0;
    margin-top: 40px !important; }
    nav #nav-languages-mobile ul {
      list-style: none; }
    nav #nav-languages-mobile li {
      margin-top: 0; }
    @media (min-width: 992px) {
      nav #nav-languages-mobile {
        display: none !important; } }
  nav #nav-languages-desktop {
    position: relative;
    float: right;
    right: 0;
    height: 86px;
    line-height: 91px;
    margin-left: 50px; }
    nav #nav-languages-desktop a:hover {
      color: #191922 !important; }
    @media (max-width: 991px) {
      nav #nav-languages-desktop {
        display: none !important; } }
    @media (min-width: 992px) {
      nav #nav-languages-desktop {
        display: block; } }
  nav #nav-social {
    position: relative;
    display: block;
    padding-bottom: 35px;
    margin-bottom: 15px;
    margin-top: 25px !important;
    visibility: hidden;
    opacity: 0; }
    @media (min-width: 992px) {
      nav #nav-social {
        display: none !important; } }
    nav #nav-social ul {
      list-style: none; }
    nav #nav-social li {
      margin-right: 25px;
      margin-top: 0 !important;
      display: inline-block; }
      nav #nav-social li a img {
        position: relative;
        display: block;
        width: 30px;
        height: 30px;
        opacity: .4;
        transition: opacity .23s ease-out; }
        nav #nav-social li a img:hover {
          opacity: 1; }
  nav #nav-languages-mobile li,
  nav #nav-languages-desktop li {
    margin-right: 10px;
    display: inline-block;
    /* seperator */
    /* reset the last child */ }
    nav #nav-languages-mobile li a,
    nav #nav-languages-desktop li a {
      text-transform: uppercase;
      font-size: 0.9375em;
      font-weight: 500;
      color: #99999D !important;
      letter-spacing: 2px; }
      nav #nav-languages-mobile li a.active,
      nav #nav-languages-desktop li a.active {
        color: #000000 !important;
        pointer-events: none; }
      @media (min-width: 992px) {
        nav #nav-languages-mobile li a,
        nav #nav-languages-desktop li a {
          font-size: 0.8125em;
          letter-spacing: 1px; }
          nav #nav-languages-mobile li a:hover,
          nav #nav-languages-desktop li a:hover {
            color: #000000; } }
    nav #nav-languages-mobile li:after,
    nav #nav-languages-desktop li:after {
      content: '|';
      position: relative;
      right: -5px;
      color: #888888; }
    nav #nav-languages-mobile li.last,
    nav #nav-languages-desktop li.last {
      margin-right: 0; }
      nav #nav-languages-mobile li.last:after,
      nav #nav-languages-desktop li.last:after {
        display: none; }

@media (max-width: 991px) {
  nav #nav-wrapper {
    transition: box-shadow 200ms ease-out;
    transition-delay: 550ms; }
  nav #nav-menu {
    overflow-y: scroll;
    transition: background 200ms ease-out;
    transition-delay: 550ms;
    /* social */ }
    nav #nav-menu:before {
      transition: all 200ms ease-out;
      transform: translateY(-75px); }
    nav #nav-menu li {
      transition: opacity 200ms ease-out, transform 200ms ease-out;
      transform: translateX(-25px) scale(1);
      opacity: 0;
      /* closing animation */
      /* reset for sub menu */ }
      nav #nav-menu li:nth-child(1) {
        transition-delay: 500ms, 500ms; }
      nav #nav-menu li:nth-child(2) {
        transition-delay: 450ms, 450ms; }
      nav #nav-menu li:nth-child(3) {
        transition-delay: 400ms, 400ms; }
      nav #nav-menu li:nth-child(4) {
        transition-delay: 350ms, 350ms; }
      nav #nav-menu li:nth-child(5) {
        transition-delay: 300ms, 300ms; }
      nav #nav-menu li:nth-child(6) {
        transition-delay: 250ms, 250ms; }
      nav #nav-menu li:nth-child(7) {
        transition-delay: 200ms, 200ms; }
      nav #nav-menu li:nth-child(8) {
        transition-delay: 150ms, 150ms; }
      nav #nav-menu li:nth-child(9) {
        transition-delay: 100ms, 100ms; }
      nav #nav-menu li ul.sub-menu li {
        transition: none;
        transform: none;
        opacity: 1;
        visibility: visible;
        transition-delay: 0; }
    nav #nav-menu li.social {
      transform: translateX(0) scale(1);
      opacity: 1; }
  nav #nav-social ul {
    transition: opacity 200ms ease-out, transform 200ms ease-out;
    transform: translateX(-25px) scale(1);
    opacity: 0; }
  nav #nav-languages-mobile ul {
    transition: all 200ms ease-out;
    transition-delay: 0;
    transform: translateX(-25px) scale(1);
    visibility: hidden;
    opacity: 0; }
  nav.open #nav-wrapper {
    transition: box-shadow 200ms ease-out;
    box-shadow: none;
    background: transparent; }
  nav.open #nav-menu {
    background-color: #ffffff;
    transition-delay: 0; }
    nav.open #nav-menu:before {
      opacity: 1 !important;
      visibility: visible;
      transition: all 600ms ease;
      transform: translateY(0);
      transition-delay: 250ms; }
    nav.open #nav-menu li {
      transform: translateX(0) scale(1);
      opacity: 1;
      /* opening animation */ }
      nav.open #nav-menu li:nth-child(1) {
        transition-delay: 150ms, 150ms; }
      nav.open #nav-menu li:nth-child(2) {
        transition-delay: 200ms, 200ms; }
      nav.open #nav-menu li:nth-child(3) {
        transition-delay: 250ms, 250ms; }
      nav.open #nav-menu li:nth-child(4) {
        transition-delay: 300ms, 300ms; }
      nav.open #nav-menu li:nth-child(5) {
        transition-delay: 350ms, 350ms; }
      nav.open #nav-menu li:nth-child(6) {
        transition-delay: 400ms, 400ms; }
      nav.open #nav-menu li:nth-child(7) {
        transition-delay: 450ms, 450ms; }
      nav.open #nav-menu li:nth-child(8) {
        transition-delay: 500ms, 500ms; }
      nav.open #nav-menu li:nth-child(9) {
        transition-delay: 550ms, 550ms; }
    nav.open #nav-menu #nav-social ul {
      transform: translateX(0) scale(1);
      opacity: 1;
      visibility: visible;
      transition-delay: 600ms; }
  nav.open #nav-languages-mobile ul {
    visibility: visible;
    opacity: 1;
    transform: translateX(0) scale(1);
    transition-delay: 550ms; } }

@media (min-width: 992px) {
  nav #nav-menu .wrapper-sub-menu {
    z-index: 1;
    visibility: hidden;
    opacity: 0; }
    nav #nav-menu .wrapper-sub-menu.open {
      opacity: 1;
      visibility: visible; } }

body[class*="post"] nav #nav-menu li:nth-child(5) a {
  color: #191919; }

body[class*="workshops"] nav #nav-menu li:nth-child(4) a {
  color: #191919; }

#footer {
  position: relative;
  display: block;
  height: auto;
  padding-top: 50px;
  padding-left: 0;
  padding-right: 0;
  /* background images */
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  /* images mobile */
  /* images tablet */
  /* images desktop */ }
  @media (max-width: 767px) {
    #footer {
      background-image: url("/build/assets/img/footer_mobile.jpg"); } }
  @media (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 767px) and (min-device-pixel-ratio: 2), (max-width: 767px) and (min-resolution: 192dpi) {
    #footer {
      background-image: url("/build/assets/img/footer_mobile@2x.jpg"); } }
  @media (min-width: 768px) {
    #footer {
      background-image: url("/build/assets/img/footer_tablet.jpg"); } }
  @media (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 768px) and (min-device-pixel-ratio: 2), (min-width: 768px) and (min-resolution: 192dpi) {
    #footer {
      background-image: url("/build/assets/img/footer_tablet@2x.jpg"); } }
  @media (min-width: 992px) {
    #footer {
      background-image: url("/build/assets/img/footer_desktop.jpg"); } }
  @media (min-width: 992px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 992px) and (min-device-pixel-ratio: 2), (min-width: 992px) and (min-resolution: 192dpi) {
    #footer {
      background-image: url("/build/assets/img/footer_desktop@2x.jpg"); } }
  #footer .container, #footer .container-post p, .container-post #footer p, #footer .container-post h2, .container-post #footer h2, #footer .container-post h3, .container-post #footer h3, #footer .container-post h5, .container-post #footer h5 {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    #footer {
      padding-top: 60px; } }
  @media (min-width: 992px) {
    #footer {
      padding-top: 80px; } }
  #footer a, #footer p {
    line-height: 1;
    color: #E5E5E6;
    font-weight: 500;
    font-size: 0.8125em;
    text-transform: uppercase;
    letter-spacing: 1px; }
  #footer a:hover {
    text-decoration: underline; }
  #footer p {
    text-transform: none; }
  #footer a.inactive, #footer p.inactive {
    color: #7F7F84;
    text-transform: none; }
  #footer a.inactive:hover {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.7); }
  @media (min-width: 1200px) {
    #footer .email {
      text-overflow: ellipsis;
      white-space: nowrap; } }
  @media (min-width: 768px) {
    #footer .email-wrapper {
      margin-left: 0;
      float: right; } }
  @media (min-width: 992px) {
    #footer .email-wrapper {
      float: left; } }
  #footer ul {
    list-style: none; }
  #footer ul.menu {
    padding-left: 0;
    list-style: none;
    margin-bottom: 40px;
    /* contact menus */ }
    @media (min-width: 992px) {
      #footer ul.menu {
        margin-bottom: 40px; } }
    #footer ul.menu li {
      margin-bottom: 15px;
      line-height: 1; }
      #footer ul.menu li:last-child {
        margin-bottom: 0; }
    #footer ul.menu.contact {
      margin-bottom: 15px; }
      #footer ul.menu.contact.telephone {
        margin-bottom: 40px; }
        @media (min-width: 992px) {
          #footer ul.menu.contact.telephone {
            margin-bottom: 40px; } }
      #footer ul.menu.contact li, #footer ul.menu.contact p {
        line-height: 1.3125em; }
  #footer hr {
    background: #4C4C53; }
  #footer .social-buttons {
    position: relative;
    display: block;
    margin-bottom: 40px; }
    @media (min-width: 992px) {
      #footer .social-buttons {
        float: right;
        text-align: right;
        margin-bottom: 0; } }
    @media (min-width: 992px) {
      #footer .social-buttons {
        float: left; } }
    #footer .social-buttons a {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      opacity: 0.75;
      margin-right: 25px;
      transition: opacity .23s ease-out; }
      @media (min-width: 1px) {
        #footer .social-buttons a:hover {
          opacity: 0.95; } }
      #footer .social-buttons a:last-child {
        margin-right: 0; }
      @media (min-width: 992px) {
        #footer .social-buttons a {
          margin-right: 0;
          margin-left: 10px;
          width: 26px;
          height: 26px; }
          #footer .social-buttons a:first-child {
            margin-left: 0; } }
      #footer .social-buttons a img {
        display: block;
        position: relative;
        width: inherit;
        height: inherit; }
  #footer .copyright, #footer .design-by {
    margin-bottom: 10px;
    text-align: left; }
    #footer .copyright p, #footer .design-by p {
      line-height: 1.5;
      display: inline; }
    @media (min-width: 768px) {
      #footer .copyright, #footer .design-by {
        margin-bottom: 15px; } }
  #footer .design-by {
    padding-top: 0;
    display: inline;
    margin-bottom: 40px; }
    #footer .design-by a {
      text-transform: lowercase;
      display: inline; }
      @media (min-width: 1px) {
        #footer .design-by a:hover {
          color: white;
          text-decoration: none; } }
    @media (min-width: 768px) {
      #footer .design-by {
        margin-top: 0;
        margin-bottom: 80px; } }

.container-gallery {
  /* reset the first line */
  margin-top: -15px; }
  @media (max-width: 374px) {
    .container-gallery {
      margin-top: -15px; } }
  @media (min-width: 768px) {
    .container-gallery {
      margin-top: -30px; } }
  .container-gallery figure {
    position: relative;
    display: block;
    margin-top: 15px;
    /* correct spacing */
    /* hover animation */ }
    @media (max-width: 767px) {
      .container-gallery figure.col-xs-6:nth-child(even) {
        padding-left: 7.5px; }
      .container-gallery figure.col-xs-6:nth-child(odd) {
        padding-right: 7.5px; } }
    @media (max-width: 374px) {
      .container-gallery figure {
        margin-top: 15px; } }
    @media (min-width: 768px) {
      .container-gallery figure {
        margin-top: 30px; } }
    @media (min-width: 992px) {
      .container-gallery figure:hover img {
        opacity: 0.4;
        transform: scale(1.1);
        transition: all 0.5s cubic-bezier(0.65, 0.1, 0.3, 1); }
      .container-gallery figure:hover .description-top,
      .container-gallery figure:hover .description-bottom {
        transform: translateX(0);
        opacity: 1 !important;
        visibility: visible !important; } }
    .container-gallery figure .image-wrapper {
      position: relative;
      width: 100%;
      padding-top: 100%;
      /* 1:1 Aspect Ratio */
      background: #00000A; }
      .container-gallery figure .image-wrapper img {
        position: absolute;
        width: 100%;
        max-width: none;
        object-fit: cover;
        min-height: 100%;
        margin: auto auto;
        left: -100%;
        top: -100%;
        right: -100%;
        bottom: -100%;
        transition: all 0.5s cubic-bezier(0.65, 0.1, 0.3, 1); }
    .container-gallery figure figcaption {
      position: absolute;
      display: block;
      overflow: hidden;
      top: 0;
      bottom: 0;
      left: 25px;
      right: 25px;
      cursor: pointer; }
      @media (max-width: 374px) {
        .container-gallery figure figcaption {
          left: 15px;
          right: 15px; } }
      @media (min-width: 768px) {
        .container-gallery figure figcaption {
          left: 15px;
          right: 15px; } }
      .container-gallery figure figcaption .description-top,
      .container-gallery figure figcaption .description-bottom {
        position: absolute;
        padding: 20px;
        width: 100%;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s cubic-bezier(0.65, 0.1, 0.3, 1); }
      .container-gallery figure figcaption .description-top {
        top: 0;
        transform: translateX(-50px); }
      .container-gallery figure figcaption .description-bottom {
        bottom: 0;
        text-align: right;
        transform: translateX(50px); }
        .container-gallery figure figcaption .description-bottom .copyrightYear {
          display: none; }
      .container-gallery figure figcaption .slash {
        display: none; }
      .container-gallery figure figcaption span, .container-gallery figure figcaption p {
        display: block;
        color: #B2B2B5;
        text-transform: uppercase !important; }
      .container-gallery figure figcaption h3 {
        display: block;
        color: #f8f8f8; }

.container-fluid.container-teaser {
  text-align: left; }
  .container-fluid.container-teaser h2 {
    margin-bottom: 5px; }
  .container-fluid.container-teaser h3 {
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .container-fluid.container-teaser h3 {
        margin-bottom: 25px;
        font-size: 1.1875em;
        font-weight: 300;
        letter-spacing: 2.38px;
        line-height: 28px; } }
  @media (min-width: 992px) {
    .container-fluid.container-teaser {
      padding-left: 0;
      padding-right: 0;
      max-width: 1440px; } }
  .container-fluid.container-teaser .image-wrapper {
    margin-bottom: 20px;
    width: 100%; }
    .container-fluid.container-teaser .image-wrapper.ratio-3-2 {
      padding-top: 66%; }
    .container-fluid.container-teaser .image-wrapper.ratio-16-9 {
      padding-top: 56%; }
    @media (min-width: 992px) {
      .container-fluid.container-teaser .image-wrapper {
        margin-bottom: 0; } }
    .container-fluid.container-teaser .image-wrapper img {
      position: absolute;
      left: -100%;
      top: -100%;
      right: -100%;
      bottom: -100%;
      margin: auto auto;
      min-height: 100%;
      min-width: 100%;
      max-width: 110% !important;
      max-height: none;
      width: auto; }
      @media (max-width: 767px) {
        .container-fluid.container-teaser .image-wrapper img {
          max-width: 110%; } }
  @media (min-width: 992px) {
    .container-fluid.container-teaser .text-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); } }

.container-filter {
  /* filter buttons */
  /* only show the first div */ }
  .container-filter-button {
    margin-bottom: 40px; }
    .container-filter-button .filter {
      cursor: pointer;
      display: inline-block; }
      .container-filter-button .filter h3 {
        color: #B2B2B5; }
      .container-filter-button .filter.active h3 {
        color: #191919;
        pointer-events: none; }
      @media (max-width: 767px) {
        .container-filter-button .filter h3 {
          font-size: 1.1875em;
          font-weight: 300;
          letter-spacing: 2.38px;
          line-height: 27px; } }
      .container-filter-button .filter:not(:first-child) {
        margin-left: 25px; }
        @media (min-width: 992px) {
          .container-filter-button .filter:not(:first-child) {
            margin-left: 40px; } }
  .container-filter-wrapper {
    display: none; }
    .container-filter-wrapper:first-child {
      display: block; }

.container-instafeed {
  background: #191922; }
  .container-instafeed .instafeed-wrapper h3 {
    margin-bottom: 20px;
    color: #ffffff; }
  .container-instafeed .instafeed-wrapper #instafeed {
    /* reset the first line */
    margin-top: -15px;
    /* correct spacing */
    /* customize fadeIn animation */ }
    @media (max-width: 374px) {
      .container-instafeed .instafeed-wrapper #instafeed {
        margin-top: -15px; } }
    @media (min-width: 768px) {
      .container-instafeed .instafeed-wrapper #instafeed {
        margin-top: -30px; } }

@media (max-width: 767px) and (max-width: 767px) {
  .container-instafeed .instafeed-wrapper #instafeed .col-xs-6:nth-child(even) {
    padding-left: 7.5px; }
  .container-instafeed .instafeed-wrapper #instafeed .col-xs-6:nth-child(odd) {
    padding-right: 7.5px; } }
    .container-instafeed .instafeed-wrapper #instafeed .image-wrapper {
      position: relative;
      margin-top: 15px;
      width: 100%;
      padding-top: 100%;
      /* 1:1 Aspect Ratio */ }
      @media (max-width: 374px) {
        .container-instafeed .instafeed-wrapper #instafeed .image-wrapper {
          margin-top: 15px; } }
      @media (min-width: 768px) {
        .container-instafeed .instafeed-wrapper #instafeed .image-wrapper {
          margin-top: 30px; } }
      @media (min-width: 1px) {
        .container-instafeed .instafeed-wrapper #instafeed .image-wrapper:hover .image-icon {
          opacity: 1; } }
      .container-instafeed .instafeed-wrapper #instafeed .image-wrapper a {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0; }
      .container-instafeed .instafeed-wrapper #instafeed .image-wrapper img.image {
        position: absolute;
        width: 100%;
        max-width: none;
        object-fit: cover;
        min-height: 100%;
        margin: auto auto;
        left: -100%;
        top: -100%;
        right: -100%;
        bottom: -100%; }
      .container-instafeed .instafeed-wrapper #instafeed .image-wrapper img.image-icon {
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        bottom: 15px;
        right: 15px;
        opacity: 0.4;
        transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1); }
        @media (min-width: 992px) {
          .container-instafeed .instafeed-wrapper #instafeed .image-wrapper img.image-icon {
            width: 30px;
            height: 30px;
            bottom: 30px;
            right: 30px; } }
    .container-instafeed .instafeed-wrapper #instafeed .instafeed-item .image-wrapper:before,
    .container-instafeed .instafeed-wrapper #instafeed .instafeed-item .image-wrapper:after {
      background: #191922; }
    @media (min-width: 992px) {
      .container-instafeed .instafeed-wrapper #instafeed .instafeed-item:nth-child(-n+3) .animate-image:before {
        height: 50%;
        background: #191922; }
      .container-instafeed .instafeed-wrapper #instafeed .instafeed-item:nth-child(-n+3) .animate-image:after {
        background: #191922;
        content: '';
        position: absolute;
        width: 100%;
        height: 50%;
        left: 0;
        top: 50%;
        right: 0;
        bottom: 0;
        z-index: 1; } }
  .container-instafeed .instafeed-wrapper .container-button {
    margin-top: 20px; }

.container-post-type .item {
  margin-bottom: 40px; }
  .container-post-type .item:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .container-post-type .item {
      margin-bottom: 0; }
      .container-post-type .item:nth-child(2n+2) {
        margin-bottom: 40px; }
      .container-post-type .item:last-child {
        margin-bottom: 0; } }
  @media (min-width: 992px) {
    .container-post-type .item {
      margin-bottom: 0; }
      .container-post-type .item:nth-child(2n+2) {
        margin-bottom: 0; }
      .container-post-type .item:nth-child(3n+3) {
        margin-bottom: 60px; }
      .container-post-type .item:last-child {
        margin-bottom: 0; } }
  .container-post-type .item a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%; }
  .container-post-type .item .description-wrapper {
    margin-top: 15px; }
  .container-post-type .item .image-wrapper {
    position: relative;
    width: 100%;
    padding-top: 100%;
    /* 1:1 Aspect Ratio */
    background: #00000A;
    border: 1px solid #ffffff; }
    .container-post-type .item .image-wrapper img {
      position: absolute;
      min-width: 101%;
      width: auto;
      max-width: 140%;
      min-height: 101%;
      margin: auto auto;
      left: -100%;
      top: -100%;
      right: -100%;
      bottom: -100%;
      transition: all 0.5s cubic-bezier(0.65, 0.1, 0.3, 1); }

.container-biography.first-child {
  margin-bottom: 40px; }

.container-biography hr {
  margin-bottom: 40px; }

.container-biography .text-wrapper {
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .container-biography .text-wrapper {
      margin-bottom: 20px; }
      .container-biography .text-wrapper:first-child {
        margin-top: 10px; }
      .container-biography .text-wrapper:last-child {
        margin-bottom: 0; }
      .container-biography .text-wrapper p.year {
        position: absolute;
        display: inline-block; }
      .container-biography .text-wrapper p.text {
        display: inline-block;
        margin-left: 28%; } }

.container-biography h2 {
  hyphens: auto;
  word-wrap: break-word; }

.page-kontakt .container-contact-form,
.page-contact .container-contact-form {
  background: #F7F7F7; }

.page-kontakt input, .page-kontakt textarea,
.page-contact input,
.page-contact textarea {
  background: #fff !important; }

.container-filter {
  position: relative; }
  .container-filter ul {
    list-style: none;
    padding: 15px 14px; }
    .container-filter ul li {
      display: inline-block;
      margin-right: 40px; }
      .container-filter ul li a {
        font-weight: 500;
        font-size: 14px;
        color: #99999D;
        letter-spacing: 1.08px;
        line-height: 21px;
        text-transform: uppercase; }
        .container-filter ul li a:hover, .container-filter ul li a.active {
          color: #191922; }
  .container-filter .filter-years {
    display: block;
    height: 30px;
    position: relative;
    left: 25px; }
    .container-filter .filter-years .dropdown-wrapper {
      width: 115px; }
  .container-filter .filter-categories {
    display: block;
    height: 30px;
    top: -30px;
    position: absolute;
    right: 25px; }
    .container-filter .filter-categories .dropdown-wrapper {
      width: 155px; }
  .container-filter-years {
    margin-top: -20px; }
    @media (min-width: 768px) {
      .container-filter-years {
        height: 50px;
        background: #F2F2F3;
        margin-bottom: 40px;
        margin-top: -80px; } }
    @media (min-width: 992px) {
      .container-filter-years {
        margin-top: -80px; } }
  .container-filter-categories {
    margin-bottom: 60px; }
    .container-filter-categories ul {
      padding-top: 0;
      padding-bottom: 0; }
